export const georgia =
  [
    // Moody 2 North
    [
      [31.3002777777778, -82.85],
      [31.2336111111111, -82.8166666666667],
      [31.0266666666667, -82.65],
      [30.9502777777778, -82.65],
      [30.9502777777778, -83.0166666666667],
      [31.3502777777778, -83.0166666666667],
    ],
    // Moody 2 South
    [
      [30.9502777777778, -83.0166666666667],
      [30.9502777777778, -82.65],
      [30.5836111111111, -82.65],
      [30.6030555555556, -83.0166666666667],
    ],
  ]