export const newHampshire =
  [
    // Yankee 1, NH
    [
      [43.7208333333333, -71.9994444444444],
      [44.15, -72.2827777777778],
      [44.5333333333333, -71.8661111111111],
      [44.6333333333333, -71.3827777777778],
      [44.2666666666667, -71.1327777777778],
      [43.65, -71.4494444444445],
    ],
    // Yankee 2, NH
    [
      [43.7016666666667, -71.8494444444444],
      [43.65, -71.4494444444445],
      [44.0802777777778, -71.2294444444444],
      // Thence northwest along Route 302
      [44.0835, -71.2272],
      [44.0822, -71.2388],
      [44.0815, -71.2427],
      [44.0816, -71.2440],
      [44.0804, -71.2552],
      [44.0795, -71.2593],
      [44.0783, -71.2702],
      [44.0783, -71.2725],
      [44.0780, -71.2769],
      [44.0782, -71.2814],
      [44.0734, -71.3020],
      [44.0734, -71.3031],
      [44.0748, -71.3060],
      [44.0779, -71.3157],
      [44.0779, -71.3190],
      [44.0765, -71.3233],
      [44.0761, -71.3283],
      [44.0763, -71.3328],
      [44.0759, -71.3361],
      [44.0760, -71.3371],
      [44.0773, -71.3407],
      [44.0787, -71.3430],
      [44.0795, -71.3452],
      [44.0841, -71.3484],
      [44.0881, -71.3529],
      [44.0962, -71.3550],
      [44.1068, -71.3520],
      [44.1101, -71.3532],
      [44.1143, -71.3542],
      [44.1206, -71.3543],
      [44.1265, -71.3593],
      [44.1303, -71.3604],
      [44.1347, -71.3598],
      [44.1359, -71.3604],
      [44.1403, -71.3644],
      [44.1501, -71.3664],
      [44.1514, -71.3661],
      [44.1555, -71.3636],
      [44.1646, -71.3685],
      [44.1670, -71.3741],
      [44.1672, -71.3780],
      [44.1703, -71.3844],
      [44.1725, -71.3928],
      [44.1742, -71.3940],
      [44.1764, -71.3941],
      [44.1823, -71.3991],
      [44.1846, -71.4002],
      [44.1932, -71.4060],
      [44.1981, -71.4072],
      [44.2028, -71.4048],
      [44.2053, -71.4029],
      [44.2113, -71.4046],
      [44.2177, -71.4108],
      [44.2183, -71.4108],
      [44.2198, -71.4103],
      [44.2208, -71.4111],
      [44.2276, -71.4188],
      [44.2379, -71.4241],
      [44.2439, -71.4314],
      [44.2529, -71.4468],
      [44.2542, -71.4498],
      [44.2558, -71.4521],
      [44.2576, -71.4539],
      [44.2612, -71.4562],
      [44.2631, -71.4581],
      [44.2692, -71.4701],
      [44.2696, -71.4731],
      [44.2683, -71.4803],
      [44.2666666666667, -71.4827777777778],
      [44.25, -71.5161111111111],
      [44.1, -71.9994444444444],
      [44, -71.9994444444444],
      [43.8833333333333, -71.8494444444444],
    ],
  ]