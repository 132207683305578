export const utah =
  [
    // Hill AFB R-6404 Viper Alley East
    [
      [41.2183333333333, -113.116666666667],
      [41.2083333333333, -113],
      [40.9166666666667, -113],
      [40.8558333333333, -113.116666666667],
    ],
    // Hill AFB R-6404 Viper Alley West
    [
      [41.2333333333333, -113.316666666667],
      [41.2183333333333, -113.116666666667],
      [40.8558333333333, -113.116666666667],
      [40.8406666666667, -113.316666666667],
    ],
    // Sevier A/C
    [
      [39.3833333333333, -114.050833333333],
      [39.3833333333333, -113.3175],
      [39.6638888888889, -113.043611111111],
      [39.5666666666667, -112.9175],
      [39, -113.3675],
      [39, -113.984166666667],
    ],
    // Sevier B/D
    [
      [38.5, -113.600833333333],
      [38.7166666666667, -113.934166666667],
      [39, -113.984166666667],
      [39, -113.3675],
      [39.5666666666667, -112.9175],
      [39.6638888888889, -113.043611111111],
      [40, -112.7175],
      [40.2666666666667, -112.7175],
      [40.4166666666667, -112.834166666667],
      [40.5736111111111, -112.943888888889],
      [40.5166666666667, -112.6175],
      [39.9833333333333, -112.534166666667],
      [39.7833333333333, -112.600833333333],
      [38.7, -113.0675],
    ],
    // UTTR North ROZ
    [
      [40.4166666666667, -113.116666666667],
      [40.4166666666667, -112.833333333333],
      [40.3333333333333, -112.766666666667],
      [40.3333333333333, -113.116666666667],
    ],
    // UTTR Wendover Shelf
    [
      [40.6, -114.45],
      [40.6, -114.04],
      [40.65, -114],
      [40.65, -113.716666666667],
      [40.2716666666667, -114.45],
    ],
    // UTTR West ROZ
    [
      [40.25, -113.35],
      [40.25, -113.333333333333],
      [39.85, -113.333333333333],
      [39.8666666666667, -113.45],
      [39.9166666666667, -113.45],
      [40, -113.416666666667],
    ],
    // UTTR Wildcat ROZ
    [
      [40.3333333333333, -113.333333333333],
      [40.3333333333333, -113.116666666667],
      [40.25, -113.116666666667],
      [40.25, -113.35],
    ],
  ]