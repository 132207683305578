export const thailand =
  [
    // CG-1
    [
      [14.9166666666667, 100.833333333333],
      [15.55, 100.45],
      [16.29194444444444, 100.45],
      // Thence along 30NM arc CCW from PSL VOR Center [16.7703722222222,100.291305555556],
      [16.3029437490501, 100.481230720039],
      [16.3354252578128, 100.551277462888],
      [16.3776045186846, 100.615555467701],
      [16.4285451333188, 100.672633899287],
      [16.4871154436177, 100.721238949012],
      [16.5520132670304, 100.760282157196],
      [16.5816666666667, 100.7738888888889],
      [16.5333333333333, 101.45],
      [15.1166666666667, 100.983333333333],
      [15.1166666666667, 100.833333333333],
    ],
    // CG-2
    [
      [16.5333333333333, 101.45],
      [16.3333333333333, 102],
      [15.3722222222222, 101.608333333333],
      [15.7155, 101.179577777778],
    ],
    // CG-3
    [
      [16.3333333333333, 102],
      [16.1444444444444, 102.5],
      [15.2578944444444, 102.162333333333],
      // Thence along a 20NM arc CCW around KRT TACAN Center [14.935,102.072722222222],
      [15.261340710699, 102.149450335894],
      [15.2688088580089, 102.098491034928],
      [15.2688088580089, 102.046953409515],
      [15.261340710699, 101.995994108549],
      [15.2465720042918, 101.946756617564],
      [15.2248341017582, 101.900345248877],
      [15.1966146057256, 101.857800072952],
      [15.1835277777778, 101.842480555556],
      [15.3722222222222, 101.608333333333],
    ],
    // CG-4
    [
      [16.1444444444444, 102.5],
      [15.8666666666667, 103.266666666667],
      [15.3919444444444, 103.267222222222],
      [15.2094444444444, 103.091111111111],
      [15.1833333333333, 102.6],
      [15.1158, 102.362233333333],
      // Thence along a 20NM arc CCW around KRT TACAN Center [14.935,102.072722222222],
      [15.1233927107235, 102.357436165071],
      [15.1625462826209, 102.325371056575],
      [15.1966146057256, 102.287644371491],
      [15.2248341017582, 102.245099195566],
      [15.2465720042918, 102.198687826879],
      [15.2578944444444, 102.162333333333],
    ],
    // CG-7
    [
      [14.6027694444444, 102.033155555556],
      // Thence along a 20NM arc CCW around KRT TACAN Center [14.935,102.072722222222],
      [14.6011785164264, 102.047032878278],
      [14.6011785164264, 102.098411566165],
      [14.6086243186979, 102.149218991146],
      [14.6233503206381, 102.198325163179],
      [14.6450289540121, 102.2446374385],
      [14.6731778827859, 102.28712455135],
      [14.7071706109306, 102.324839362236],
      [14.746250277152, 102.356939839712],
      [14.7895463452693, 102.38270782304],
      [14.8360938359252, 102.401565152044],
      [14.8501083333333, 102.405394444444],
      [14.8166666666667, 103.266666666667],
      [14.5288888888889, 103.267222222222],
      [14.4666666666667, 103.029166666667],
      [13.9322222222222, 102.331388888889],
    ],
    // CG-8
    [
      [15.6277777777778, 101.154166666667],
      [15.1166666666667, 101.7875],
      // Thence along a 20NM arc CCW around KRT TACAN Center [14.935,102.072722222222],
      [15.0800309929381, 101.762319764806],
      [15.0334319416446, 101.743578942809],
      [14.984638198401, 101.732200490077],
      [14.9347407944888, 101.728433759788],
      [14.9166666666667, 101.733333333333],
      [14.9166666666667, 100.983333333333],
      [15.1166666666667, 100.983333333333],
    ],
  ]