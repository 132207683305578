export const northCarolina =
  [
    // Cherry Point, NC R-5306A
    [
      [35.3877777777778, -76.5775],
      [35.3044444444444, -76.2775],
      [35.0752777777778, -76.0747222222222],
      [34.7794444444444, -76.4122222222222],
      [34.7669444444444, -76.4997222222222],
      [35.1336111111111, -76.8552777777778],
    ],
    // Cherry Point, NC R-5306C
    [
      [34.8502777777778, -77.0913888888889],
      [34.7002777777778, -76.9122222222222],
      [34.6975, -76.9386111111111],
      [34.6252777777778, -76.9386111111111],
      // Thence SW along a line 3NM from and parallel to the shoreline
      [34.5752777777778, -77.1497222222222],
      [34.7475, -77.2441666666667],
      [34.8252777777778, -77.1663888888889],
    ],
    // Cherry Point, NC R-5306D
    [
      [34.7475, -77.2441666666667],
      [34.5752777777778, -77.1497222222222],
      // Thence SW along a line 3NM from and parallel to the shoreline
      [34.5058333333333, -77.2636111111111],
      [34.5502777777778, -77.3163888888889],
      [34.6016666666667, -77.4352777777778],
      [34.6669444444444, -77.3663888888889],
      [34.6530555555556, -77.3469444444444],
    ],
    // Cherry Point, NC R-5306E
    [
      [34.6725, -77.3697222222222],
      [34.6669444444445, -77.3663888888889],
      [34.6016666666667, -77.4352777777778],
      [34.6369444444444, -77.4330555555556],
    ],
    // Long Shoal Point, NC R-5313A
    // Circle with 3 mile radius centered at [35.5469444444444,-75.6902777777778],
    [
      [35.5469282452071, -75.6370254823318],
      [35.553414095202, -75.6376159394606],
      [35.5597555603293, -75.6393828820636],
      [35.5658109330105, -75.6422870968748],
      [35.5714448681123, -75.6462639181281],
      [35.5765314132642, -75.651224653401],
      [35.580956831274, -75.6570585520673],
      [35.5846221508052, -75.6636352735368],
      [35.5874453874527, -75.6708078006672],
      [35.589363384687, -75.6784157331153],
      [35.5903332326936, -75.6862888862405],
      [35.5903332326936, -75.6942511137594],
      [35.589363384687, -75.7021242668846],
      [35.5874453874527, -75.7097321993327],
      [35.5846221508052, -75.7169047264631],
      [35.580956831274, -75.7234814479326],
      [35.5765314132642, -75.7293153465989],
      [35.5714448681123, -75.7342760818718],
      [35.5658109330105, -75.7382529031251],
      [35.5597555603293, -75.7411571179363],
      [35.553414095202, -75.7429240605393],
      [35.5469282452071, -75.7435145176681],
      [35.5404429104542, -75.7429155816122],
      [35.5341029452717, -75.7411409134708],
      [35.5280499239616, -75.738230412957],
      [35.5224189827281, -75.7342493043491],
      [35.5173358079233, -75.7292866610207],
      [35.5129138372698, -75.723453403138],
      [35.5092517357911, -75.7168798143561],
      [35.506431201954, -75.7097126334651],
      [35.5045151521312, -75.7021117857719],
      [35.5035463231006, -75.6942468264049],
      [35.5035463231006, -75.686293173595],
      [35.5045151521312, -75.678428214228],
      [35.506431201954, -75.6708273665348],
      [35.5092517357911, -75.6636601856438],
      [35.5129138372698, -75.657086596862],
      [35.5173358079233, -75.6512533389793],
      [35.5224189827281, -75.6462906956508],
      [35.5280499239616, -75.6423095870429],
      [35.5341029452717, -75.6393990865292],
      [35.5404429104542, -75.6376244183877],
    ],
    // Long Shoal Point, NC R-5313B
    [
      [35.6130555555556, -75.6891666666667],
      [35.5125, -75.5869444444444],
      [35.4041666666667, -75.6727777777778],
      [35.5152777777778, -75.8413888888889],
    ],
    // Long Shoal Point, NC R-5313C
    [
      [35.5358333333333, -75.5683333333333],
      [35.5125, -75.5869444444444],
      [35.6130555555556, -75.6891666666667],
      [35.6402777777778, -75.6463888888889],
      // Thence CW along a 6NM arc centered at [35.5469444444444,-75.6902777777778],
      [35.6401603364015, -75.6454472547928],
      [35.6336584386154, -75.6289312561389],
      [35.6252176678528, -75.6137898690757],
      [35.6150271463321, -75.6003618774128],
      [35.6033151227289, -75.5889473485748],
      [35.5903438191313, -75.5798009214772],
      [35.576403520461, -75.5731261495014],
      [35.5618060431249, -75.5690710220232],
      [35.5468777329586, -75.5677247582821],
      [35.5468777329586, -75.5677247582821],
    ],
    // Long Shoal Point, NC R-5313D
    [
      [35.3477777777778, -75.7186111111111],
      [35.4041666666667, -75.6727777777778],
      [35.5152777777778, -75.8413888888889],
      [35.4672222222222, -75.915],
      // Thence CCW along a 12NM arc centered at [35.5469444444444,-75.6902777777778],
      [35.4598286850125, -75.9108501303669],
      [35.4339337583131, -75.8924894361263],
      [35.4105633278038, -75.8696303338538],
      [35.3902370838631, -75.8427847499938],
      [35.3734066673257, -75.8125518826236],
      [35.3604457933711, -75.7796048636282],
      [35.3516421010475, -75.7446759207032],
    ],
    // Dare County, NC R-5314A
    [
      [35.71, -75.8302777777778],
      [35.6688888888889, -75.8372222222222],
      [35.6736111111111, -75.8708333333333],
      [35.6447222222222, -75.8761111111111],
      [35.6502777777778, -75.9094444444444],
      [35.6780555555556, -75.9025],
      [35.6919444444444, -76.0052777777778],
      [35.7361111111111, -75.9947222222222],
    ],
    // Dare County, NC R-5314B
    [
      [35.6977777777778, -75.7530555555556],
      [35.5780555555556, -75.7802777777778],
      [35.6127777777778, -76.0219444444444],
      [35.6919444444444, -76.0052777777778],
      [35.6780555555556, -75.9025],
      [35.6502777777778, -75.9094444444444],
      [35.6447222222222, -75.8761111111111],
      [35.6736111111111, -75.8708333333333],
      [35.6688888888889, -75.8372222222222],
      [35.71, -75.8302777777778],
    ],
    // Dare County, NC R-5314C
    [
      [35.7361111111111, -75.9947222222222],
      [35.6486111111111, -76.0144444444445],
      [35.6558333333333, -76.0830555555555],
      [35.7466666666667, -76.0622222222222],
    ],
    // Dare County, NC R-3414D
    [
      [35.7975, -75.8136111111111],
      [35.71, -75.8302777777778],
      [35.7361111111111, -75.9947222222222],
      [35.7836111111111, -75.9830555555556],
      [35.7688888888889, -75.8763888888889],
      [35.8002777777778, -75.8663888888889],
    ],
    // Dare County, NC R-5314E
    [
      [35.8086111111111, -75.7275],
      [35.6977777777778, -75.7530555555556],
      [35.71, -75.8302777777778],
      [35.7975, -75.8136111111111],
      [35.8002777777778, -75.8663888888889],
      [35.7688888888889, -75.8763888888889],
      [35.7836111111111, -75.9830555555556],
      [35.86, -75.965],
      [35.8252777777778, -75.7497222222222],
    ],
    // Dare County, NC R-5314F
    [
      [35.86, -75.965],
      [35.7361111111111, -75.9947222222222],
      [35.7466666666667, -76.0622222222222],
      [35.8647222222222, -76.0355555555555],
    ],
    // Dare County, NC R-5314H
    [
      [35.8647222222222, -76.0355555555555],
      [35.6558333333333, -76.0830555555555],
      [35.6738888888889, -76.2066666666667],
      [35.8730555555556, -76.1644444444445],
    ],
    // Dare County, NC R-5314J
    [
      [35.8730555555556, -76.1644444444445],
      [35.6738888888889, -76.2066666666667],
      [35.7308333333333, -76.5913888888889],
      [35.8975, -76.5525],
    ],
  ]