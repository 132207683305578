export const colorado =
  [
    // La Veta Low, CO
    [
      [38.2583333333333, -104.917222222222],
      [38.0666666666667, -104.817222222222],
      [38.0152777777778, -104.754166666667],
      [37.9166666666667, -105.000555555556],
      [38.2480555555556, -105.123888888889],
      [38.27, -105.101111111111],
    ],
  ]