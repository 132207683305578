export const virginia =
  [
    // Farmville, VA
    [
      [37.5833333333333, -78.2083333333333],
      [37.4916666666667, -77.9166666666667],
      [36.8416666666667, -78.3333333333333],
      [36.775, -78.8],
      [36.875, -79.0583333333333],
      [37.25, -78.8333333333333],
      [37.25, -78.3333333333333],
    ],
  ]