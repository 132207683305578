export const alabama =
  [
    // Birmingham MOA
    [
      [33, -87.4222222222222],
      [33, -87.0944444444444],
      [32.9736111111111, -87.0222222222222],
      [32.4430555555556, -87.0402777777778],
      [32.4972222222222, -87.8888888888889],
      [32.5916666666667, -87.9916666666667],
    ],
    // Birmingham 2
    [
      [33, -87.4222222222222],
      [33, -87.0944444444444],
      [32.9736111111111, -87.0222222222222],
      [32.4430555555556, -87.0402777777778],
      [32.4849977777, -87.6973],
      [32.5416666666667, -87.8333333333333],
      [32.5916666666667, -87.9916666666667],
    ],
    // Rose Hill
    [
      [31.1141666666667, -85.8958333333333],
      // Thence CW via the 26 NM DME arc of the Wiregrass, AL VORTAC (RRS)
      // [31.2845872,-85.4312097]
      [31.1556717421898, -85.9137746966765],
      [31.2188882130102, -85.9309024345905],
      [31.2835920062415, -85.9368915174287],
      [31.3483393573442, -85.9315855474853],
      [31.4116826322337, -85.9150802332368],
      // End
      [31.4533333333333, -85.8975],
      [31.595, -86.2933333333333],
      // Thence CCW via the 48NM DME arc of the Wiregrass, AL VORTAC (RRS)
      [31.5177824969413, -86.3254814710854],
      [31.400752185091, -86.3554584399047],
      [31.2811956889001, -86.3647177627469],
      [31.1617876091908, -86.353130621497],
      [31.045189368185, -86.3210325742007],
      // End
      [30.9683333333333, -86.2877777777778],
    ],
  ]