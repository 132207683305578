export const southKorea =
  [
    // ACMI A/B
    [
      [37.0833333333333, 125.7],
      [37.0833333333333, 126.166666666667],
      [36.7333333333333, 126.166666666667],
      [36.7333333333333, 125.7],
    ],
    // ACMI-C
    [
      [37.0855555555556, 125.6],
      [37.0938888888889, 126.366111111111],
      [37.0027777777778, 126.425],
      [36.8, 126.483333333333],
      [36.7333333333333, 126.483333333333],
      [36.7333333333333, 126.166666666667],
      [36.5833333333333, 125.7],
      [36.5833333333333, 125.6],
    ],
    // ACMI-E
    [
      [36.7333333333333, 125.7],
      [36.7333333333333, 126.166666666667],
      [36.5833333333333, 125.7],
    ],
    // CATA-1
    [
      [38.41722222, 130.1666667],
      [38.4, 131.1855556],
      [38.23333333, 131.1833333],
      [37.78333333, 130.1666667],
    ],
    // CATA-2
    [
      [34, 125],
      [34, 125.8333333],
      [33.25, 125.8333333],
      [33.25, 125],
    ],
    // CATA-7H
    [
      [36.83333333, 129.8477778],
      [36.5, 129.8477778],
      [36.5, 129.4352778],
    ],
    // MOA-1
    [
      [36.5833333333333, 125.6],
      [36.5833333333333, 125.7],
      [36.7333333333333, 126.166666666667],
      [36.7333333333333, 126.483333333333],
      [36.3333333333333, 126.416666666667],
      [36.3333333333333, 125.7],
      [36.3330555555556, 125.600555555556],
    ],
    // MOA-2H
    [
      [36.58333333, 127.1166667],
      [36.58333333, 127.3666667],
      [36.49972222, 127.5019444],
      [35.96666667, 127.3666667],
      [35.98333333, 127.0666667],
      [36.14333333, 127.08],
      [36.38388889, 127.1069444],
      [36.44361111, 127.105],
    ],
    // MOA-3H
    [
      [36.49972222, 127.5019444],
      [36.36666667, 127.7166667],
      [35.93333333, 128.25],
      [35.96666667, 127.3666667],
    ],
    // MOA-5
    [
      [37.45, 127.9666667],
      [37.53333333, 128.5166667],
      [37.2, 128.5166667],
      [37.1, 127.9833333],
    ],
    // MOA-6
    [
      [37.53333333, 128.5166667],
      [37.56666667, 128.7166667],
      [37.38333333, 129.1],
      [37.21666667, 129.05],
      [37.21666667, 128.6833333],
      [37.2, 128.5166667],
    ],
    // MOA-7
    [
      [38.26666667, 129],
      [38.25, 129.85],
      [37.8, 129.85],
      [37.81666667, 129],
    ],
    // MOA-8
    [
      [37.21666667, 129.05],
      [37.38333333, 129.1],
      [36.96666667, 130],
      [36.83333333, 130],
      [36.83333333, 129.25],
      [36.91666667, 129.05],
    ],
    // MOA-9E
    [
      [36.83333333, 129.5333333],
      [36.83333333, 130],
      [36.5, 130],
      [36.5, 129.5833333],
    ],
    // MOA-9W
    [
      [36.83333333, 129.25],
      [36.83333333, 129.5333333],
      [36.5, 129.5833333],
      [36.5, 129.25],
    ],
    // MOA-10
    [
      [36.91666667, 128.6833333],
      [36.91666667, 129.05],
      [36.83333333, 129.25],
      [36.5, 129.25],
      [36.35, 129.1],
      [36.71666667, 128.5],
    ],
    // MOA-11
    [
      [37.1, 127.9833333],
      [37.2, 128.5166667],
      [37.21666667, 128.6833333],
      [36.91666667, 128.6833333],
      [36.71666667, 128.5],
      [36.93333333, 128.05],
    ],
    // MOA-12E
    [
      [36.5, 129.5833333],
      [36.5, 130],
      [36.03333333, 130],
      [36.08333333, 129.6166667],
    ],
    // MOA-12W
    [
      [36.5, 129.25],
      [36.5, 129.5833333],
      [36.08333333, 129.6166667],
      [36.35, 129.1],
    ],
    // MOA-13E
    [
      [35.85, 129.6],
      [35.76666667, 130.2166667],
      [35.33333333, 129.8333333],
      [35.26666667, 129.35],
    ],
    // MOA-13W
    [
      [35.73333333, 128.8333333],
      [35.86666667, 129.5],
      [35.85, 129.6],
      [35.26666667, 129.35],
      [35.23333333, 129.1166667],
    ],
    // MOA-14
    [
      [36.43333333, 128.4833333],
      [36.03333333, 129.2],
      [36, 129.05],
      [36, 128.5333333],
      [36.2, 128.2833333],
    ],
    // MOA-15
    [
      [35.98333333, 127.0666667],
      [35.96666667, 127.5444444],
      [35.23333333, 127.4],
      [35.31583333, 126.9880556],
    ],
    // MOA-16
    [
      [36.08277778, 124.6897222],
      [36.08361111, 125.6011111],
      [35.66666667, 125.6019444],
      [35.66666667, 124.6902778],
    ],
    // MOA-17
    [
      [36.33305556, 125.6005556],
      [36.33333333, 126.4166667],
      [35.66666667, 126.4166667],
      [35.66666667, 125.6019444],
    ],
    // MOA-18
    [
      [35.66666667, 124.6902778],
      [35.66666667, 125.6019444],
      [35.25027778, 125.6027778],
      [35.25, 124.6911111],
    ],
    // MOA-19H
    [
      [35.66666667, 125.6019444],
      [35.66666667, 126.6569444],
      [35.25027778, 126.6130556],
      [35.25027778, 125.6027778],
    ],
    // MOA-20
    [
      [35.25027778, 125.6027778],
      [35.25027778, 126.6130556],
      [34.83361111, 126.5691667],
      [34.83333333, 125.6033333],
    ],
    // MOA-21
    [
      [34.83333333, 125.6033333],
      [34.83361111, 126.5691667],
      [34.41666667, 126.5261111],
      [34.41694444, 125.6036111],
    ],
    // MOA-22
    [
      [34.83333333, 124.6919444],
      [34.83333333, 125.6033333],
      [34.41694444, 125.6036111],
      [34.41666667, 124.6925],
    ],
    // MOA-23
    [
      [34.41666667, 124.6925],
      [34.41694444, 125.6036111],
      [34, 125.6038889],
      [34.00027778, 124.8838889],
      [34.01222222, 124.8333333],
      [34.04583333, 124.6933333],
    ],
    // MOA-24
    [
      [34.41694444, 125.6036111],
      [34.41666667, 126.5261111],
      [34, 126.4830556],
      [34, 125.6038889],
    ],
    // MOA-25H
    [
      [35, 126.9533333],
      [35, 127.3038889],
      [34.14638889, 127.3036111],
      [34, 127.0833333],
      [34, 126.8452778],
    ],
    // MOA-26H
    [
      [35, 127.3038889],
      [35, 128.375],
      [34.825, 128.3583333],
      [34.14638889, 127.3036111],
    ],
    // MOA-27N
    [
      [35.96666667, 127.5444444],
      [35.93333333, 128.25],
      [35.66111111, 128.4027778],
      [35.63611111, 127.4805556],
    ],
    // MOA-27S
    [
      [35.63611111, 127.4805556],
      [35.66111111, 128.4027778],
      [35.62916667, 128.4305556],
      [35.25416667, 128.3972222],
      [35.23333333, 127.4],
    ],
    // MOA-28
    [
      [34.25, 128],
      [34.75, 128.75],
      [34.41666667, 128.7],
      [33.91666667, 128],
    ],
    // MOA-29
    [
      [33.71666667, 127.3166667],
      [34.23333333, 128],
      [33.91666667, 128],
      [33.65, 127.6666667],
      [33.61666667, 127.45],
    ],
    // MOA-30
    [
      [37.98333333, 127.7833333],
      [38.03333333, 128.2],
      [37.75, 128.2],
      [37.68333333, 127.7833333],
    ],
    // MOA-31
    [
      [38.03333333, 128.2],
      [38.06666667, 128.6],
      [37.8, 128.6],
      [37.75, 128.2],
    ],
    // MOA-32
    [
      [37.55, 129.6333333],
      [37.51666667, 131],
      [36.7, 131],
      [37.43333333, 129.55],
    ],
    // MOA-33
    [
      [37.51666667, 131],
      [37.5, 132],
      [37.28333333, 132.5833333],
      [36.58333333, 131.2166667],
      [36.7, 131],
    ],
  ]