export const nevada =
  [
    // R-61A
    [
      [37.2833333333333, -115.584166666667],
      [37.2835, -115.434166666667],
      [37, -115.434333333333],
      [37, -115.550833333333],
      [37.1, -115.584166666667],
    ],
    // R-61B
    [
      [37.2835, -115.434166666667],
      [37.2833333333333, -115.300833333333],
      [37, -115.300833333333],
      [37, -115.434333333333],
    ],
    // R-62A
    [
      [37, -115.550833333333],
      [37, -115.434333333333],
      [37, -115.300833333333],
      [36.85, -115.300833333333],
      [36.85, -115.550833333333],
    ],
    // R-62B
    [
      [36.85, -115.550833333333],
      [36.85, -115.300833333333],
      [36.7166666666667, -115.300833333333],
      [36.7166666666667, -115.425],
      [36.7501666666667, -115.4245],
      [36.75, -115.490666666667],
      [36.7166666666667, -115.490666666667],
      [36.7166666666667, -115.550833333333],
    ],
    // R-63A
    [
      [36.75, -115.490666666667],
      [36.7501666666667, -115.4245],
      [36.7166666666667, -115.425],
      [36.7166666666667, -115.490666666667],
    ],
    // R-63B
    [
      [36.7166666666667, -115.550833333333],
      [36.7166666666667, -115.490666666667],
      [36.7166666666667, -115.425],
      [36.7166666666667, -115.300833333333],
      [36.6333333333333, -115.300833333333],
      [36.5335, -115.300833333333],
      [36.5333333333333, -115.434166666667],
      [36.5333333333333, -115.4925],
      [36.5031666666667, -115.4925],
      [36.5571666666667, -115.576833333333],
      [36.6061666666667, -115.568833333333],
      [36.6261666666667, -115.5655],
    ],
    // R-63C
    [
      [36.5333333333333, -115.4925],
      [36.5333333333333, -115.434166666667],
      [36.5166666666667, -115.434166666667],
      [36.5166666666667, -115.4175],
      [36.4658333333333, -115.4345],
      [36.5031666666667, -115.4925],
    ],
    // R-64A
    [
      [37.0001666666667, -115.716666666667],
      [37, -115.550833333333],
      [36.85, -115.550833333333],
      [36.8501666666667, -115.7175],
    ],
    // R-64B
    [
      [36.8501666666667, -115.7175],
      [36.85, -115.550833333333],
      [36.7166666666667, -115.550833333333],
      [36.7163333333333, -115.7175],
    ],
    // R-64C
    [
      [36.6725, -115.855833333333],
      [36.6728333333333, -115.7175],
      [36.7163333333333, -115.7175],
      [36.7166666666667, -115.550833333333],
      [36.6261666666667, -115.5655],
      [36.6261666666667, -115.637166666667],
      [36.6488333333333, -115.634666666667],
      [36.6516666666667, -115.645666666667],
      [36.6516666666667, -115.654666666667],
      [36.6491666666667, -115.665833333333],
      [36.627, -115.668],
      [36.627, -115.815166666667],
    ],
    // R-64D
    [
      [36.627, -115.815166666667],
      [36.627, -115.668],
      [36.6061666666667, -115.670166666667],
      [36.6061666666667, -115.796666666667],
    ],
    // R-64E
    [
      [36.6491666666667, -115.665833333333],
      [36.6516666666667, -115.654666666667],
      [36.6516666666667, -115.645666666667],
      [36.6488333333333, -115.634666666667],
      [36.6261666666667, -115.637166666667],
      [36.6061666666667, -115.617],
      [36.6061666666667, -115.670166666667],
      [36.627, -115.668],
    ],
    // R-64F
    [
      [36.6061666666667, -115.617],
      [36.6061666666667, -115.568833333333],
      [36.5571666666667, -115.576833333333],
      [36.5833333333333, -115.6175],
    ],
    // R-64G
    [
      [36.6261666666667, -115.637166666667],
      [36.6261666666667, -115.5655],
      [36.6061666666667, -115.568833333333],
      [36.6061666666667, -115.617],
    ],
    // R-65A
    [
      [37.1, -115.934166666667],
      [37.1, -115.584166666667],
      [37, -115.550833333333],
      [37.0001666666667, -115.716666666667],
      [37, -115.934166666667],
    ],
    // R-65B
    [
      [37, -115.934166666667],
      [37.0001666666667, -115.716666666667],
      [36.8501666666667, -115.7175],
      [36.85, -115.934166666667],
    ],
    // R-65C
    [
      [36.85, -115.934166666667],
      [36.8501666666667, -115.7175],
      [36.7163333333333, -115.7175],
      [36.6728333333333, -115.7175],
      [36.6725, -115.855833333333],
      [36.75, -115.934166666667],
    ],
    // R-65D
    [
      [36.75, -115.934166666667],
      [36.6725, -115.855833333333],
      [36.627, -115.815166666667],
      [36.6061666666667, -115.796666666667],
      [36.5833333333333, -115.776333333333],
      [36.5833333333333, -115.884166666667],
      [36.6, -115.934166666667],
      [36.6833333333333, -115.934166666667],
    ],
    // R-71N
    [
      [37.8833333333333, -117.094666666667],
      [37.8833333333333, -116.9175],
      [37.7833333333333, -116.9175],
      [37.6666666666667, -116.8175],
      [37.6666666666667, -117.094666666667],
    ],
    // R-71S
    [
      [37.6666666666667, -117.094666666667],
      [37.6666666666667, -116.8175],
      [37.55, -116.716666666667],
      [37.55, -117.094666666667],
    ],
    // R-74A
    [
      [37.55, -116.014],
      [37.5505, -115.884166666667],
      [37.5506666666667, -115.800833333333],
      [37.4668333333333, -115.800833333333],
      [37.4666666666667, -116.000833333333],
      [37.2666666666667, -116.000833333333],
      [37.2666666666667, -116.184166666667],
      [37.333, -116.183833333333],
    ],
    // R-74B
    [
      [37.6326666666667, -116.024666666667],
      [37.6321666666667, -115.884166666667],
      [37.5505, -115.884166666667],
      [37.55, -116.014],
      [37.333, -116.183833333333],
      [37.3833333333333, -116.284166666667],
      [37.3833333333333, -116.300833333333],
      [37.3833333333333, -116.317166666667],
    ],
    // R-74C
    [
      [37.7, -116.184166666667],
      [37.7, -115.884166666667],
      [37.6321666666667, -115.884166666667],
      [37.6326666666667, -116.024666666667],
      [37.3833333333333, -116.317166666667],
      [37.5333333333333, -116.316833333333],
      [37.5333333333333, -116.283333333333],
    ],
    // R-75E
    [
      [37.55, -116.434166666667],
      [37.55, -116.316833333333],
      [37.5333333333333, -116.316833333333],
      [37.3833333333333, -116.317166666667],
      [37.3833333333333, -116.3675],
      [37.35, -116.450833333333],
      [37.35, -116.5675],
    ],
    // R-75W
    [
      [37.55, -116.708333333333],
      [37.55, -116.434166666667],
      [37.35, -116.5675],
      [37.3333333333333, -116.6175],
      [37.3333333333333, -116.668666666667],
      [37.3753333333333, -116.699333333333],
      [37.3753333333333, -116.708333333333],
    ],
    // R-76
    [
      [37.55, -117.094666666667],
      [37.55, -116.716666666667],
      [37.55, -116.708333333333],
      [37.3753333333333, -116.708333333333],
      [37.3751666666667, -116.750166666667],
      [37.3753333333333, -116.789666666667],
      [37.3333333333333, -116.825166666667],
      [37.3333333333333, -116.834166666667],
      [37.2668333333333, -116.834166666667],
      [37.2666666666667, -116.922],
      [37.4416666666667, -117.075833333333],
    ],
    // R-4807A (Subset)
    [
      [37.7518333333333, -116.434166666667],
      [37.7518333333333, -116.383333333333],
      [37.702, -116.383333333333],
      [37.7018333333333, -116.434166666667],
    ],
    // R-4808A
    [
      [37.4666666666667, -116.000833333333],
      [37.4668333333333, -115.800833333333],
      [37.4666666666667, -115.584166666667],
      [37.2833333333333, -115.584166666667],
      [37.1, -115.584166666667],
      [37.1, -115.934166666667],
      [37.1, -116.000833333333],
      [37.2666666666667, -116.000833333333],
    ],
    // R-4808B
    [
      [37.2666666666667, -116.250833333333],
      [37.2666666666667, -116.184166666667],
      [37.2666666666667, -116.000833333333],
      [37.1, -116.000833333333],
      [37.1, -115.934166666667],
      [37, -115.934166666667],
      [37, -116.250833333333],
    ],
    // R-4808C
    [
      [37, -116.250833333333],
      [37, -115.934166666667],
      [36.85, -115.934166666667],
      [36.75, -115.934166666667],
      [36.6833333333333, -115.934166666667],
      [36.6833333333333, -116.017666666667],
      [36.8333333333333, -116.250833333333],
    ],
    // R-4808D
    [
      [37.2666666666667, -116.5175],
      [37.2668333333333, -116.300833333333],
      [37.2666666666667, -116.250833333333],
      [37, -116.250833333333],
      [37, -116.450833333333],
      [37.1333333333333, -116.450833333333],
    ],
    // R-4808E
    [
      [37, -116.450833333333],
      [37, -116.250833333333],
      [36.8333333333333, -116.250833333333],
      [36.6833333333333, -116.017666666667],
      [36.6833333333333, -116.246666666667],
      [36.7666666666667, -116.4425],
      [36.85, -116.4425],
      [36.85, -116.559166666667],
      [36.9166666666667, -116.559166666667],
      [36.9166666666667, -116.450833333333],
    ],
    // R-4808S
    [
      [36.7666666666667, -116.4425],
      [36.6833333333333, -116.246666666667],
      [36.6833333333333, -116.4425],
    ],
    // R-4809A
    [
      [37.8833333333333, -116.9175],
      [37.8835, -116.833333333333],
      [37.8835, -116.6675],
      [37.6666666666667, -116.6675],
      [37.6666666666667, -116.8175],
      [37.7833333333333, -116.9175],
    ],
    // R-4809B
    [
      [37.752, -116.5005],
      [37.7518333333333, -116.434166666667],
      [37.7018333333333, -116.434166666667],
      [37.7025, -116.4665],
      [37.7368333333333, -116.500333333333],
    ],
    // R-63B High
    [
      [36.5333333333333, -115.4925],
      [36.5335, -115.300833333333],
      [36.4333333333333, -115.300833333333],
      [36.4333333333333, -115.384166666667],
      [36.5031666666667, -115.4925],
    ],
  ]