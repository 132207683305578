export const idaho =
  [
    // Saylor Creek High/Low, ID R-3202
    [
      [42.8833333333333, -115.705555555556],
      [42.8833333333333, -115.404166666667],
      [42.6, -115.404166666667],
      [42.6, -115.705555555556],
    ],
    // Juniper Buttes, ID R-3204A
    [
      [42.3333333333333, -115.375],
      [42.3333333333333, -115.3],
      [42.3166666666667, -115.283333333333],
      [42.2763888888889, -115.283333333333],
      [42.2763888888889, -115.375],
    ],
    // R-3204B/C
    [
      [42.2999450189022, -115.220703912301],
      [42.3123709639113, -115.221936329019],
      [42.3245216710546, -115.225651163247],
      [42.3361254812773, -115.231766758901],
      [42.3469228188548, -115.240147586118],
      [42.3566720222919, -115.250607171947],
      [42.3651547841186, -115.262912199281],
      [42.372181074382, -115.27678768856],
      [42.3775934336686, -115.291923149277],
      [42.3812705354537, -115.307979563201],
      [42.3831299341643, -115.324597039126],
      [42.3831299341643, -115.341402960873],
      [42.3812705354537, -115.358020436798],
      [42.3775934336686, -115.374076850722],
      [42.372181074382, -115.389212311439],
      [42.3651547841186, -115.403087800718],
      [42.3566720222919, -115.415392828052],
      [42.3469228188548, -115.425852413881],
      [42.3361254812773, -115.434233241098],
      [42.3245216710546, -115.440348836752],
      [42.3123709639113, -115.44406367098],
      [42.2999450189022, -115.445296087698],
      [42.2875214895229, -115.44402001028],
      [42.275377814632, -115.440265394766],
      [42.2637850284439, -115.434117431964],
      [42.253001727157, -115.425714527696],
      [42.2432683250874, -115.415245116573],
      [42.2348017256876, -115.402943388749],
      [42.2277905228068, -115.389084030641],
      [42.2223908352731, -115.373976099453],
      [42.2187228636626, -115.357956167304],
      [42.2168682422849, -115.341380883822],
      [42.2168682422849, -115.324619116177],
      [42.2187228636626, -115.308043832695],
      [42.2223908352731, -115.292023900546],
      [42.2277905228068, -115.276915969358],
      [42.2348017256876, -115.26305661125],
      [42.2432683250874, -115.250754883426],
      [42.253001727157, -115.240285472303],
      [42.2637850284439, -115.231882568035],
      [42.275377814632, -115.225734605233],
      [42.2875214895229, -115.221979989719],
    ],
  ]