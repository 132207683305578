export const kansas =
  [
    // Brookville, KS R-3601A/B
    [
      [38.7555555555556, -97.7669444444444],
      [38.6625, -97.7669444444444],
      [38.6388888888889, -97.7919444444444],
      [38.6388888888889, -97.8336111111111],
      [38.5833333333333, -97.8336111111111],
      [38.5833333333333, -97.9336111111111],
      [38.7555555555556, -97.9336111111111],
    ],
    // Manhattan, KS R-3602A
    [
      [39.2958333333333, -96.8308333333333],
      [39.2983333333333, -96.8366666666667],
      [39.2952777777778, -96.8741666666667],
      [39.3058333333333, -96.8969444444445],
      [39.3025, -96.9177777777778],
      [39.3063888888889, -96.9330555555556],
      [39.3066666666667, -96.9608333333333],
      [39.2111111111111, -96.9611111111111],
      // Along the shoreline of the main body of Milfor Reservoir
      [39.1871, -96.9215],
      [39.1983, -96.9222],
      [39.2026, -96.9192],
      [39.2187, -96.9173],
      [39.2082, -96.9130],
      [39.2006, -96.9152],
      [39.1860, -96.9157],
      [39.1825, -96.9108333333333],
      [39.1827777777778, -96.8872222222222],
      [39.1394444444444, -96.8872222222222],
      [39.1394444444444, -96.8313888888889],
    ],
    // Manhattan, KS  R-3602B
    [
      [39.2958333333333, -96.8308333333333],
      [39.1394444444444, -96.8313888888889],
      [39.1316666666667, -96.8313888888889],
      [39.0733333333333, -96.8730555555556],
      [39.0733333333333, -96.8544444444444],
      // Thence CW along the arc of a 4NM crcle centered on Cavalry NDB at [39.0261111111111,-96.7944444444444],
      [39.0782684715924, -96.8478175887693],
      [39.083890959126, -96.8372488981783],
      [39.0882218456266, -96.8257216048719],
      [39.0911641452435, -96.8134937072791],
      [39.0902777777778, -96.7716666666667],
      [39.1069444444444, -96.7447222222222],
      [39.1388888888889, -96.7169444444445],
      [39.1563888888889, -96.7169444444445],
      [39.1786111111111, -96.6822222222222],
      [39.2047222222222, -96.6822222222222],
      [39.2166666666667, -96.71],
      [39.2208333333333, -96.71],
      [39.2330555555556, -96.7569444444444],
      [39.2427777777778, -96.7661111111111],
      [39.2555555555556, -96.7747222222222],
      [39.2825, -96.8130555555556],
    ],
  ]