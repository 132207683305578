export const kansas =
  [
    // ADA East, KS
    [
      [39.1277777777778, -97.6336111111111],
      [39.625, -97.6502777777778],
      [39.775, -97.0002777777778],
      [39.0916666666667, -97.0002777777778],
      [39.025, -97.3961111111111],
      // CCW along 12NM DME arc of Salina VORTAC [38.9251381,-97.6213322]
      [39.0377155893977, -97.4092488420964],
      [39.061148730583, -97.4331064577476],
      [39.081541170046, -97.4611927142148],
      [39.0984346093966, -97.4928797233383],
      [39.1114490903107, -97.5274570576837],
      [39.1202917145019, -97.5641478487631],
    ],
    // ADA West, KS
    [
      [39.0333333333333, -97.8391666666667],
      [39.1333333333333, -98.5002777777778],
      [39.4333333333333, -98.5002777777778],
      [39.625, -97.6502777777778],
      [39.1277777777778, -97.6336111111111],
      // CCW along 12NM DME arc of Salina VORTAC [38.9251381,-97.6213322]
      [39.1247633824494, -97.6405378602629],
      [39.1202917145019, -97.6785165512368],
      [39.1114490903107, -97.7152073423162],
      [39.0984346093966, -97.7497846766616],
      [39.0815411700459, -97.7814716857851],
      [39.061148730583, -97.8095579422523],
    ],
    // Bison, KS
    [
      [38.5583333333333, -99.1502777777778],
      [38.7916666666667, -98.8252777777778],
      [38.8061111111111, -98.2416666666667],
      [38.4036111111111, -98.2416666666667],
      [38.3875, -98.3002777777778],
      [38.2666666666667, -98.4169444444445],
    ],
    // Riley, KS
    [
      [39.5583333333333, -97.0002777777778],
      [39.5583333333333, -96.75],
      [39.4291666666667, -96.6083333333333],
      [39.2047222222222, -96.6822222222222],
      [39.2166666666667, -96.71],
      [39.2208333333333, -96.71],
      [39.2330555555556, -96.7569444444444],
      [39.2427777777778, -96.7661111111111],
      [39.2555555555556, -96.7747222222222],
      [39.2825, -96.8130555555556],
      [39.2958333333333, -96.8308333333333],
      [39.2983333333333, -96.8366666666667],
      [39.2952777777778, -96.8741666666667],
      [39.3058333333333, -96.8969444444445],
      [39.3025, -96.9177777777778],
      [39.3063888888889, -96.9330555555556],
      [39.3066666666667, -96.9608333333333],
      [39.2111111111111, -96.9611111111111],
      // Along the shoreline of the main body of Milford Reservoir
      [39.1871, -96.9215],
      [39.1983, -96.9222],
      [39.2026, -96.9192],
      [39.2187, -96.9173],
      [39.2082, -96.9130],
      [39.2006, -96.9152],
      [39.1860, -96.9157],
      [39.1825, -96.9108333333333],
      [39.1827777777778, -96.8872222222222],
      [39.1394444444444, -96.8872222222222],
      [39.1394444444444, -96.8313888888889],
      [39.1316666666667, -96.8313888888889],
      [39.0733333333333, -96.8730555555556],
      [39.0958333333333, -97.0002777777778],
    ],
    // Smoky, KS
    [
      [38.8061111111111, -98.2416666666667],
      [38.8083333333333, -98.1502777777778],
      [38.7555555555556, -97.9336111111111],
      [38.5833333333333, -97.9336111111111],
      [38.5833333333333, -97.8336111111111],
      [38.6388888888889, -97.8336111111111],
      [38.6388888888889, -97.7919444444444],
      [38.6625, -97.7669444444444],
      [38.4555555555556, -97.8336111111111],
      [38.4555555555556, -98.0461111111111],
      [38.4036111111111, -98.2416666666667],
    ],
  ]