export const pennsylvania =
  [
    // Fort Indiantown Gap, PA R-5802A
    [
      [40.4583333333333, -76.7427777777778],
      [40.4919444444444, -76.6519444444445],
      [40.4752777777778, -76.6058333333333],
      [40.4183333333333, -76.7463888888889],
    ],
    // Fort Indiantown Gap, PA R-5802B
    [
      [40.4183333333333, -76.7463888888889],
      [40.4752777777778, -76.6058333333333],
      [40.4536111111111, -76.5869444444444],
      [40.4383333333333, -76.6111111111111],
      [40.39, -76.7261111111111],
    ],
    // Fort Indiantown Gap, PA R-5802C/D
    [
      [40.39, -76.7261111111111],
      [40.4183333333333, -76.7463888888889],
      [40.4666666666667, -76.7830555555555],
      [40.495, -76.7163888888889],
      [40.4919444444444, -76.6519444444445],
      [40.4752777777778, -76.6058333333333],
      [40.4536111111111, -76.5869444444444],
      [40.4383333333333, -76.6111111111111],
    ],
    // Fort Indiantown Gap, PA R-5802E
    [
      [40.495, -76.7163888888889],
      [40.4919444444444, -76.6519444444445],
      [40.4752777777778, -76.6058333333333],
      [40.4536111111111, -76.5869444444444],
      [40.3958333333333, -76.5433333333333],
      [40.3805555555556, -76.5675],
      [40.3319444444444, -76.6830555555556],
      // Thence CW along the arc of a 4NM radius circle centered at [40.39,-76.7261111111111],
      [40.332216006999, -76.6825225734929],
      [40.3278935405857, -76.6942634831211],
      [40.3249572657605, -76.7067138498048],
      [40.3234725931085, -76.7195962562657],
      [40.3234725931085, -76.7326237437342],
      [40.3249572657605, -76.7455061501952],
      [40.3278935405857, -76.7579565168788],
      [40.332216006999, -76.7696974265071],
      [40.3378283594738, -76.7804671380443],
      [40.3446055266227, -76.7900253864081],
      [40.3523964385898, -76.7981587217575],
      [40.3633333333333, -76.805],
      [40.4344444444444, -76.8594444444444],
      [40.4666666666667, -76.7830555555555],
    ],
  ]