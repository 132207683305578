export const georgia =
  [
    // Benning
    [
      [32.5502777777778, -84.6569444444444],
      [32.5502777777778, -84.5],
      [32.3669444444444, -84.5],
      [32.2836111111111, -84.6569444444444],
    ],
    // Bulldog A
    [
      [33.0169444444444, -82.875],
      [33.2336111111111, -82.4997222222222],
      [33.2002777777778, -82.3830555555555],
      [33.2169444444444, -82.1497222222222],
      [33.0961111111111, -82.0747222222222],
      [32.8336111111111, -82.0163888888889],
      [32.7225, -82.4088888888889],
      [32.7369444444444, -82.7719444444444],
    ],
    // Bulldog B
    [
      [33.2336111111111, -82.4997222222222],
      [33.2002777777778, -82.3830555555555],
      [33.2169444444444, -82.1497222222222],
      [33.2169444444444, -81.9830555555556],
      [32.9002777777778, -81.8413888888889],
      [32.5669444444444, -81.9330555555556],
      [32.5627777777778, -81.9636111111111],
      [32.5461111111111, -82.0622222222222],
      [32.5752777777778, -82.4247222222222],
      [32.6308333333333, -82.7330555555556],
      [33.0169444444444, -82.875],
      [33.2238888888889, -82.5247222222222],
    ],
    // Bulldog C
    [
      [32.9033333333333, -82.0316666666667],
      [32.8761111111111, -81.8480555555555],
      [32.5669444444444, -81.9330555555556],
      [32.5627777777778, -81.9636111111111],
      [32.5461111111111, -82.0622222222222],
      [32.5544444444444, -82.1675],
      [32.67, -82.1936111111111],
      [32.6952777777778, -82.2255555555556],
      [32.7225, -82.4088888888889],
      [32.8336111111111, -82.0163888888889],
    ],
    // Bulldog D
    [
      [33.2336111111111, -82.4997222222222],
      [33.3544444444444, -82.3127777777778],
      [33.2916666666667, -82.3830555555555],
      [33.2725, -82.2997222222222],
      [33.3288888888889, -82.2038888888889],
      [33.365, -82.2038888888889],
      [33.2169444444444, -82.1497222222222],
      [33.2002777777778, -82.3830555555555],
    ],
    // Coastal 1 East
    [
      [31.8502777777778, -81.3497222222222],
      [31.6836111111111, -81.3916666666667],
      [31.6252777777778, -81.4705555555556],
      [31.5419444444444, -81.4580555555556],
      [31.4377777777778, -81.5247222222222],
      [31.4252777777778, -81.5997222222222],
      [31.4501666666667, -81.6201666666667],
      [31.4678333333333, -81.6105],
      [31.479, -81.614],
      [31.4841666666667, -81.6318333333333],
      [31.4941666666667, -81.6441666666667],
      [31.507, -81.6551666666667],
      [31.5298333333333, -81.6518333333333],
      [31.5298333333333, -81.6613333333333],
      [31.534, -81.6648333333333],
      [31.5403333333333, -81.662],
      [31.5461666666667, -81.6696666666667],
      [31.5426666666667, -81.6798333333333],
      [31.5491666666667, -81.6785],
      [31.5533333333333, -81.6923333333333],
      [31.5796666666667, -81.697],
      [31.6521666666667, -81.8083333333333],
      [31.6498333333333, -81.8213333333333],
      [31.6655, -81.8536666666667],
      [31.6778333333333, -81.8495],
      [31.6948333333333, -81.8673333333333],
      [31.7116666666667, -81.875],
      [31.7228333333333, -81.9023333333333],
      [31.738, -81.91],
      [31.7625, -81.9155],
      [31.7548333333333, -81.9256666666667],
      [31.7648333333333, -81.9443333333333],
      [31.7865, -81.9525],
      [31.787, -81.9751666666667],
      [31.7997222222222, -81.9997222222222],
      [31.8502777777778, -81.9997222222222],
    ],
    // Coastal 1 West
    [
      [31.4252777777778, -81.5997222222222],
      [31.4501666666667, -81.6201666666667],
      [31.4678333333333, -81.6105],
      [31.479, -81.614],
      [31.4841666666667, -81.6318333333333],
      [31.4941666666667, -81.6441666666667],
      [31.507, -81.6551666666667],
      [31.5298333333333, -81.6518333333333],
      [31.5298333333333, -81.6613333333333],
      [31.534, -81.6648333333333],
      [31.5403333333333, -81.662],
      [31.5461666666667, -81.6696666666667],
      [31.5426666666667, -81.6798333333333],
      [31.5491666666667, -81.6785],
      [31.5533333333333, -81.6923333333333],
      [31.5796666666667, -81.697],
      [31.6521666666667, -81.8083333333333],
      [31.6498333333333, -81.8213333333333],
      [31.6655, -81.8536666666667],
      [31.6778333333333, -81.8495],
      [31.6948333333333, -81.8673333333333],
      [31.7116666666667, -81.875],
      [31.7228333333333, -81.9023333333333],
      [31.738, -81.91],
      [31.7625, -81.9155],
      [31.7548333333333, -81.9256666666667],
      [31.7648333333333, -81.9443333333333],
      [31.7865, -81.9525],
      [31.787, -81.9751666666667],
      [31.7997222222222, -81.9997222222222],
      [31.4002777777778, -81.9997222222222],
      [31.3330555555556, -81.8877777777778],
      [31.3330555555556, -81.7788888888889],
    ],
    // Coastal 2
    [
      [31.6836111111111, -81.3916666666667],
      [31.4666666666667, -81.4333333333333],
      [31.4377777777778, -81.5247222222222],
      [31.5419444444444, -81.4580555555556],
      [31.6252777777778, -81.4705555555556],
    ],
    // Coastal 4
    [
      [31.4666666666667, -81.4333333333333],
      [31.4377777777778, -81.5247222222222],
      [31.4252777777778, -81.5997222222222],
      [31.3330555555556, -81.7788888888889],
      [31.3330555555556, -81.8877777777778],
      [31.4002777777778, -81.9997222222222],
      [31.0502777777778, -81.8163888888889],
      [31.0502777777778, -81.5163888888889],
    ],
    // Coastal 5
    [
      [32.0836111111111, -81.9497222222222],
      [31.8502777777778, -81.9997222222222],
      [31.4002777777778, -81.9997222222222],
      [31.7502777777778, -82.2163888888889],
    ],
    // Coastal 6
    [
      [31.8586111111111, -81.6955555555556],
      [31.8502777777778, -81.6955555555556],
      [31.8502777777778, -81.9997222222222],
      [32.0836111111111, -81.9497222222222],
      [32.1836111111111, -81.7663888888889],
      [32.1669444444444, -81.6497222222222],
      [32.1419444444444, -81.6163888888889],
      [32.1086111111111, -81.6163888888889],
      [32.1086111111111, -81.6497222222222],
      [32.1169444444444, -81.7247222222222],
      [32.1247222222222, -81.7877777777778],
      [32.0902777777778, -81.8338888888889],
      [32.05, -81.8569444444444],
      [32.0394444444444, -81.8447222222222],
      [31.9961111111111, -81.8513888888889],
      [31.9502777777778, -81.8872222222222],
      [31.9252777777778, -81.8830555555555],
      [31.9169444444444, -81.8830555555555],
    ],
    // Coastal 7
    [
      [31.9011111111111, -81.4788888888889],
      [31.9377777777778, -81.3830555555555],
      [31.9794444444444, -81.3288888888889],
      [31.8502777777778, -81.3497222222222],
      [31.8502777777778, -81.6955555555556],
      [31.8586111111111, -81.6955555555556],
      [31.8725, -81.6358333333333],
      [31.8866666666667, -81.6305555555555],
      [31.89034, -81.623721],
      [31.895587, -81.61958],
      [31.907096, -81.590805],
      [31.920017, -81.571849],
      [31.927675, -81.557875],
      [31.936555, -81.53308],
      [31.939035, -81.518796],
      [31.9469444444444, -81.5113888888889],
      [31.9458914368942, -81.5091590862864],
      [31.9385100746931, -81.4998396077752],
      [31.9300262107959, -81.4919158690286],
      [31.9206296320591, -81.4855647241071],
      [31.9105304832601, -81.4809277142187],
    ],
    // Coastal 8
    [
      [32.0836111111111, -81.9497222222222],
      [31.7502777777778, -82.2163888888889],
      [32.0669444444444, -82.4052777777778],
      [32.2169444444444, -82.2747222222222],
      [32.1836111111111, -81.7663888888889],
    ],
    // Corsair North
    [
      [31.5002777777778, -84.1],
      [31.3333333333333, -83.5638888888889],
      [31, -83.4669444444445],
      [31, -83.8830555555555],
      [31.3002777777778, -84.0194444444444],
      [31.3794444444444, -84.0333333333333],
    ],
    // Corsair South
    [
      [31, -83.8830555555555],
      [31, -83.4669444444445],
      [30.6166666666667, -83.3555555555556],
      [30.6336111111111, -83.7166666666667],
    ],
    // Hawg North
    [
      [31.3830555555556, -83.1611111111111],
      [31.3113888888889, -82.7430555555556],
      [31.2169444444444, -82.65],
      [30.9502777777778, -82.65],
      [30.9502777777778, -83.1413888888889],
    ],
    // Hawg South
    [
      [30.9502777777778, -83.1413888888889],
      [30.9502777777778, -82.65],
      [30.5836111111111, -82.6497222222222],
      [30.6058333333333, -83.1255555555556],
    ],
    // Moody 3
    [
      [31.6752777777778, -84.6083333333333],
      [31.3336111111111, -84.4666666666667],
      [30.9613888888889, -84.4666666666667],
      [30.9113888888889, -84.6583333333333],
      [31.3502777777778, -85.1333333333333],
      [31.8419444444444, -85.0666666666667],
    ],
    // Mustang
    [
      [32.0719444444444, -83.5944444444444],
      [32.0627777777778, -83.4833333333333],
      [31.9719444444444, -83.3938888888889],
      [31.4944444444444, -83.3833333333333],
      [31.5683333333333, -83.6319444444444],
    ],
    // Sabre
    [
      [31.6875, -84.0333333333333],
      [31.4944444444444, -83.3833333333333],
      [31.3830555555556, -83.1611111111111],
      [30.6058333333333, -83.1255555555556],
      [30.6166666666667, -83.3555555555556],
      [31.3333333333333, -83.5638888888889],
      [31.5002777777778, -84.1],
    ],
    // Thud
    [
      [32.0627777777778, -83.9],
      [32.0836111111111, -83.7375],
      [32.0719444444444, -83.5944444444444],
      [31.5683333333333, -83.6319444444444],
      [31.6875, -84.0333333333333],
    ],
    // Warhawk
    [
      [31.9719444444444, -83.3938888888889],
      [31.3113888888889, -82.7430555555556],
      [31.3830555555556, -83.1611111111111],
      [31.4944444444444, -83.3833333333333],
    ],
  ]