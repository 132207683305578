export const atcaas =
  [
    // Black River ATCAA
    [
      [44.7695, -91.0021666666667],
      [44.7193333333333, -90.468],
      [44.1444444444444, -90.7388888888889],
      [44.0694, -90.7410],
      [44.2833333333333, -91.35],
    ],
    // Morenci ATCAA
    [
      [33.25, -109.566666666667],
      [33.25, -108.408333333333],
      [32.4716666666667, -109.316666666667],
      [32.5, -109.733333333333],
      [32.9083333333333, -109.566666666667],
    ],
    // Oshkosh ATCAA
    [
      [44.4833333333333, -89],
      [44.3833333333333, -88.4333333333333],
      [44.15, -88.3333333333333],
      [43.55, -88.35],
      [43.5916666666667, -88.775],
      [44.1595, -88.7541666666667],
    ],
    // Outlaw/Jackal ATCAA
    [
      [33.4916666666667, -111.383333333333],
      [33.75, -110.5],
      [34, -109.566666666667],
      [32.9083333333333, -109.566666666667],
      [32.5, -109.733333333333],
      [33.1166666666667, -111.383333333333],
    ],
    // Outlaw/Jackal ATCAA West
    [
      [33.4916666666667, -111.383333333333],
      [33.75, -110.5],
      [32.787068, -110.5],
      [33.1166666666667, -111.383333333333],
    ],
    // Ruby ATCAA (Floor 180)
    [
      [32.2533333333333, -111.6],
      [32, -111.033333333333],
      [31.3333333333333, -111.05],
      [31.3334, -111.0801],
      [31.5166666666667, -111.641666666667],
      [31.725, -111.591666666667],
      [31.8166666666667, -111.533333333333],
      [31.9616666666667, -111.6],
    ],
    // Ruby ATCAA (Floor 240)
    [
      [32.2533333333333, -111.6],
      [32, -111.033333333333],
      [31.9616666666667, -111.6],
    ],
    // Rustler ATCAA
    [
      [33.8766666666667, -109.566666666667],
      [33.955, -109.326666666667],
      [33.2683333333333, -108.381666666667],
      [32.4716666666667, -109.316666666667],
      [32.5, -109.733333333333],
      [32.9083333333333, -109.566666666667],
    ],
    // Sells ATCAA
    [
      [31.9666666666667, -113.0925],
      [32.1916666666667, -113.0925],
      [32.1916666666667, -112.946666666667],
      [32.4833333333333, -112.8925],
      [32.8402777777778, -112.8175],
      [32.8477777777778, -112.715555555556],
      [32.8166666666667, -112.650833333333],
      [32.6416666666667, -112.300833333333],
      [32.2527777777778, -111.600555555556],
      [31.9625, -111.600555555556],
      [31.8166666666667, -111.533888888889],
      [31.725, -111.592222222222],
      [31.5166666666667, -111.642222222222],
    ],
    // Sheboygan East ATCAA
    [
      [44.0916666666667, -87.4958333333333],
      [44.0205, -86.9438333333333],
      [43.6833333333333, -86.6333333333333],
      [43.2833333333333, -86.7333333333333],
      [43.2583333333333, -87.2333333333333],
      [43.3166666666667, -87.6833333333333],
    ],
    // Sheboygan West ATCAA
    [
      [44.3833333333333, -88.4333333333333],
      [44.0916666666667, -87.4958333333333],
      [43.3166666666667, -87.6833333333333],
      [43.55, -88.35],
      [44.15, -88.3333333333333],
    ]
  ]