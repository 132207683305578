export const northCarolina =
  [
    // Gamecock A, NC
    [
      [34.7613888888889, -78.6913888888889],
      [34.5377777777778, -78.3288888888889],
      [34.4002777777778, -78.4275],
      [34.3572222222222, -79.0663888888889],
      [34.5169444444444, -79.0663888888889],
    ],
    // Gamecock B, NC
    [
      [33.8169444444444, -79.3080555555556],
      [33.6336111111111, -79.1247222222222],
      [33.4252777777778, -79.4247222222222],
      [33.4002777777778, -79.6163888888889],
    ],
    // Gamecock C, NC
    [
      [33.8169444444444, -79.6913888888889],
      [33.8419444444444, -79.4163888888889],
      [33.8419444444444, -79.3413888888889],
      [33.8169444444444, -79.3080555555556],
      [33.6336111111111, -79.1247222222222],
      [33.4252777777778, -79.4247222222222],
      [33.4002777777778, -79.6163888888889],
      [33.4108333333333, -79.8075],
    ],
    // Gamecock D, NC
    [
      [33.8836111111111, -79.9163888888889],
      [33.8336111111111, -79.7997222222222],
      [33.8169444444444, -79.6913888888889],
      [33.8419444444444, -79.4163888888889],
      [33.8419444444444, -79.3413888888889],
      [33.8169444444444, -79.3080555555556],
      [33.4002777777778, -79.6163888888889],
      [33.4169444444444, -79.9163888888889],
      [33.5419444444444, -80.2663888888889],
    ],
    // Gamecock I, NC
    [
      [34.7405555555556, -80.6497222222222],
      [34.6002777777778, -80.3830555555555],
      [34.4708333333333, -80.4072222222222],
      [34.2669444444444, -80.7552777777778],
      [34.6419444444444, -80.8663888888889],
    ],
    // Pamlico A, NC
    [
      [35.8041666666667, -75.7286111111111],
      [35.4919444444444, -75.4166666666667],
      [35.4502777777778, -75.4191666666667],
      [35.4502777777778, -75.8052777777778],
      [35.5780555555556, -75.8052777777778],
      [35.5780555555556, -75.7802777777778],
    ],
    // Pamlico B, NC
    [
      [35.6127777777778, -76.0219444444444],
      [35.5780555555556, -75.8052777777778],
      [35.4502777777778, -75.8052777777778],
      [35.4502777777778, -75.4191666666667],
      // Thence south and southwest 3NM from and parallel to the shoreline
      [35.2163, -75.4651],
      [35.0086111111111, -76.0163888888889],
      [35.3044444444444, -76.2775],
    ],
    // Phelps A, NC
    [
      [35.8730555555556, -76.1644444444445],
      [35.6738888888889, -76.2066666666667],
      [35.7308333333333, -76.5913888888889],
      [35.8975, -76.5525],
    ],
    // Phelps B, NC
    [
      [35.8647222222222, -76.0355555555555],
      [35.6558333333333, -76.0830555555555],
      [35.6738888888889, -76.2066666666667],
      [35.8730555555556, -76.1644444444445],
    ],
    // Phelps C, NC
    [
      [35.86, -75.965],
      [35.6486111111111, -76.0144444444445],
      [35.6558333333333, -76.0830555555555],
      [35.8647222222222, -76.0355555555555],
    ],
    // Stumpy Point, NC
    [
      [35.6502777777778, -75.7636111111111],
      [35.6502777777778, -75.5663888888889],
      [35.5502777777778, -75.5663888888889],
      [35.4502777777778, -75.6330555555555],
      [35.4502777777778, -75.8052777777778],
      [35.5780555555556, -75.8052777777778],
      [35.5780555555556, -75.7802777777778],
    ],
  ]