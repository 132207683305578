export const p518 =
[
  [37.6523733333333, 126.164636666667],
  [37.7189783333333, 126.681271666667],
  [37.6357233333333, 126.881323333333],
  [37.969175, 127.664576666667],
  [38.0690516666667, 128.514305],
  [38.1357183333333, 128.530991666667],
  [38.2052316666667, 128.599661666667],
  [38.265975, 128.569366666667],
  [38.2999383333333, 128.550175],
  [38.3303583333333, 128.531086666667],
  [38.3656916666667, 128.513811666667],
  [38.3818733333333, 128.505866666667],
  [38.4410633333333, 128.469183333333],
  [38.44929, 128.464168333333],
  [38.4909583333333, 128.442371666667],
  [38.51968, 128.427016666667],
  [38.5310083333333, 128.420953333333],
  [38.5396383333333, 128.416625],
  [38.5554166666667, 128.407966666667],
  [38.5864333333333, 128.391513333333],
  [38.6332916666667, 128.366941666667]
]