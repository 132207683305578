export const colorado =
  [
    // Airburst X, CO
    [
      [38.7, -105.133888888889],
      [38.6666666666667, -104.883888888889],
      [38.625, -104.883888888889],
      // Thence Southwest along Colorado HWY 15 to
      [38.6183, -104.8844],
      [38.6082, -104.9001],
      [38.5853, -104.9181],
      [38.5674, -104.9424],
      [38.5506, -104.9481],
      [38.5460, -104.9570],
      [38.5420, -104.9613],
      [38.5372, -104.9628],
      [38.5314, -104.9664],
      [38.5297, -104.9665],
      [38.5263, -104.9649],
      [38.5216, -104.9669],
      [38.5184, -104.9672],
      [38.5159, -104.9658],
      [38.5139, -104.9650],
      [38.5116, -104.9629],
      [38.5045, -104.9597],
      [38.5011, -104.9567],
      [38.4968, -104.9567],
      [38.4930555555556, -104.958888888889],
      [38.4355555555556, -104.958888888889],
      [38.4375, -105],
      [38.4833333333333, -105.100555555556],
      [38.4833333333333, -105.258888888889],
    ],
    // Airburst Y, CO
    [
      [38.4375, -105],
      [38.4355555555556, -104.958888888889],
      [38.4361111111111, -104.817222222222],
      [38.4263888888889, -104.817222222222],
      [38.4, -104.831666666667],
      [38.4125, -104.979722222222],
    ],
    // Airburst Z, CO
    [
      [38.4125, -104.979722222222],
      [38.4, -104.831666666667],
      [38.36, -104.845],
      // Thence west along US HWY 50 to
      [38.3561, -104.8464],
      [38.3723, -104.9417],
      [38.3855, -104.9600],
      [38.3972222222222, -104.966666666667],
    ],
    // Cougar High/Low, CO
    [
      [38.8916666666667, -103],
      [39.1219444444444, -102.245277777778],
      [39.0277777777778, -101],
      [38.6361111111111, -101],
      [38.3916666666667, -102.201111111111],
      [38.3916666666667, -102.742777777778],
      [38.5780555555556, -103],
    ],
    // La Veta High, CO
    [
      [38.2583333333333, -104.917222222222],
      [38.2833333333333, -105.317222222222],
      [37.8333333333333, -105.500555555556],
      [37.6, -105.500555555556],
      [37.6, -105.083888888889],
      [37.675, -104.750555555556],
      [37.9166666666667, -104.633888888889],
      [38.0666666666667, -104.817222222222],
    ],
    // Two Buttes High/Low, CO
    [
      [37.8816666666667, -103.52],
      [37.9833333333333, -103],
      [37.85, -102.468333333333],
      [37.6933333333333, -102.311666666667],
      [37.3266666666667, -103.291666666667],
      [37.455, -103.518333333333],
    ],
  ]