export const missouri =
  [
    // Cannon A, MO
    [
      [37.555, -92.1488888888889],
      [37.4555555555556, -92.2025],
      [37.3427777777778, -92.4177777777778],
      [37.3833333333333, -92.5166666666667],
      [37.625, -92.5166666666667],
      [37.675, -92.3166666666667],
      [37.6, -92.325],
      [37.6041666666667, -92.1769444444444],
    ],
    // Cannon B, MO
    [
      [37.6916666666667, -92.2502777777778],
      [37.6041666666667, -92.2561111111111],
      [37.6, -92.3252777777778],
      [37.675, -92.3169444444444],
    ],
    // Lindbergh A, MO
    [
      [37.35, -92.0541666666667],
      [37.6638888888889, -91.8838888888889],
      [37.86, -91.0177777777778],
      [37.7783333333333, -90.7683333333333],
      [37.1730555555555, -90.8705555555556],
      [36.9905555555556, -92.0541666666667],
    ],
    // Lindbergh B, MO
    [
      [36.9905555555556, -92.0541666666667],
      [37.1730555555555, -90.8705555555556],
      [36.9225, -90.9138888888889],
      [36.75, -92.0541666666667],
    ],
    // Lindbergh C, MO
    [
      [36.75, -92.0541666666667],
      [36.9225, -90.9138888888889],
      [36.6333333333333, -90.9666666666667],
      [36.6333333333333, -91.8333333333333],
    ],
    // Salem, MO
    [
      [37.2294444444444, -90.5672222222222],
      [37.7202777777778, -90.5922222222222],
      [37.9038888888889, -91.1525],
      [37.6911111111111, -91.4936111111111],
      // Via the 60NM arc of Farmington, MO VORTAC [37.6734614,-90.2340597]
      [37.6667376063556, -91.493428211042],
      [37.5177040933521, -91.4768786416379],
      [37.3722821083482, -91.4327491840321],
      [37.3313888888889, -91.4152777777778],
      [37.1763888888889, -91.0158333333333],
    ],
    // Truman A, MO
    [
      [38.9833333333333, -93.7502777777778],
      [38.9333333333333, -92.9836111111111],
      [38.6916666666667, -92.6208333333333],
      [38.5, -93.7494444444444],
      [38.825, -93.9086111111111],
    ],
    // Truman B, MO
    [
      [38.5, -93.7494444444444],
      [38.6916666666667, -92.6208333333333],
      [38.6666666666667, -92.5836111111111],
      [38.3638888888889, -92.7975],
      [38.4, -93.0336111111111],
      [38.25, -93.6169444444444],
    ],
    // Truman C, MO
    [
      [38.25, -93.6169444444444],
      [38.4, -93.0336111111111],
      [38.3638888888889, -92.7975],
      [38.0333333333333, -93.0002777777778],
      [38, -93.4836111111111],
    ],
  ]