export const georgia =
  [
    // Benning R-3002A/B/C
    [
      [32.52, -84.8363888888889],
      [32.3175, -84.695],
      [32.3191666666667, -84.7075],
      [32.3205555555556, -84.7144444444445],
      [32.3230555555556, -84.7216666666667],
      [32.3263888888889, -84.7302777777778],
      [32.3286111111111, -84.7413888888889],
      [32.3319444444444, -84.7516666666667],
      [32.3369444444444, -84.765],
      [32.3416666666667, -84.7755555555556],
      [32.3480555555556, -84.7819444444444],
      [32.3486111111111, -84.7938888888889],
      [32.2569444444444, -84.7922222222222],
      [32.2572222222222, -84.8102777777778],
      [32.2547222222222, -84.8102777777778],
      [32.2547222222222, -84.8133333333333],
      [32.2516666666667, -84.8188888888889],
      [32.2466666666667, -84.8238888888889],
      [32.2438888888889, -84.8313888888889],
      [32.2422222222222, -84.8375],
      [32.2394444444444, -84.8416666666667],
      [32.2366666666667, -84.8433333333333],
      [32.2394444444444, -84.8727777777778],
      [32.2519444444444, -84.8725],
      [32.2516666666667, -84.8772222222222],
      [32.2591666666667, -84.8769444444444],
      [32.2594444444444, -84.8863888888889],
      [32.3375, -84.9766666666667],
      [32.3433333333333, -84.9708333333333],
      [32.3480555555556, -84.9652777777778],
      [32.3508333333333, -84.9611111111111],
      [32.3530555555556, -84.9566666666667],
      [32.3522222222222, -84.9486111111111],
      [32.3536111111111, -84.9344444444445],
      [32.3591666666667, -84.9263888888889],
      [32.3638888888889, -84.9211111111111],
      [32.3647222222222, -84.9166666666667],
      [32.3683333333333, -84.9113888888889],
      [32.3836111111111, -84.9288888888889],
      [32.4133333333333, -84.8811111111111],
      [32.4266666666667, -84.8811111111111],
      [32.4288888888889, -84.8916666666667],
      [32.4386111111111, -84.8919444444444],
      [32.4388888888889, -84.8983333333333],
      [32.4552777777778, -84.8980555555556],
      [32.4547222222222, -84.8694444444444],
      [32.4794444444444, -84.8688888888889],
      [32.4788888888889, -84.8463888888889],
      [32.4952777777778, -84.8497222222222],
      [32.5097222222222, -84.8472222222222],
      [32.5108333333333, -84.8397222222222],
    ],
    // R-3002D
    [
      [32.52, -84.8363888888889],
      [32.5311111111111, -84.8402777777778],
      [32.5513888888889, -84.7575],
      [32.5477777777778, -84.75],
      [32.5452777777778, -84.7355555555555],
      [32.5427777777778, -84.7277777777778],
      [32.5394444444444, -84.7202777777778],
      [32.5383333333333, -84.7147222222222],
      [32.5355555555556, -84.7105555555556],
      [32.5347222222222, -84.7072222222222],
      [32.5363888888889, -84.7033333333333],
      [32.5369444444444, -84.6983333333333],
      [32.5361111111111, -84.6938888888889],
      [32.535, -84.6902777777778],
      [32.5355555555556, -84.6880555555556],
      [32.5375, -84.6836111111111],
      [32.5388888888889, -84.6822222222222],
      [32.5352777777778, -84.6788888888889],
      [32.5183333333333, -84.6952777777778],
      [32.5177777777778, -84.6816666666667],
      [32.5344444444444, -84.6377777777778],
      [32.4877777777778, -84.6380555555556],
      [32.4861111111111, -84.6569444444444],
      [32.3097222222222, -84.6583333333333],
      [32.3063888888889, -84.6858333333333],
      [32.3175, -84.695],
    ],
    // R-3002F
    [
      [32.4547222222222, -84.8694444444444],
      [32.4794444444444, -84.8688888888889],
      [32.4788888888889, -84.8463888888889],
      [32.4952777777778, -84.8497222222222],
      [32.5097222222222, -84.8472222222222],
      [32.5108333333333, -84.8397222222222],
      [32.52, -84.8363888888889],
      [32.5311111111111, -84.8402777777778],
      [32.5513888888889, -84.7575],
      [32.5477777777778, -84.75],
      [32.5452777777778, -84.7355555555555],
      [32.5427777777778, -84.7277777777778],
      [32.5394444444444, -84.7202777777778],
      [32.5383333333333, -84.7147222222222],
      [32.5355555555556, -84.7105555555556],
      [32.5347222222222, -84.7072222222222],
      [32.5363888888889, -84.7033333333333],
      [32.5369444444444, -84.6983333333333],
      [32.5361111111111, -84.6938888888889],
      [32.535, -84.6902777777778],
      [32.5355555555556, -84.6880555555556],
      [32.5375, -84.6836111111111],
      [32.5388888888889, -84.6822222222222],
      [32.5352777777778, -84.6788888888889],
      [32.5183333333333, -84.6952777777778],
      [32.5177777777778, -84.6816666666667],
      [32.5344444444444, -84.6377777777778],
      [32.4877777777778, -84.6380555555556],
      [32.4861111111111, -84.6569444444444],
      [32.3097222222222, -84.6583333333333],
      [32.3063888888889, -84.6858333333333],
      [32.3175, -84.695],
      [32.3191666666667, -84.7075],
      [32.3205555555556, -84.7144444444445],
      [32.3230555555556, -84.7216666666667],
      [32.3263888888889, -84.7302777777778],
      [32.3286111111111, -84.7413888888889],
      [32.3319444444444, -84.7516666666667],
      [32.3369444444444, -84.765],
      [32.3416666666667, -84.7755555555556],
      [32.3480555555556, -84.7819444444444],
      [32.3486111111111, -84.7938888888889],
    ],
    // R-3002G
    [
      [32.3375, -84.9766666666667],
      [32.2594444444444, -84.8863888888889],
      [32.2588888888889, -84.9005555555556],
      [32.2511111111111, -84.9233333333333],
      [32.2408333333333, -84.9138888888889],
      [32.2402777777778, -84.9480555555556],
      [32.2433333333333, -84.9480555555556],
      [32.2438888888889, -84.9655555555556],
      [32.2766666666667, -84.9661111111111],
      [32.2766666666667, -84.9763888888889],
      [32.2941666666667, -84.9763888888889],
      [32.2944444444444, -84.9816666666667],
    ],
    // Fort Gordon R-3004A/B/C
    [
      [33.365, -82.2038888888889],
      [33.3288888888889, -82.2038888888889],
      [33.2725, -82.2997222222222],
      [33.2916666666667, -82.3830555555555],
      [33.3544444444444, -82.3127777777778],
      [33.3711111111111, -82.2830555555555],
    ],
    // Fort Stewart R-3005A
    [
      [32.1169444444444, -81.7247222222222],
      [31.9336111111111, -81.7247222222222],
      [31.936772, -81.730972],
      [31.938271, -81.740206],
      [31.93928, -81.742446],
      [31.943391, -81.745725],
      [31.945133, -81.749938],
      [31.942693, -81.772485],
      [31.93909, -81.784373],
      [31.934255, -81.812697],
      [31.932321, -81.85321],
      [31.9252777777778, -81.8830555555555],
      [31.9502777777778, -81.8872222222222],
      [31.9961111111111, -81.8513888888889],
      [32.0394444444444, -81.8447222222222],
      [32.05, -81.8569444444444],
      [32.0902777777778, -81.8338888888889],
      [32.1247222222222, -81.7877777777778],
    ],
    // R-3005B
    [
      [32.1127777777778, -81.6163888888889],
      [32.094399, -81.626184],
      [32.064981, -81.644741],
      [32.046473, -81.666203],
      [32.044316, -81.667552],
      [32.027212, -81.671427],
      [31.963134, -81.648335],
      [31.946256, -81.646322],
      [31.908371, -81.636277],
      [31.9002777777778, -81.6372222222222],
      [31.899733, -81.655363],
      [31.901601, -81.65908],
      [31.915871, -81.672707],
      [31.930715, -81.719823],
      [31.9336111111111, -81.7247222222222],
      [32.1169444444444, -81.7247222222222],
    ],
    // R-3005C
    [
      [32.0794444444444, -81.4413888888889],
      [31.9586111111111, -81.4413888888889],
      [31.96608, -81.460105],
      [31.965443, -81.467181],
      [31.957341, -81.488411],
      [31.952712, -81.504066],
      [31.948588, -81.510349],
      [31.939769, -81.517976],
      [31.938394, -81.520229],
      [31.936316, -81.533103],
      [31.927576, -81.558034],
      [31.919921, -81.572016],
      [31.90708, -81.590724],
      [31.895577, -81.619314],
      [31.890636, -81.62356],
      [31.8866666666667, -81.6305555555555],
      [31.908371, -81.636277],
      [31.946256, -81.646322],
      [31.963134, -81.648335],
      [32.027212, -81.671427],
      [32.044316, -81.667552],
      [32.046473, -81.666203],
      [32.064981, -81.644741],
      [32.094399, -81.626184],
      [32.1127777777778, -81.6163888888889],
      [32.1044444444444, -81.5247222222222],
      [32.0919444444444, -81.5247222222222],
      [32.0877777777778, -81.4997222222222],
    ],
    // R-3005D
    [
      [31.9586111111111, -81.4413888888889],
      [32.0794444444444, -81.4413888888889],
      [32.0711111111111, -81.3747222222222],
      [32.0086111111111, -81.3247222222222],
      [31.9794444444444, -81.3288888888889],
      [31.9377777777778, -81.3830555555555],
      [31.9011111111111, -81.4788888888889],
      [31.9105304832601, -81.4809277142187],
      [31.9206296320591, -81.4855647241071],
      [31.9300262107959, -81.4919158690286],
      [31.9385100746931, -81.4998396077752],
      [31.9458914368942, -81.5091590862864],
      [31.9469444444444, -81.5113888888889],
      [31.948588, -81.510349],
      [31.952712, -81.504066],
      [31.957341, -81.488411],
      [31.965443, -81.467181],
      [31.96608, -81.460105],
    ],
    // R-3005E
    [
      [31.9002777777778, -81.6372222222222],
      [31.8866666666667, -81.6305555555555],
      [31.8725, -81.6358333333333],
      [31.8655555555556, -81.6636111111111],
      [31.8586111111111, -81.6955555555556],
      [31.9169444444444, -81.8830555555555],
      [31.9252777777778, -81.8830555555555],
      [31.932321, -81.85321],
      [31.934255, -81.812697],
      [31.93909, -81.784373],
      [31.942693, -81.772485],
      [31.945133, -81.749938],
      [31.943391, -81.745725],
      [31.93928, -81.742446],
      [31.938271, -81.740206],
      [31.936772, -81.730972],
      [31.930715, -81.719823],
      [31.915871, -81.672707],
      [31.901601, -81.65908],
      [31.899733, -81.655363],
    ],
    // Townsend R-3007A
    [
      [31.5762293291986, -81.5782514914472],
      [31.5760995895261, -81.5760259551167],
      [31.5758050551433, -81.5738221723019],
      [31.5753479691861, -81.5716569264417],
      [31.5747318127205, -81.5695467068818],
      [31.5739612781776, -81.5675075830621],
      [31.5730422335467, -81.5655550819328],
      [31.5719816776028, -81.5637040695434],
      [31.5707876865109, -81.5619686377108],
      [31.5694693522196, -81.5603619966364],
      [31.5680367131151, -81.5588963742923],
      [31.5665006774697, -81.5575829233465],
      [31.5648729402707, -81.5564316363334],
      [31.5631658940687, -81.5554512697155],
      [31.5613925345262, -81.5546492774103],
      [31.5595663613923, -81.5540317542832],
      [31.5577012756579, -81.5536033900287],
      [31.5558114736791, -81.553367433785],
      [31.5539113390771, -81.5533256697394],
      [31.552015333237, -81.5534784039019],
      [31.550137885243, -81.5538244621361],
      [31.5482932820849, -81.5543611994513],
      [31.5464955599726, -81.5550845204752],
      [31.5447583975838, -81.5559889109397],
      [31.543095012055, -81.5570674799319],
      [31.5415180585045, -81.5583120125788],
      [31.5400395338494, -81.5597130327584],
      [31.5386706856431, -81.5612598753543],
      [31.5374219266247, -81.5629407674984],
      [31.5363027556245, -81.5647429181833],
      [31.5353216854238, -81.5666526155598],
      [31.5344861781131, -81.5686553311824],
      [31.5338025884363, -81.5707358304116],
      [31.5332761155479, -81.5728782881382],
      [31.5329107635473, -81.5750664089557],
      [31.5327093110862, -81.5772835508743],
      [31.5326732902792, -81.5795128516444],
      [31.5328029750759, -81.5817373567369],
      [31.5330973791814, -81.5839401480193],
      [31.5335542635419, -81.5861044721572],
      [31.5341701533389, -81.5882138677754],
      [31.5349403643627, -81.5902522904215],
      [31.5358590385686, -81.5922042343902],
      [31.5369191885467, -81.5940548504901],
      [31.5381127505713, -81.5957900588629],
      [31.5394306458286, -81.5973966560046],
      [31.5408628493624, -81.5988624151755],
      [31.5423984662177, -81.6001761794393],
      [31.5440258142057, -81.6013279466225],
      [31.5457325126668, -81.6023089455466],
      [31.5475055765595, -81.6031117029495],
      [31.549331515163, -81.6037301005813],
      [31.5511964346474, -81.6041594220342],
      [31.5530861437319, -81.6043963889417],
      [31.5549862616315, -81.6044391862613],
      [31.5568823274706, -81.6042874764402],
      [31.5587599103336, -81.6039424023438],
      [31.5606047191132, -81.6034065789145],
      [31.5624027113216, -81.6026840736137],
      [31.5641402000336, -81.6017803757848],
      [31.5658039581467, -81.6007023551606],
      [31.567381319161, -81.5994582098233],
      [31.5688602737089, -81.5980574040043],
      [31.5702295610958, -81.5965105961923],
      [31.5714787551514, -81.5948295580922],
      [31.5725983437322, -81.5930270850495],
      [31.5735798012676, -81.5911168986214],
      [31.5744156537905, -81.5891135420368],
      [31.5750995359552, -81.5870322693459],
      [31.5756262396028, -81.5848889291063],
      [31.575991753502, -81.5826998434983],
      [31.5761932939584, -81.5804816837981],
    ],
    // R-3007B
    [
      [31.6336111111111, -81.4830555555556],
      [31.6252777777778, -81.4705555555556],
      [31.5419444444444, -81.4580555555556],
      [31.4377777777778, -81.5247222222222],
      [31.4252777777778, -81.5997222222222],
      [31.4572222222222, -81.5608333333333],
      [31.5238888888889, -81.5327777777778],
      [31.5242713165348, -81.5346167799024],
      [31.5250070268042, -81.5374017273325],
      [31.5260900129481, -81.5400276160012],
      [31.5274960941926, -81.5424358093532],
      [31.5291938745184, -81.5445725249117],
      [31.5311454427957, -81.5463900345712],
      [31.5333072183713, -81.5478477305329],
      [31.5356309233305, -81.5489130330916],
      [31.5380646598284, -81.5495621199365],
      [31.540554068523, -81.5497804605398],
      [31.543043542312, -81.5495631434977],
      [31.5454774683093, -81.5489149892663],
      [31.5478014703351, -81.5478504455063],
      [31.5499636241488, -81.546393267106],
      [31.5519156182275, -81.544575987783],
      [31.5536138340837, -81.5424391948698],
      [31.555020321889, -81.5400306233451],
      [31.5561036494991, -81.5374040892879],
      [31.5568396057996, -81.5346182865991],
      [31.5572117425527, -81.5317354739684],
      [31.5572117425527, -81.5288200815872],
      [31.5568396057996, -81.5259372689565],
      [31.5561036494991, -81.5231514662678],
      [31.555020321889, -81.5205249322105],
      [31.555, -81.5202777777778],
    ],
    // R-3007C
    [
      [31.6336111111111, -81.7830555555555],
      [31.7086111111111, -81.5663888888889],
      [31.6336111111111, -81.4830555555556],
      [31.555, -81.5202777777778],
      [31.555020321889, -81.5205249322105],
      [31.5561036494991, -81.5231514662678],
      [31.5568396057996, -81.5259372689565],
      [31.5572117425527, -81.5288200815872],
      [31.5572117425527, -81.5317354739684],
      [31.5568396057996, -81.5346182865991],
      [31.5561036494991, -81.5374040892879],
      [31.555020321889, -81.5400306233451],
      [31.5536138340837, -81.5424391948698],
      [31.5519156182275, -81.544575987783],
      [31.5499636241488, -81.546393267106],
      [31.5478014703351, -81.5478504455063],
      [31.5454774683093, -81.5489149892663],
      [31.543043542312, -81.5495631434977],
      [31.540554068523, -81.5497804605398],
      [31.5380646598284, -81.5495621199365],
      [31.5356309233305, -81.5489130330916],
      [31.5333072183713, -81.5478477305329],
      [31.5311454427957, -81.5463900345712],
      [31.5291938745184, -81.5445725249117],
      [31.5274960941926, -81.5424358093532],
      [31.5260900129481, -81.5400276160012],
      [31.5250070268042, -81.5374017273325],
      [31.5242713165348, -81.5346167799024],
      [31.5238888888889, -81.5327777777778],
      [31.4572222222222, -81.5608333333333],
      [31.4252777777778, -81.5997222222222],
      [31.4501666666667, -81.6201666666667],
      [31.4678333333333, -81.6105],
      [31.479, -81.614],
      [31.4841666666667, -81.6318333333333],
      [31.4941666666667, -81.6441666666667],
      [31.507, -81.6551666666667],
      [31.5298333333333, -81.6518333333333],
      [31.5298333333333, -81.6613333333333],
      [31.534, -81.6648333333333],
      [31.5403333333333, -81.662],
      [31.5461666666667, -81.6696666666667],
      [31.5426666666667, -81.6798333333333],
      [31.5491666666667, -81.6785],
      [31.5533333333333, -81.6923333333333],
      [31.5796666666667, -81.697],
    ],
    // R-3007 Range 6
    [
      [31.5329166666667, -81.5794666666667],
      [31.53285, -81.5699333333333],
      [31.5247333333333, -81.57],
      [31.5247666666667, -81.5795],
    ],
    // Grand Bay R-3008A
    [
      [30.96, -83.1847222222222],
      [30.9869444444444, -83.1666666666667],
      [30.9869444444444, -83.1313888888889],
      [30.9752777777778, -83.1313888888889],
      [30.9752777777778, -83.1291666666667],
      [30.9622222222222, -83.1291666666667],
      [30.9622222222222, -83.1347222222222],
      [30.9488888888889, -83.1347222222222],
      [30.94, -83.1452777777778],
      [30.9475, -83.1666666666667],
    ],
    // R-3008B
    [
      [30.9869444444444, -83.1666666666667],
      [31.0336111111111, -83.15],
      [31.0252777777778, -83.1],
      [30.9086111111111, -83.1],
      [30.8919444444444, -83.15],
      [30.9475, -83.1666666666667],
      [30.94, -83.1452777777778],
      [30.9488888888889, -83.1347222222222],
      [30.9622222222222, -83.1347222222222],
      [30.9622222222222, -83.1291666666667],
      [30.9752777777778, -83.1291666666667],
      [30.9752777777778, -83.1313888888889],
      [30.9869444444444, -83.1313888888889],
    ],
    // R-3008C
    [
      [31.0669444444444, -83.0166666666667],
      [31.0669444444444, -83.1333333333333],
      [31.0336111111111, -83.15],
      [31.0252777777778, -83.1],
      [30.9086111111111, -83.1],
      [30.8919444444444, -83.15],
      [30.8502777777778, -83.1333333333333],
      [30.8502777777778, -83.0166666666667],
    ],
  ]