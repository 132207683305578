export const california =
  [
    // Bullion Mountains R-2501A
    [
      [34.7166666666667, -116.439722222222],
      [34.7166666666667, -116.284166666667],
      [34.6566666666667, -116.488611111111],
      [34.675, -116.495277777778],
    ],
    // R-2501B
    [
      [34.6566666666667, -116.488611111111],
      [34.7166666666667, -116.284166666667],
      [34.6875, -116.075833333333],
      [34.6833333333333, -116.050833333333],
      [34.525, -116.446666666667],
      [34.6, -116.4675],
    ],
    // R-2501C
    [
      [34.525, -116.446666666667],
      [34.6833333333333, -116.050833333333],
      [34.5916666666667, -115.9675],
      [34.5777777777778, -115.916111111111],
      [34.5613888888889, -115.84],
      [34.3597222222222, -116.360555555556],
      [34.5, -116.439722222222],
    ],
    // R-2501D
    [
      [34.3597222222222, -116.360555555556],
      [34.5613888888889, -115.84],
      [34.55, -115.784166666667],
      [34.4166666666667, -115.784166666667],
      [34.4166666666667, -115.734166666667],
      [34.2833333333333, -115.734166666667],
      [34.2336111111111, -115.983333333333],
      [34.2333333333333, -116.284166666667],
      [34.325, -116.341388888889],
    ],
    // R-2501E
    [
      [34.2833333333333, -115.734166666667],
      [34.2333333333333, -115.734166666667],
      [34.2336111111111, -115.983333333333],
    ],
    // Fort Irwin, CA R-2502A
    [
      [35.43, -116.313333333333],
      [35.425, -116.162777777778],
      [35.3875, -116.163055555556],
      [35.115, -116.504722222222],
      [35.1166666666667, -116.5675],
      [35.3125, -116.313333333333],
    ],
    // R-2502E
    [
      [35.4763888888889, -116.313333333333],
      [35.3125, -116.313333333333],
      [35.1166666666667, -116.5675],
      [35.1166666666667, -116.796666666667],
      [35.1472222222222, -116.811944444444],
      [35.1736111111111, -116.705],
    ],
    // R-2502N
    [
      [35.6291666666667, -116.495277777778],
      [35.575, -116.495277777778],
      [35.575, -116.3925],
      [35.4763888888889, -116.313333333333],
      [35.1736111111111, -116.705],
      [35.1472222222222, -116.811944444444],
      [35.1666666666667, -116.8175],
      [35.3166666666667, -116.8175],
      [35.3166666666667, -116.923055555556],
      [35.6291666666667, -116.923055555556],
    ],
    // China Lake, CA R-2505
    [
      [36.2333333333333, -117.884166666667],
      [36.2333333333333, -117.4175],
      [35.675, -117.4175],
      [35.625, -117.5925],
      [35.625, -117.7925],
      [35.9, -117.884166666667],
    ],
    // China Lake South, CA R-2506
    [
      [35.625, -117.689722222222],
      [35.4666666666667, -117.681388888889],
      [35.4666666666667, -117.784166666667],
      [35.625, -117.7925],
    ],
    // North Chocolate Mountains R-2507N
    [
      [33.5444444444444, -115.564722222222],
      [33.525, -115.534166666667],
      [33.5208333333333, -115.446666666667],
      [33.4833333333333, -115.334166666667],
      [33.4305555555556, -115.2425],
      [33.4041666666667, -115.284166666667],
      [33.3833333333333, -115.2425],
      [33.2333333333333, -115.375833333333],
      [33.3583333333333, -115.549444444444],
      [33.3944444444444, -115.556388888889],
      [33.475, -115.703611111111],
    ],
    // R-2507S
    [
      [33.3833333333333, -115.2425],
      [33.3611111111111, -115.200833333333],
      [33.3805555555556, -115.166944444444],
      [33.1458333333333, -114.945277777778],
      [33.0166666666667, -115.100833333333],
      [33.2333333333333, -115.375833333333],
    ],
    // R-2507W
    [
      [33.2333333333333, -115.375833333333],
      [33.2205555555556, -115.388055555556],
      [33.2327777777778, -115.407222222222],
      [33.2394444444444, -115.424722222222],
      [33.2611111111111, -115.46],
      [33.2911111111111, -115.495],
      [33.3213888888889, -115.536944444444],
      [33.3530555555556, -115.5775],
      [33.3827777777778, -115.638611111111],
      [33.4572222222222, -115.725],
      [33.4863888888889, -115.763611111111],
      [33.4933333333333, -115.76],
      [33.5191666666667, -115.686666666667],
      [33.5472222222222, -115.626944444444],
      [33.5444444444444, -115.564722222222],
      [33.475, -115.703611111111],
      [33.3944444444444, -115.556388888889],
      [33.3583333333333, -115.549444444444],
    ],
    // El Centro R-2510A
    [
      [32.9930555555556, -115.725833333333],
      [32.9255555555556, -115.671666666667],
      [32.9011111111111, -115.671666666667],
      // Thence CCW along a 4.3 NM radius circle centered at [32.8291666666667, -115.671666666667],
      [32.9007731872741, -115.678027696717],
      [32.8991723502432, -115.690607255568],
      [32.8960065198128, -115.702762722798],
      [32.8913465750937, -115.714222003275],
      [32.885296833095, -115.724728692028],
      [32.8779926986836, -115.734047839605],
      [32.8695976163521, -115.741971222377],
      [32.8602993939954, -115.748321998133],
      [32.8503059830637, -115.752958642614],
      [32.8398408115441, -115.755778080327],
      [32.8347222222222, -115.756388888889],
      [32.8347222222222, -115.9175],
      [32.9305555555556, -115.9175],
      [33.0222222222222, -116.038333333333],
      [33.1097222222222, -115.948055555556],
      [33.1097222222222, -115.854166666667],
    ],
    // R-2510B
    [
      [32.9255555555556, -115.671666666667],
      [32.9305555555556, -115.9175],
      [33.0222222222222, -116.038333333333],
      [33.1097222222222, -115.948055555556],
      [33.1097222222222, -115.854166666667],
      [32.9930555555556, -115.725833333333],
    ],
    // HoltVille R-2512
    [
      [33.0833333333333, -115.2925],
      [33, -115.225833333333],
      [32.85, -115.0925],
      [32.85, -115.284166666667],
      [32.9666666666667, -115.2925],
      [33.0833333333333, -115.334166666667],
    ],
    // Muroc Lake, CA R-2515
    [
      [35.3166666666667, -116.8175],
      [35.1666666666667, -116.8175],
      [35.1472222222222, -116.811944444444],
      [35.1083333333333, -116.978611111111],
      [34.8916666666667, -117.198055555556],
      [34.8388888888889, -117.534166666667],
      [34.8083333333333, -117.534166666667],
      [34.8, -117.584166666667],
      [34.8, -118.0175],
      [34.8277777777778, -118.096666666667],
      [35.0166666666667, -118.096666666667],
      [35.4611111111111, -117.434166666667],
      [35.2655555555556, -117.434166666667],
      [35.2655555555556, -116.923055555556],
      [35.3166666666667, -116.923055555556],
    ],
    // Trona, CA R-2524
    [
      [35.7961111111111, -116.923055555556],
      [35.2655555555556, -116.923055555556],
      [35.2655555555556, -117.434166666667],
      [35.6, -117.434166666667],
      [35.6, -117.281944444444],
      [35.7961111111111, -117.281944444444],
    ],
  ]