export const koreaNflBuffer =
[
  [37.68951, 126.83249],
  [37.79318, 126.84415],
  [38.13051, 127.25721],
  [38.16118, 127.50796],
  [38.0118, 127.62513],
  [37.98394, 127.75753],
  [38.03732, 128.42043],
  [38.14124, 128.55303],
  [38.26825, 128.58348]
]