export const michigan =
  [
    // Camp Grayling, MI R-4201A
    [
      [44.9333333333333, -84.4833333333333],
      [44.7833333333333, -84.4833333333333],
      [44.7833333333333, -84.65],
      [44.9333333333333, -84.65],
    ],
    // Camp Grayling, MI R-4201B
    [
      [44.7833333333333, -84.4833333333333],
      [44.6833333333333, -84.4833333333333],
      [44.6833333333333, -84.6666666666667],
      [44.7166666666667, -84.6666666666667],
      [44.7166666666667, -84.6333333333333],
      [44.7833333333333, -84.6333333333333],
    ],
    // Upper Lake Huron, MI R-4207
    [
      [45.2833333333333, -83],
      [45.34, -82.5216666666667],
      // Along the United States-Canadian Border
      [44.5166666666667, -82.3316666666667],
      [44.4616666666667, -82.7855555555555],
    ],
  ]