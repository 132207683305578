export const colorado =
  [
    // Fort Carson, CO R-2601A/B/C/D
    [
      [38.6386111111111, -104.867222222222],
      [38.7111111111111, -104.818333333333],
      [38.6888888888889, -104.783888888889],
      [38.6708333333333, -104.772777777778],
      [38.6666666666667, -104.761666666667],
      [38.535, -104.750555555556],
      [38.4263888888889, -104.750555555556],
      [38.4263888888889, -104.817222222222],
      [38.4361111111111, -104.817222222222],
      [38.4355555555556, -104.958888888889],
      [38.4930555555556, -104.958888888889],
      // Thence northeast along Colorado HWY 115
      [38.4968, -104.9567],
      [38.5011, -104.9567],
      [38.5045, -104.9597],
      [38.5116, -104.9629],
      [38.5139, -104.9650],
      [38.5159, -104.9658],
      [38.5184, -104.9672],
      [38.5216, -104.9669],
      [38.5263, -104.9649],
      [38.5297, -104.9665],
      [38.5314, -104.9664],
      [38.5372, -104.9628],
      [38.5420, -104.9613],
      [38.5460, -104.9570],
      [38.5506, -104.9481],
      [38.5674, -104.9424],
      [38.5853, -104.9181],
      [38.6082, -104.9001],
      [38.6221, -104.8786],
      [38.6248, -104.8759],
    ],
    // Fort Carson, CO R-2603
    [
      [37.375, -104.079722222222],
      [37.5408333333333, -104.108888888889],
      [37.5408333333333, -104.0375],
      [37.5558333333333, -103.965277777778],
      [37.5997222222222, -103.963888888889],
      [37.5991666666667, -103.911111111111],
      [37.6361111111111, -103.813055555556],
      [37.6422222222222, -103.811944444444],
      [37.6411111111111, -103.711111111111],
      [37.5461111111111, -103.712777777778],
      [37.3527777777778, -103.911388888889],
      [37.3541666666667, -104.043055555556],
    ],
  ]