export const alabama =
  [
    // Centerville ROZ
    [
      [33, -87.1507],
      [33, -87.0944444444444],
      [32.9736111111111, -87.0222222222222],
      [32.8805, -87.025398999],
      [32.8811666666667, -87.1526666666667],
    ],
    // Demopolis ROZ
    [
      [32.5916666666667, -87.9916666666667],
      [32.5416666666667, -87.8333333333333],
      [32.4849977777, -87.6973],
      [32.4972222222222, -87.8888888888889],
    ],
    // Greensboro ROZ
    [
      [32.924000233, -87.5284],
      [32.6221666666667, -87.5956666666667],
      [32.6446666666667, -87.7496666666667],
      [32.78526788, -87.7220],
    ],
    // Marion ROZ
    [
      [32.5419, -87.03692222],
      [32.6421666666667, -87.3353333333333],
      [32.47293, -87.5082],
      [32.4430555555556, -87.0402777777778],
    ],
  ]