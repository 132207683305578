export const arizona =
  [
    // Ajo East R-2301E
    [
      [32.8402777777778, -112.8175],
      [32.1916666666667, -112.946666666667],
      [32.1916666666667, -113.0925],
      [31.9666666666667, -113.0925],
      [32.0932, -113.509166666667],
      [32.7375, -113.685555555556],
      [32.7638888888889, -113.575833333333],
    ],
    // Ajo West R-2301W
    [
      [32.0932, -113.509166666667],
      [32.3958333333333, -114.475833333333],
      [32.5, -114.475833333333],
      [32.5, -114.5175],
      [32.5833333333333, -114.5175],
      [32.5833333333333, -114.475833333333],
      [32.6611111111111, -114.475833333333],
      [32.6791666666667, -114.308888888889],
      [32.7375, -113.685555555556],
    ],
    // Fort Huachuca R-2303A
    [
      [31.6777777777778, -110.183888888889],
      [31.5666666666667, -110.142222222222],
      [31.5666666666667, -110.367222222222],
      [31.55, -110.383888888889],
      [31.4833333333333, -110.383888888889],
      [31.4833333333333, -110.692222222222],
      [31.5666666666667, -110.725555555556],
      [31.6416666666667, -110.700555555556],
      [31.6416666666667, -110.658888888889],
      [31.6833333333333, -110.558888888889],
      [31.6833333333333, -110.200555555556],
    ],
    // R-2303B
    [
      [31.75, -110.333888888889],
      [31.6833333333333, -110.200555555556],
      [31.6777777777778, -110.183888888889],
      [31.5666666666667, -110.142222222222],
      [31.5666666666667, -110.367222222222],
      [31.55, -110.383888888889],
      [31.4833333333333, -110.383888888889],
      [31.4833333333333, -110.417222222222],
      [31.4, -110.417222222222],
      [31.4, -110.750555555556],
      [31.75, -110.764444444444],
    ],
    // R-2303C
    [
      [31.5833333333333, -110.000555555556],
      [31.4, -110.000555555556],
      [31.4, -110.417222222222],
      [31.4833333333333, -110.417222222222],
      [31.4833333333333, -110.383888888889],
      [31.55, -110.383888888889],
      [31.5666666666667, -110.367222222222],
      [31.5666666666667, -110.142222222222],
      [31.6777777777778, -110.183888888889],
    ],
    // Gila Bend R-2304
    [
      [32.6416666666667, -112.300833333333],
      [32.4444444444444, -112.300833333333],
      [32.4444444444444, -112.725833333333],
      [32.8166666666667, -112.650833333333],
    ],
    // Gila Bend R-2305
    [
      [32.8402777777778, -112.8175],
      [32.8477777777778, -112.715555555556],
      [32.8166666666667, -112.650833333333],
      [32.4833333333333, -112.7175],
      [32.4833333333333, -112.8925],
    ],
    // Fort Huachuca R-2312
    [
      [31.4852732981122, -110.262514112396],
      [31.4895999637208, -110.262890924052],
      [31.4938301684835, -110.264016127392],
      [31.4978693976605, -110.265864680749],
      [31.5016273917914, -110.268395366638],
      [31.5050201655838, -110.271551705707],
      [31.5079718873744, -110.275263213759],
      [31.5104165768803, -110.279446974174],
      [31.512299582976, -110.284009490805],
      [31.51357880814, -110.288848779884],
      [31.5142256518953, -110.293856653924],
      [31.5142256518953, -110.298921146075],
      [31.51357880814, -110.303929020115],
      [31.512299582976, -110.308768309194],
      [31.5104165768803, -110.313330825825],
      [31.5079718873744, -110.31751458624],
      [31.5050201655838, -110.321226094292],
      [31.5016273917914, -110.324382433361],
      [31.4978693976605, -110.32691311925],
      [31.4938301684835, -110.328761672607],
      [31.4895999637208, -110.329886875947],
      [31.4852732981122, -110.330263687603],
      [31.4809468286936, -110.329883792795],
      [31.4767171950684, -110.328755780255],
      [31.4726788612284, -110.326904941259],
      [31.4689220070794, -110.324372696381],
      [31.4655305166216, -110.321215663496],
      [31.4625801074883, -110.317504388451],
      [31.4601366433269, -110.31332176716],
      [31.4582546663859, -110.308761194556],
      [31.4569761827416, -110.303924481671],
      [31.4563297269839, -110.298919587086],
      [31.4563297269839, -110.293858212913],
      [31.4569761827416, -110.288853318328],
      [31.4582546663859, -110.284016605443],
      [31.4601366433269, -110.279456032839],
      [31.4625801074883, -110.275273411548],
      [31.4655305166216, -110.271562136503],
      [31.4689220070794, -110.268405103618],
      [31.4726788612284, -110.26587285874],
      [31.4767171950684, -110.264022019744],
      [31.4809468286936, -110.262894007204],
    ],
    // Barry M. Goldwater Range (BMGR)
    // ETAC
    [
      [32.7915, -112.601083333333],
      [32.6416666666667, -112.300833333333],
      [32.4445, -112.300833333333],
      [32.4445, -112.725833333333],
      [32.4833333333333, -112.7175],
      [32.4833333333333, -112.765466666667],
      [32.6100833333333, -112.739516666667],
    ],
    // NTAC
    [
      [32.79985, -113.223966666667],
      [32.7192333333333, -113.19105],
      [32.6873, -113.01625],
      [32.5314166666667, -113.088866666667],
      [32.6059666666667, -113.331716666667],
      [32.6256333333333, -113.4057],
      [32.7767833333333, -113.451016666667],
    ],
    // STAC
    [
      [32.5314166666667, -113.088866666667],
      [32.5064333333333, -113.100633333333],
      [32.35625, -113.0494],
      [32.3055166666667, -112.975833333333],
      [32.2694666666667, -112.988083333333],
      [32.2396333333333, -112.9377],
      [32.1916666666667, -112.946666666667],
      [32.1916666666667, -113.0925],
      [32.3000333333333, -113.250766666667],
      [32.4583833333333, -113.355733333333],
      [32.6256333333333, -113.4057],
      [32.6059666666667, -113.331716666667],
    ],
    // EAST CORRIDOR
    [
      [31.8926, -111.567683333333],
      [31.8166666666667, -111.534166666667],
      [31.725, -111.592216666667],
      [31.5166666666667, -111.642216666667],
      [31.5343, -111.697866666667],
      [31.8833, -111.61965],
    ],
    // RANGE 1
    [
      [32.5302833333333, -112.756533333333],
      [32.4833333333333, -112.765466666667],
      [32.4833333333333, -112.8925],
      [32.4252333333333, -112.903316666667],
      [32.4245166666667, -113.004266666667],
      [32.5051833333333, -113.03675],
      [32.5898833333333, -112.996466666667],
    ],
    // RANGE 2
    [
      [32.7490833333333, -112.8887],
      [32.62945, -112.725016666667],
      [32.6100833333333, -112.739516666667],
      [32.5302833333333, -112.756533333333],
      [32.5898833333333, -112.996466666667],
      [32.73045, -112.929566666667],
    ],
    // RANGE 3
    [
      [32.7915, -112.601083333333],
      [32.62945, -112.725016666667],
      [32.7490833333333, -112.8887],
      [32.8145666666667, -112.749916666667],
      [32.8166666666667, -112.650833333333],
    ],
    // RANGE 4
    [
      [32.8247, -112.976216666667],
      [32.6873, -113.01625],
      [32.7192333333333, -113.19105],
      [32.79985, -113.223966666667],
    ],
  ]