export const newMexico =
  [
    // McGregor, NM R-5103A
    [
      [32.0652777777778, -106.166666666667],
      [32.0583333333333, -105.897222222222],
      [32.0041666666667, -105.945],
      [32.0083333333333, -106.174166666667],
    ],
    // R-5103B
    [
      [32.25, -106.167222222222],
      [32.25, -105.700555555556],
      [32.0583333333333, -105.897222222222],
      [32.0652777777778, -106.166666666667],
      [32.0838888888889, -106.156111111111],
      [32.1, -106.258888888889],
    ],
    // R-5103C
    [
      [32.75, -105.883888888889],
      [32.75, -105.872777777778],
      [32.5555555555556, -105.500555555556],
      [32.4388888888889, -105.500555555556],
      [32.25, -105.700555555556],
      [32.25, -106.167222222222],
      // Then along the Southern Pacific Railroad
      [32.3658, -106.0903],
      [32.4061, -106.0473],
      [32.4419, -106.0330],
      [32.4659, -106.0291],
      [32.4611111111111, -106.000555555556],
      [32.6, -106],
      [32.75, -105.983888888889],
    ],
    // White Sands Missile Range, NM R-5107A
    [
      [32.325, -106.567222222222],
      [32.325, -106.396944444444],
      [32.2425, -106.396111111111],
      [32.2047222222222, -106.404722222222],
      [32.1475, -106.500277777778],
      [32.1444444444444, -106.567222222222],
    ],
    // R-5107B
    [
      [33.7458333333333, -106.067222222222],
      [32.8333333333333, -106.067222222222],
      [32.6, -106.100555555556],
      [32.4166666666667, -106.100555555556],
      [32.3883333333333, -106.118055555556],
      [32.4133333333333, -106.150555555556],
      [32.325, -106.343888888889],
      [32.325, -106.658888888889],
      [33.2166666666667, -106.867222222222],
      [33.8291666666667, -106.756111111111],
      [33.825, -106.275555555556],
      // Thence along the south side of US HWY 380
      [33.825, -106.2720],
      [33.82, -106.2671],
      [33.8183, -106.2648],
      [33.8165, -106.2606],
      [33.8106, -106.2378],
      [33.8092, -106.2299],
      [33.8028, -106.2105],
      [33.7999, -106.2030],
      [33.7992, -106.2017],
      [33.7976, -106.1992],
      [33.7972, -106.1982],
      [33.7971, -106.1969],
      [33.7972, -106.1957],
      [33.7977, -106.1924],
      [33.7982, -106.1913],
      [33.7995, -106.1892],
      [33.7999, -106.1879],
      [33.8000, -106.1872],
      [33.7999, -106.1830],
      [33.7995, -106.1817],
      [33.7934, -106.1722],
      [33.7737, -106.1476],
      [33.7651, -106.1336],
      [33.7647, -106.1324],
      [33.7645, -106.1313],
      [33.7645, -106.1307],
      [33.7647, -106.1263],
      [33.7644, -106.1251],
      [33.7641, -106.1241],
      [33.7638, -106.1236],
      [33.7599, -106.1184],
      [33.7594, -106.1174],
      [33.7589, -106.1161],
      [33.7497, -106.0839],
      [33.7494, -106.0823],
      [33.7491, -106.0806],
      [33.7491, -106.0798],
      [33.7487, -106.067222222222],
    ],
    // R-5107C
    [
      [34.2833333333333, -106.067222222222],
      [33.7458333333333, -106.067222222222],
      // Thence along the south side of US HWY 380
      [33.7487, -106.067222222222],
      [33.7491, -106.0798],
      [33.7491, -106.0806],
      [33.7494, -106.0823],
      [33.7497, -106.0839],
      [33.7589, -106.1161],
      [33.7594, -106.1174],
      [33.7599, -106.1184],
      [33.7638, -106.1236],
      [33.7641, -106.1241],
      [33.7644, -106.1251],
      [33.7647, -106.1263],
      [33.7645, -106.1307],
      [33.7645, -106.1313],
      [33.7647, -106.1324],
      [33.7651, -106.1336],
      [33.7737, -106.1476],
      [33.7934, -106.1722],
      [33.7995, -106.1817],
      [33.7999, -106.1830],
      [33.8000, -106.1872],
      [33.7999, -106.1879],
      [33.7995, -106.1892],
      [33.7982, -106.1913],
      [33.7977, -106.1924],
      [33.7972, -106.1957],
      [33.7971, -106.1969],
      [33.7972, -106.1982],
      [33.7976, -106.1992],
      [33.7992, -106.2017],
      [33.7999, -106.2030],
      [33.8028, -106.2105],
      [33.8092, -106.2299],
      [33.8106, -106.2378],
      [33.8165, -106.2606],
      [33.8183, -106.2648],
      [33.82, -106.2671],
      [33.825, -106.2720],
      [33.825, -106.275555555556],
      [33.8291666666667, -106.756111111111],
      [34.2625, -106.675555555556],
      [34.2833333333333, -106.200555555556],
    ],
    // R-5107D
    [
      [33.5666666666667, -106.067222222222],
      [33.0666666666667, -106.350555555556],
      [32.5666666666667, -106.250555555556],
      [32.5666666666667, -106.100555555556],
      [32.6, -106.100555555556],
      [32.8333333333333, -106.067222222222],
    ],
    // R-5107E
    [
      [33.9530, -106.7331],
      [33.9, -106.775555555556],
      [33.5458333333333, -106.979722222222],
      [33.4472222222222, -107.000555555556],
      [33.5833333333333, -106.800555555556],
    ],
    // R-5107F
    [
      [33.1694444444444, -107.1825],
      [33.3416666666667, -107.139444444444],
      [33.2694444444444, -106.861666666667],
      [33.0916666666667, -106.067222222222],
      [33, -105.450555555556],
      [32.75, -105.450555555556],
      [32.75, -105.817222222222],
      [32.8416666666667, -106.067222222222],
      [33.0833333333333, -106.839444444444],
    ],
    // R-5107G
    [
      [33.1944444444444, -107.174166666667],
      [33.35, -107.133888888889],
      [33.3819444444444, -107.097777777778],
      [33.4222222222222, -105.450555555556],
      [33.2333333333333, -105.450555555556],
    ],
    // R-5107H
    [
      [34.2833333333333, -106.067222222222],
      [33.875, -106.067222222222],
      [33.875, -106.42],
      [33.826252, -106.42],
      [33.8291666666667, -106.756111111111],
      [34.2625, -106.675555555556],
      [34.2833333333333, -106.200555555556],
    ],
    // R-5107J
    [
      [33.875, -106.067222222222],
      [33.7458333333333, -106.067222222222],
      // Thence along the south side of US HWY 380
      [33.7487, -106.067222222222],
      [33.7491, -106.0798],
      [33.7491, -106.0806],
      [33.7494, -106.0823],
      [33.7497, -106.0839],
      [33.7589, -106.1161],
      [33.7594, -106.1174],
      [33.7599, -106.1184],
      [33.7638, -106.1236],
      [33.7641, -106.1241],
      [33.7644, -106.1251],
      [33.7647, -106.1263],
      [33.7645, -106.1307],
      [33.7645, -106.1313],
      [33.7647, -106.1324],
      [33.7651, -106.1336],
      [33.7737, -106.1476],
      [33.7934, -106.1722],
      [33.7995, -106.1817],
      [33.7999, -106.1830],
      [33.8000, -106.1872],
      [33.7999, -106.1879],
      [33.7995, -106.1892],
      [33.7982, -106.1913],
      [33.7977, -106.1924],
      [33.7972, -106.1957],
      [33.7971, -106.1969],
      [33.7972, -106.1982],
      [33.7976, -106.1992],
      [33.7992, -106.2017],
      [33.7999, -106.2030],
      [33.8028, -106.2105],
      [33.8092, -106.2299],
      [33.8106, -106.2378],
      [33.8165, -106.2606],
      [33.8183, -106.2648],
      [33.82, -106.2671],
      [33.825, -106.2720],
      [33.825, -106.275555555556],
      [33.826252, -106.42],
      [33.875, -106.42],
    ],
    // R-5107K
    [
      [32.325, -106.396944444444],
      [32.325, -106.343888888889],
      [32.4133333333333, -106.150555555556],
      [32.3883333333333, -106.118055555556],
      [32.0833333333333, -106.306111111111],
      [32.0833333333333, -106.483888888889],
      [32.1055555555556, -106.567222222222],
      [32.1444444444444, -106.567222222222],
      [32.1475, -106.500277777778],
      [32.2047222222222, -106.404722222222],
      [32.2425, -106.396111111111],
    ],
    // White Sands Missile Range, NM R-5109A
    [
      [33.525, -105.450555555556],
      [32.75, -105.450555555556],
      [32.75, -105.983888888889],
      [32.6, -106.000555555556],
      [32.6, -106.100555555556],
      [32.8333333333333, -106.067222222222],
      [33.7361111111111, -106.067222222222],
    ],
    // R-5109B
    [
      [34.2833333333333, -106.067222222222],
      [34.2833333333333, -105.850555555556],
      [33.95, -105.450555555556],
      [33.525, -105.450555555556],
      [33.7361111111111, -106.067222222222],
    ],
    // Elephant Butte, NM R-5111A
    [
      [32.7166666666667, -106.750555555556],
      [32.7625, -106.950555555556],
      [32.8666666666667, -107.000555555556],
      [33.4472222222222, -107.000555555556],
      [33.5833333333333, -106.800555555556],
      [33.2166666666667, -106.867222222222],
    ],
    // R-5111B
    [
      [32.7166666666667, -106.750555555556],
      [32.7625, -106.950555555556],
      [32.8666666666667, -107.000555555556],
      [33.4472222222222, -107.000555555556],
      [33.5833333333333, -106.800555555556],
      [33.2166666666667, -106.867222222222],
    ],
    // R-5111C/D
    [
      [32.7625, -106.950555555556],
      [32.7833333333333, -107.100555555556],
      [33, -107.217222222222],
      [33.35, -107.133888888889],
      [33.4472222222222, -107.000555555556],
      [32.8666666666667, -107.000555555556],
    ],
  ]