export const florida =
  [
    // Avon East / East High
    [
      [27.7461111111111, -81.1941666666667],
      [27.7461111111111, -81.1413888888889],
      [27.5669444444444, -81.0747222222222],
      [27.5419444444444, -81.1230555555556],
      [27.5836111111111, -81.1497222222222],
    ],
    // Basinger
    [
      [27.5419444444444, -81.1230555555556],
      [27.5669444444444, -81.0747222222222],
      [27.3877777777778, -80.9497222222222],
      [27.3502777777778, -80.9997222222222],
      [27.4919444444444, -81.0908333333333],
    ],
    // Eglin A East
    [
      [30.9669444444444, -86.8166666666667],
      [30.9502777777778, -86.675],
      [30.7136111111111, -86.6833333333333],
      [30.7127777777778, -86.7625],
      [30.70375, -86.7833333333333],
      [30.7044444444444, -86.7833333333333],
      [30.8891666666667, -86.7833333333333],
      [30.9030555555556, -86.8875],
    ],
    // Eglin A West
    [
      [30.9030555555556, -86.8875],
      [30.8891666666667, -86.7833333333333],
      [30.70375, -86.7833333333333],
      [30.6944, -86.8049],
      [30.6749, -86.8564],
      [30.6731, -86.8600],
      [30.6592, -86.8806],
      [30.6569, -86.8841],
      [30.6552, -86.8875],
      [30.6540, -86.8907],
      [30.6533, -86.8929],
      [30.6498, -86.9082],
      [30.6477777777778, -86.9166666666667],
      [30.8752777777778, -86.9166666666667],
    ],
    // Eglin B
    [
      [30.9669444444444, -86.675],
      [30.9669444444444, -86.4166666666667],
      [30.8836111111111, -86.4166666666667],
      [30.7252777777778, -86.3333333333333],
      [30.7216666666667, -86.4602777777778],
      [30.7141666666667, -86.6338888888889],
      [30.7136111111111, -86.6833333333333],
    ],
    // Eglin C
    [
      [30.9669444444444, -86.4166666666667],
      [30.9669444444444, -86.2166666666667],
      [30.7294444444444, -86.175],
      [30.7252777777778, -86.3333333333333],
      [30.8836111111111, -86.4166666666667],
    ],
    // Eglin D
    [
      [31.0502777777778, -86.4166666666667],
      [31.0502777777778, -86.1166666666667],
      [30.7502777777778, -86.1166666666667],
      [30.7294444444444, -86.175],
      [30.9669444444444, -86.2166666666667],
      [30.9669444444444, -86.4166666666667],
    ],
    // Eglin F
    [
      [30.4419444444444, -86.8583333333333],
      [30.4058333333333, -86.8],
      [30.3836111111111, -86.8583333333333],
    ],
    // Eglin E/W Corridor
    [
      [30.3294444444444, -86.3958333333333],
      [30.4169444444444, -86.3738888888889],
      [30.4169444444444, -86.4166666666667],
      [30.4169444444444, -86.6367],
      [30.3961111111111, -86.6375],
      [30.3475, -86.6472222222222],
    ],
    // Lake Placid East
    [
      [27.7136111111111, -81.6044444444444],
      [27.9169444444444, -81.4219444444444],
      [27.7002777777778, -81.4219444444444],
      [27.5425, -81.3608333333333],
      [27.5127777777778, -81.2969444444444],
      [27.4127777777778, -81.1830555555556],
      [27.2508333333333, -81.0816666666667],
      [27.0669444444444, -81.2830555555555],
      [27.3583333333333, -81.4666666666667],
    ],
    // Lake Placid North
    [
      [27.8919444444444, -81.8663888888889],
      [28.0002777777778, -81.3497222222222],
      [27.9169444444444, -81.4219444444444],
      [27.7136111111111, -81.6044444444444],
      [27.5955555555556, -81.7038888888889],
    ],
    // Lake Placid West
    [
      [27.5955555555556, -81.7038888888889],
      [27.7136111111111, -81.6044444444444],
      [27.3583333333333, -81.4666666666667],
      [27.0669444444444, -81.2830555555555],
      [27.0669444444444, -81.4163888888889],
    ],
    // Live Oak
    [
      [30.3836111111111, -82.6663888888889],
      [30.2336111111111, -82.6497222222222],
      [29.8002777777778, -82.6719444444444],
      [29.8002777777778, -83.0997222222222],
      [30.3836111111111, -83.55],
    ],
    // Marian
    [
      [27.7461111111111, -81.1413888888889],
      [27.7447222222222, -81.0663888888889],
      [27.6891666666667, -80.8997222222222],
      [27.5002777777778, -80.8052777777778],
      [27.3877777777778, -80.9497222222222],
      [27.5669444444444, -81.0747222222222],
    ],
    // Palatka 1
    [
      [29.3836111111111, -82.0247222222222],
      [29.3836111111111, -81.6483333333333],
      [29.3853029700548, -81.6466967430373],
      [29.3923414357362, -81.6349203644632],
      [29.3977629700854, -81.6220762444736],
      [29.4014462041816, -81.6084517802182],
      [29.4033086726624, -81.5943519577504],
      [29.4033086726624, -81.5800924822496],
      [29.4014462041816, -81.5659926597818],
      [29.3977629700854, -81.5523681955264],
      [29.3923414357362, -81.5395240755368],
      [29.3853029700548, -81.5277476969628],
      [29.3808333333333, -81.5219444444444],
      [29.2086111111111, -81.4997222222222],
      [29, -81.5],
      [29.0002777777778, -81.7080555555556],
      [29.0541666666667, -81.7805555555556],
      [29.0578784572479, -81.7847035916693],
      [29.0676361515746, -81.7935718437652],
      [29.0784456894542, -81.8006873089202],
      [29.0900658889553, -81.8058906613103],
      [29.1022373985547, -81.8090651106741],
      [29.1146884706194, -81.8101390603273],
      [29.1271410189324, -81.8090877671549],
      [29.1327777777778, -81.8080555555556],
      [29.3669444444444, -82.0497222222222],
    ],
    // Palatka 2
    [
      [29.6058333333333, -81.8552777777778],
      [29.6058333333333, -81.5386111111111],
      [29.4919444444444, -81.5330555555555],
      [29.3808333333333, -81.5219444444444],
      [29.3853029700548, -81.5277476969628],
      [29.3923414357362, -81.5395240755368],
      [29.3977629700854, -81.5523681955264],
      [29.4014462041816, -81.5659926597818],
      [29.4033086726624, -81.5800924822496],
      [29.4033086726624, -81.5943519577504],
      [29.4014462041816, -81.6084517802182],
      [29.3977629700854, -81.6220762444736],
      [29.3923414357362, -81.6349203644632],
      [29.3853029700548, -81.6466967430373],
      [29.3836111111111, -81.6483333333333],
      [29.3836111111111, -82.0247222222222],
    ],
    // Tyndall B
    [
      [30.6725, -85.9],
      [30.7225, -85.6277777777778],
      [30.7225, -85.5833333333333],
      [30.2866666666667, -85.3880555555556],
      [30.3391666666667, -85.4666666666667],
      [30.3502777777778, -85.6916666666667],
    ],
    // Tyndall C
    [
      [30.7225, -85.5833333333333],
      [30.7169444444444, -85.2166666666667],
      [30.4391666666667, -84.9166666666667],
      [30.2419444444444, -85.3611111111111],
      [30.2866666666667, -85.3880555555556],
    ],
    // Tyndall D
    [
      [30.2419444444444, -85.3611111111111],
      [30.4391666666667, -84.9166666666667],
      [30.2461111111111, -84.7361111111111],
      [30.1169444444444, -85.3125],
    ],
    // Tyndall E
    [
      [30.1169444444444, -85.3125],
      [30.2461111111111, -84.7361111111111],
      [30.0002777777778, -84.5333333333333],
      [29.8711111111111, -84.5583333333333],
      [29.8752777777778, -84.5833333333333],
      [29.6669444444444, -84.975],
      [29.8113888888889, -85.1972222222222],
    ],
    // Tyndall F
    [
      [29.9591666666667, -85.2527777777778],
      [29.8113888888889, -85.1972222222222],
      [29.6669444444444, -84.975],
      [29.5558333333333, -84.9675],
      [29.5411666666667, -85.0531666666667],
      [29.5443333333333, -85.0861666666667],
      [29.5698333333333, -85.1136666666667],
      [29.581, -85.1283333333333],
      [29.5921666666667, -85.1703333333333],
      [29.624, -85.218],
      [29.6255, -85.2326666666667],
      [29.6383333333333, -85.2986666666667],
      [29.616, -85.326],
      [29.6143333333333, -85.381],
      [29.6748333333333, -85.434],
      [29.8016666666667, -85.4719444444444],
    ],
    // Tyndall G
    [
      [29.5558333333333, -84.9675],
      [29.6669444444444, -84.975],
      [29.8752777777778, -84.5833333333333],
      [29.8711111111111, -84.5583333333333],
      [30.0002777777778, -84.5333333333333],
      [29.8502777777778, -84.4083333333333],
      [29.7452777777778, -84.5783333333333],
      [29.7416666666667, -84.6013888888889],
      [29.7048333333333, -84.6838333333333],
      [29.6583333333333, -84.7346666666667],
      [29.6213333333333, -84.8348333333333],
      [29.5711666666667, -84.931],
    ],
  ]