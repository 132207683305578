export const virginia =
  [
    // Fort Pickett, VA R-6602A
    [
      [37.0938888888889, -77.8647222222222],
      [37.0738888888889, -77.8622222222222],
      // Along State HWY 40
      [37.0655555555556, -77.8511111111111],
      [37.0455555555556, -77.8436111111111],
      [37.0183333333333, -77.845],
      [36.9975, -77.8425],
      [36.9663888888889, -77.8702777777778],
      [36.9652777777778, -77.8883333333333],
      [36.9702777777778, -77.9613888888889],
      [37.0308333333333, -77.9775],
      [37.0308333333333, -77.9325],
      [37.0727777777778, -77.9325],
      [37.0938888888889, -77.9113888888889],
    ],
    // Fort Pickett, VA R-6602B
    [
      [37.0938888888889, -77.8647222222222],
      [37.0738888888889, -77.8622222222222],
      // Along State HWY 40
      [37.0655555555556, -77.8511111111111],
      [37.0455555555556, -77.8436111111111],
      [37.0183333333333, -77.845],
      [36.9652777777778, -77.8883333333333],
      [36.9702777777778, -77.9613888888889],
      [37.0308333333333, -77.9775],
      [37.0308333333333, -77.9325],
      [37.0727777777778, -77.9325],
      [37.0938888888889, -77.9113888888889],
    ],
    // Fort Pickett, VA R-6602C
    [
      [37.0938888888889, -77.8647222222222],
      [37.0738888888889, -77.8622222222222],
      // Along State HWY 40
      [37.0655555555556, -77.8511111111111],
      [37.0455555555556, -77.8436111111111],
      [37.0183333333333, -77.845],
      [36.9652777777778, -77.8883333333333],
      [36.9702777777778, -77.9613888888889],
      [37.0308333333333, -77.9775],
      [37.0308333333333, -77.9325],
      [37.0727777777778, -77.9325],
      [37.0938888888889, -77.9113888888889],
    ],
    // Chincoteague Inlet, VA R-6604A
    [
      [37.9236111111111, -75.415],
      [37.8586111111111, -75.2877777777778],
      // Then along a line 3NM from and parallel to the shoreline
      [37.8235, -75.322],
      [37.8131666666667, -75.3475],
      [37.8098333333333, -75.3708333333333],
      [37.8093333333333, -75.3851666666667],
      [37.8201666666667, -75.4168333333333],
      [37.7073333333333, -75.5088333333333],
      [37.6555555555556, -75.5219444444444],
      [37.7833333333333, -75.5216666666667],
      [37.85, -75.4933333333333],
    ],
    // R-6604B
    [
      [37.9458333333333, -75.4580555555556],
      [37.9236111111111, -75.415],
      [37.85, -75.4933333333333],
      [37.7833333333333, -75.5216666666667],
      [37.84, -75.5219444444444],
    ],
    // R-6604C
    [
      [37.9491666666667, -75.4769444444444],
      [37.9483333333333, -75.4488888888889],
      [37.9397222222222, -75.4461111111111],
      [37.9458333333333, -75.4580555555556],
      [37.9208333333333, -75.4730555555556],
      [37.9208333333333, -75.4775],
      [37.9422222222222, -75.4883333333333],
    ],
    // R-6604D
    [
      [38.0283333333333, -75.4911111111111],
      [38.12, -75.2466666666667],
      [38.0766666666667, -75.1352777777778],
      // Then along a line 3NM from and parallel to the shoreline
      [37.8586111111111, -75.2877777777778],
      [37.9458333333333, -75.4580555555556],
      [37.8986111111111, -75.4863888888889],
      [37.9277777777778, -75.5575],
    ],
    // R-6604E
    [
      [37.9277777777778, -75.5575],
      [37.8986111111111, -75.4863888888889],
      [37.84, -75.5219444444444],
      [37.6555555555556, -75.5219444444444],
      [37.6491666666667, -75.5252777777778],
      [37.7819444444444, -75.6536111111111],
    ],
  ]