export const michigan =
  [
    // Big Bear, MI
    [
      [46.6166666666667, -89.6669444444445],
      [46.9416666666667, -88.85],
      [46.8666666666667, -88.5333333333333],
      [46.0833333333333, -88.3],
      [46.0833333333333, -88.6333333333333],
      [46.35, -89.3669444444444],
    ],
    // Ontonagon, MI
    [
      [46.9166666666667, -90.0002777777778],
      [46.9166666666667, -89.4669444444445],
      [47.5, -89.4666666666667],
      [46.9416666666667, -88.85],
      [46.6166666666667, -89.6669444444445],
    ],
    // Pike East, MI
    [
      [44.2219444444444, -83.25],
      [44.7, -83.15],
      [44.9875, -83.25],
      [45.2666666666667, -83.3833333333333],
      [45.3666666666667, -83.4833333333333],
      [45.3666666666667, -83.5833333333333],
      [45.75, -83.8083333333333],
      [45.75, -83.4352777777778],
      [45.3386111111111, -82.5186111111111],
      [43.8875, -82.1883333333333],
    ],
    // Pike West, MI
    [
      [45.75, -84.1666666666667],
      [44.6833333333333, -84.1],
      [44.2888888888889, -83.7166666666667],
      [44.2833333333333, -83.45],
      [44.2219444444444, -83.25],
      [44.7, -83.15],
      [44.9875, -83.25],
      [45.2666666666667, -83.3833333333333],
      [45.3666666666667, -83.4833333333333],
      [45.3666666666667, -83.5833333333333],
      [45.75, -83.8083333333333],
    ],
    // Steelhead, MI
    [
      [43.4333333333333, -82.7333333333333],
      [43.6416666666667, -83.5333333333333],
      [44.2888888888889, -83.7166666666667],
      [44.2833333333333, -83.45],
      [43.8875, -82.1883333333333],
      [43.5911111111111, -82.1227777777778],
      [43.565, -82.1361111111111],
    ],
  ]