export const alaska =
  [
    // Birch
    [
      [64.5213888888889, -146.158611111111],
      [64.4152777777778, -145.701944444444],
      [64.2141666666667, -146.058611111111],
      [64.0916666666667, -146.275277777778],
      [64.2455555555556, -146.723055555556],
      [64.2952777777778, -147.058055555556],
    ],
    // Buffalo
    [
      [64.2077777777778, -144.836944444444],
      [63.9997222222222, -144.002222222222],
      [63.9997222222222, -143],
      [63.6166666666667, -144.216666666667],
      [63.6166666666667, -145.55],
      [63.5, -145.9],
      [63.7330555555556, -146.502222222222],
      [63.7038888888889, -146.226111111111],
      [63.7163888888889, -145.9025],
      [63.8413888888889, -145.835555555556],
      [63.9016666666667, -145.840833333333],
      // Counterclockwise a 7NM arc of the Big Delta VORTAC [64.0044611,-145.7172472]
      [63.8961745626364, -145.813683011055],
      [63.8910715285446, -145.775973387711],
      [63.8884915424158, -145.736967637466],
      [63.8884915424158, -145.697526762533],
      [63.8910715285446, -145.658521012288],
      [63.8961745626364, -145.620811388944],
      [63.9036879795389, -145.585231319086],
      [63.9134457876227, -145.55256882251],
      [63.9252322149768, -145.52354950848],
      [63.9333333333333, -145.507777777778],
      [64.07, -145.087777777778],
    ],
    // Delta 1
    [
      [64.7833333333333, -147.15],
      [64.6416666666667, -147.183333333333],
      [64.5761111111111, -146.983333333333],
      [64.5563888888889, -146.8025],
      [64.5563888888889, -146.769166666667],
      [64.5563888888889, -146.310833333333],
      [64.5213888888889, -146.158611111111],
      [64.2952777777778, -147.058055555556],
      [64.3327777777778, -147.319166666667],
      [64.4994444444444, -147.735833333333],
    ],
    // Delta 2
    [
      [64.5213888888889, -146.158611111111],
      [64.4152777777778, -145.701944444444],
      [64.2141666666667, -146.058611111111],
      [64.0916666666667, -146.275277777778],
      [64.2455555555556, -146.723055555556],
      [64.2952777777778, -147.058055555556],
    ],
    // Delta 3
    [
      [64.4152777777778, -145.701944444444],
      [64.2077777777778, -144.836944444444],
      [64.07, -145.087777777778],
      [63.9333333333333, -145.507777777778],
      // Clockwise on a 7NM arc from the Big Delta VORTAC [64.0044611,-145.7172472]
      [63.9252322149768, -145.52354950848],
      [63.9134457876227, -145.55256882251],
      [63.9036879795389, -145.585231319086],
      [63.8961745626364, -145.620811388944],
      [63.8910715285446, -145.658521012288],
      [63.8884915424158, -145.697526762533],
      [63.8884915424158, -145.736967637466],
      [63.8910715285446, -145.775973387711],
      [63.8961745626364, -145.813683011055],
      [63.9016666666667, -145.840833333333],
      [63.9052777777778, -145.841111111111],
      [63.9377777777778, -145.827222222222],
      [64.0594444444444, -146.182777777778],
      [64.0916666666667, -146.275277777778],
    ],
    // Delta 4
    [
      [64.2077777777778, -144.836944444444],
      [63.9997222222222, -144.002222222222],
      [63.9997222222222, -143],
      [63.6166666666667, -144.216666666667],
      [63.6166666666667, -145.55],
      [63.5, -145.9],
      [63.7163888888889, -145.9025],
      [63.8413888888889, -145.835555555556],
      [63.9016666666667, -145.840833333333],
      // Counterclockwise a 7NM arc of the Big Delta VORTAC [64.0044611,-145.7172472]
      [63.8961745626364, -145.813683011055],
      [63.8910715285446, -145.775973387711],
      [63.8884915424158, -145.736967637466],
      [63.8884915424158, -145.697526762533],
      [63.8910715285446, -145.658521012288],
      [63.8961745626364, -145.620811388944],
      [63.9036879795389, -145.585231319086],
      [63.9134457876227, -145.55256882251],
      [63.9252322149768, -145.52354950848],
      [63.9333333333333, -145.507777777778],
      [64.07, -145.087777777778],
    ],
    // Delta 5
    [
      [63.6166666666667, -144.553888888889],
      [63.6166666666667, -144.216666666667],
      [63.5394444444444, -144.272777777778],
    ],
    // Eielson
    [
      [63.8469444444444, -146.793888888889],
      [63.9666666666667, -148],
      [64.3744444444444, -147.969166666667],
      [64.2244444444444, -147.535555555556],
      [64.3327777777778, -147.319166666667],
      [64.2952777777778, -147.058055555556],
      [64.2455555555556, -146.723055555556],
      // Along the east bank of the Little Delta and East Fork Rivers
      [64.1072, -146.8041],
      [64.0179, -146.8354],
      [63.9773, -146.9088],
      [63.9682, -146.9174],
      [63.9528, -146.8999],
      [63.9282, -146.8261],
      [63.9161, -146.8170],
      [63.8963, -146.8330],
      [63.8593, -146.7947],
    ],
    // Fox 1
    [
      [63.9666666666667, -148],
      [63.8469444444444, -146.793888888889],
      [63.7330555555556, -146.502222222222],
      [63.5, -145.9],
      [63.5, -148.279444444444],
    ],
    // Fox 2
    [
      [63.7330555555556, -146.502222222222],
      [63.7038888888889, -146.226111111111],
      [63.7163888888889, -145.9025],
      [63.5, -145.9],
    ],
    // Fox 3 High/Low
    [
      [63.5, -145.9],
      [63.3833333333333, -146],
      [62.4480555555556, -145.859166666667],
      [62.2263888888889, -148.847222222222],
      [62.5, -148.847222222222],
      [63.5, -148.279444444444],
    ],
    // Paxon High/Low
    [
      [63.5, -145.9],
      [63.6166666666667, -145.55],
      [63.6166666666667, -144.553888888889],
      [63.5394444444444, -144.272777777778],
      [62.5, -145],
      [62.4480555555556, -145.859166666667],
      [63.3833333333333, -146],
    ],
    // Viper A/B
    [
      [64.5563888888889, -146.769166666667],
      [64.5733333333333, -146.791388888889],
      [64.6861111111111, -146.916666666667],
      [64.8983333333333, -146.916666666667],
      [64.8166666666667, -147.066666666667],
      [64.7833333333333, -147.15],
      [64.6416666666667, -147.183333333333],
      [64.5761111111111, -146.983333333333],
      [64.5563888888889, -146.8025],
    ],
    // Yukon 1
    [
      [64.6861111111111, -146.916666666667],
      [64.8983333333333, -146.916666666667],
      [64.9997222222222, -146.728611111111],
      [64.9997222222222, -146.266666666667],
      [64.9997222222222, -146.083333333333],
      [64.9997222222222, -143.002222222222],
      [63.9997222222222, -144.002222222222],
      [64.5563888888889, -146.310833333333],
      [64.5563888888889, -146.769166666667],
      [64.5733333333333, -146.791388888889],
    ],
    // Yukon 2
    [
      [66.1663888888889, -145.085833333333],
      [66.1663888888889, -143.0025],
      [64.9997222222222, -143.002222222222],
      [64.9997222222222, -146.083333333333],
      [64.9997222222222, -146.266666666667],
      [64.9997222222222, -146.728611111111],
      [65.3830555555555, -146.0025],
    ],
    // Yukon 3A High/Low
    [
      [64.9997222222222, -143.002222222222],
      [64.9997222222222, -141.083333333333],
      [63.9997222222222, -143],
      [63.9997222222222, -144.002222222222],
    ],
    // Yukon 3B
    [
      [63.9997222222222, -143],
      [63.9997222222222, -141.083333333333],
      [64.9997222222222, -141.083333333333],
    ],
    // Yukon 4
    [
      [64.9997222222222, -143.002222222222],
      [64.9997222222222, -141.083333333333],
      [66.1663888888889, -141.083333333333],
      [66.1663888888889, -143.0025],
    ],
    // Yukon 5
    [
      [66.1663888888889, -145.085833333333],
      [66.6666666666667, -144.5],
      [66.6666666666667, -141.083333333333],
      [66.1663888888889, -141.083333333333],
      [66.1663888888889, -143.0025],
    ],
  ]