export const wisconsin =
  [
    // Fort McCoy, WI R-6901A
    [
      [44.1444444444444, -90.7388888888889],
      [44.1444444444444, -90.6727777777778],
      [44.16, -90.6727777777778],
      [44.16, -90.6138888888889],
      [44.0077777777778, -90.6113888888889],
      // Then West along Wisconsin State HWY 21
      [44.0075, -90.6458333333333],
      [44.0291666666667, -90.7419444444444],
    ],
    // R-6901B
    [
      [43.9994444444444, -90.7194444444444],
      // Then East along Wisconsin State HWY 21
      [44.0059, -90.7126],
      [44.0066, -90.7115],
      [44.0074, -90.7076],
      [44.0075, -90.6458333333333],
      [44.0077777777778, -90.6113888888889],
      [44.0027777777778, -90.6113888888889],
      [44.0005555555556, -90.6097222222222],
      [44.0005555555556, -90.5875],
      [43.9394444444444, -90.5894444444444],
      [43.9394444444444, -90.65],
      [43.9438888888889, -90.6833333333333],
      [43.9455555555556, -90.7213888888889],
    ],
    // Volk Field, WI R-6904A
    [
      [44.3, -89.9833333333333],
      [44.1666666666667, -89.9833333333333],
      [44.1666666666667, -90.1833333333333],
      [44.3, -90.1833333333333],
    ],
    // R-6904B
    [
      [44.25, -89.9833333333333],
      [44.2166666666667, -89.9833333333333],
      [44.2166666666667, -90.1166666666667],
      [44.25, -90.1166666666667],
    ],
  ]