export const maryland =
  [
    // Aberdeen, MD R-4001A
    [
      [39.5083333333333, -76.1663888888889],
      [39.4833333333333, -76.1330555555555],
      [39.4916666666667, -76.0830555555555],
      [39.45, -76.0080555555556],
      [39.3297222222222, -76.1925],
      [39.2916666666667, -76.2161111111111],
      [39.2733333333333, -76.2713888888889],
      [39.2869444444444, -76.3133333333333],
      [39.3116666666667, -76.3133333333333],
      [39.3341666666667, -76.3416666666667],
      [39.3322222222222, -76.3505555555555],
      [39.3441666666667, -76.3663888888889],
      [39.3666666666667, -76.3663888888889],
      [39.3911111111111, -76.3441666666667],
      [39.4361111111111, -76.2469444444445],
      [39.45, -76.2080555555556],
    ],
    // Aberdeen, MD R-4001B
    [
      [39.2916666666667, -76.2161111111111],
      [39.2027777777778, -76.2747222222222],
      [39.2125, -76.3747222222222],
      [39.2916666666667, -76.3288888888889],
      [39.3083333333333, -76.3663888888889],
      [39.3441666666667, -76.3663888888889],
      [39.3180555555556, -76.3505555555555],
      [39.3341666666667, -76.3416666666667],
      [39.3116666666667, -76.3133333333333],
      [39.2869444444444, -76.3133333333333],
      [39.2733333333333, -76.2713888888889],
    ],
    // Aberdeen, MD R-4001C
    [
      [39.3638888888889, -76.3663888888889],
      [39.3836111111111, -76.2763888888889],
      [39.3511111111111, -76.2644444444445],
      [39.3322222222222, -76.3505555555555],
      [39.3441666666667, -76.3663888888889],
    ],
    // Patuxent River, MD R-4006
    [
      [38.6875, -75.7663888888889],
      [38.5416666666667, -75.7288888888889],
      [38.3166666666667, -75.6163888888889],
      [37.9166666666667, -75.8747222222222],
      [37.75, -75.9788888888889],
      [37.75, -76.3913888888889],
      [37.8416666666667, -76.5330555555555],
      [38.0861111111111, -76.5705555555556],
      [38.1861111111111, -76.4191666666667],
      [38.5, -76.0663888888889],
      [38.6, -75.9247222222222],
      // Along the Pennsylvania Railroad to the point of beginning
    ],
  ]