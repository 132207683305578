export const arkansas =
  [
    // Fort Chaffee, AR R-2402A
    [
      [35.2969444444444, -94.0502777777778],
      [35.2833333333333, -94.0502777777778],
      [35.2833333333333, -94.0169444444444],
      [35.1680555555556, -94.0169444444444],
      // West along AR HWY 10
      [35.1678, -94.0189],
      [35.1702, -94.0243],
      [35.1716, -94.0255],
      [35.1744, -94.0317],
      [35.1763, -94.0467],
      [35.1744, -94.0557],
      [35.1736, -94.0717],
      [35.1749, -94.0761],
      [35.1767, -94.0792],
      [35.1806, -94.1079],
      [35.1808, -94.1123],
      [35.1817, -94.1185],
      [35.1824, -94.1259],
      [35.1825, -94.1315],
      [35.1808, -94.1400],
      [35.1801, -94.1452],
      [35.1785, -94.1541],
      [35.1783, -94.1611],
      [35.1795, -94.1676],
      [35.1795, -94.1716],
      [35.1804, -94.1746],
      [35.1808, -94.1752],
      [35.1871, -94.1785],
      [35.1880, -94.1802],
      [35.1881, -94.1866],
      [35.1899, -94.1930],
      [35.1930555555556, -94.2002777777778],
      [35.2305555555556, -94.2002777777778],
      [35.3027777777778, -94.2002777777778],
      [35.3027777777778, -94.165],
      // East along AR HWY 22
      [35.3028, -94.1650],
      [35.3014, -94.1612],
      [35.2996, -94.1577],
      [35.2990, -94.1561],
      [35.2943, -94.1401],
      [35.2930, -94.1350],
      [35.2928, -94.1308],
      [35.2913, -94.1210],
      [35.2926, -94.1107],
      [35.2919, -94.1069],
      [35.2904, -94.1043],
      [35.2898, -94.1025],
      [35.2898, -94.1014],
      [35.2938, -94.0882],
      [35.2958, -94.0786],
      [35.2958, -94.0656],
      [35.2952, -94.0616],
      [35.2952, -94.0608],
      [35.2971, -94.0523],
      [35.2972, -94.0502777777778],
    ],
    // Fort Chaffee, AR R-2402B
    [
      [35.3061111111111, -93.9272222222222],
      // CW along a 7NM circle centered at [35.2572222222222,-94.0566666666667]
      [35.2915854862675, -93.9204726328071],
      [35.2745535756103, -93.915762227872],
      [35.2571361450801, -93.9142012274324],
      [35.2571361450801, -93.9142012274324],
      [35.2397223897443, -93.9158224947776],
      [35.2227011781187, -93.9205878117006],
      [35.2064523770943, -93.9283889098076],
      [35.191338398553, -93.9390500499006],
      [35.177696153578, -93.9523320776808],
      [35.1658295895206, -93.9679378538482],
      [35.1560029710273, -93.9855189297453],
      [35.1484350488711, -94.0046833162611],
      [35.1432942405082, -94.025004173924],
      [35.1406949241386, -94.0460292360025],
      [35.1406949241386, -94.0672907639974],
      [35.1432942405082, -94.088315826076],
      [35.1484350488711, -94.1086366837388],
      [35.1560029710273, -94.1278010702546],
      [35.1658295895206, -94.1453821461517],
      [35.177696153578, -94.1609879223191],
      [35.1783333333333, -94.1619444444444],
      // East along AR HWY 10
      [35.1783, -94.1611],
      [35.1785, -94.1541],
      [35.1801, -94.1452],
      [35.1808, -94.1400],
      [35.1825, -94.1315],
      [35.1824, -94.1259],
      [35.1817, -94.1185],
      [35.1808, -94.1123],
      [35.1806, -94.1079],
      [35.1767, -94.0792],
      [35.1749, -94.0761],
      [35.1736, -94.0717],
      [35.1744, -94.0557],
      [35.1763, -94.0467],
      [35.1744, -94.0317],
      [35.1716, -94.0255],
      [35.1702, -94.0243],
      [35.1678, -94.0189],
      [35.1680555555556, -94.0169444444444],
      [35.2833333333333, -94.0169444444444],
      [35.2833333333333, -94.0502777777778],
      [35.2969444444444, -94.0502777777778],
      // East along AR HWY 22
      [35.2972, -94.0502777777778],
      [35.2962, -93.9897],
      [35.2978, -93.9841],
      [35.2993, -93.9814],
      [35.2995, -93.9800],
      [35.2996, -93.9761],
      [35.3002, -93.9732],
      [35.3019, -93.9706],
      [35.3055, -93.9622],
      [35.3056, -93.9612],
      [35.3055, -93.9513],
      [35.3063, -93.9458],
    ],
    // Fort Chaffee, AR R-2402C
    [
      [35.3633333333333, -94.1163888888889],
      // CW along a 7NM circle centered at  [35.2572222222222,-94.0566666666667]
      [35.3659806069316, -94.108775755738],
      [35.371135306199, -94.0884045404825],
      [35.3737418909393, -94.0673212380617],
      [35.3737418909393, -94.0459987619382],
      [35.371135306199, -94.0249154595174],
      [35.3659806069316, -94.0045442442619],
      [35.3583934056213, -93.9853418574194],
      [35.3485438327796, -93.9677385149433],
      [35.336652678786, -93.9521281843807],
      [35.3229863932137, -93.9388597190036],
      [35.3078510595186, -93.9282290529749],
      [35.3061111111111, -93.9272222222222],
      // West along AR HWY 22
      [35.3063, -93.9458],
      [35.3055, -93.9513],
      [35.3056, -93.9612],
      [35.3055, -93.9622],
      [35.3019, -93.9706],
      [35.3002, -93.9732],
      [35.2996, -93.9761],
      [35.2995, -93.9800],
      [35.2993, -93.9814],
      [35.2978, -93.9841],
      [35.2962, -93.9897],
      [35.2972, -94.0502777777778],
      [35.2971, -94.0523],
      [35.2952, -94.0608],
      [35.2952, -94.0616],
      [35.2958, -94.0656],
      [35.2958, -94.0786],
      [35.2938, -94.0882],
      [35.2898, -94.1014],
      [35.2898, -94.1025],
      [35.2904, -94.1043],
      [35.2919, -94.1069],
      [35.2926, -94.1107],
      [35.2913, -94.1210],
      [35.2928, -94.1308],
      [35.2930, -94.1350],
      [35.2943, -94.1401],
      [35.2990, -94.1561],
      [35.2996, -94.1577],
      [35.3014, -94.1612],
      [35.3028, -94.1650],
      [35.3027777777778, -94.165],
    ],
  ]