export const newYork =
  [
    // Fort Drum, NY R-5201
    [
      [44.0180555555556, -75.6205555555556],
      [44.0555555555556, -75.6802777777778],
      [44.1152777777778, -75.7025],
      [44.1805555555556, -75.6497222222222],
      [44.2686111111111, -75.5447222222222],
      [44.19, -75.3830555555555],
      [44.1194444444444, -75.4469444444445],
    ],
    // Fort Drum, NY R-5202A
    [
      [44.0180555555556, -75.6205555555556],
      [44.0555555555556, -75.6802777777778],
      [44.1152777777778, -75.7025],
      [44.1805555555556, -75.6497222222222],
      [44.2686111111111, -75.5447222222222],
      [44.19, -75.3830555555555],
      [44.1194444444444, -75.4469444444445],
    ],
    // Fort Drum, NY R-5202B
    [
      [44.1716666666667, -75.6883333333333],
      [44.3422222222222, -75.5344444444444],
      [44.2333333333333, -75.2833333333333],
      [44.1, -75.4194444444445],
      [44.1, -75.4802777777778],
      [44.1194444444444, -75.4469444444445],
      [44.19, -75.3830555555555],
      [44.2686111111111, -75.5447222222222],
      [44.1805555555556, -75.6497222222222],
      [44.1594444444444, -75.6666666666667],
    ],
  ]