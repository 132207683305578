export const oregon =
  [
    // Paradise North, OR
    [
      [42.75, -117],
      [42, -117],
      [42, -117.743888888889],
      [42.4166666666667, -117.7],
      [42.75, -117.15],
    ],
    // Saddle A, OR
    [
      [43.5, -117.584444444444],
      [43.5, -117.234444444444],
      [43.1833333333333, -117.134444444444],
      [42.875, -117.584444444444],
    ],
    // Saddle B, OR
    [
      [43.5, -117.584444444444],
      [42.875, -117.584444444444],
      [42.8333333333333, -117.634444444444],
      [42.7833333333333, -117.851111111111],
      [43.3583333333333, -118.584444444444],
      [43.5, -118.426111111111],
    ],
  ]