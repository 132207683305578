export const arkansas =
  [
    // Hog A, AR
    [
      [35.2544444444444, -94.2911111111111],
      [35.2305555555556, -94.2502777777778],
      [35.2305555555556, -94.2002777777778],
      [35.1930555555556, -94.2002777777778],
      // East along Arkansas HWY 10
      [35.1899, -94.1930],
      [35.1881, -94.1866],
      [35.1880, -94.1802],
      [35.1871, -94.1785],
      [35.1808, -94.1752],
      [35.1804, -94.1746],
      [35.1795, -94.1716],
      [35.1795, -94.1676],
      [35.1783, -94.1611],
      [35.1785, -94.1541],
      [35.1801, -94.1452],
      [35.1808, -94.1400],
      [35.1825, -94.1315],
      [35.1824, -94.1259],
      [35.1817, -94.1185],
      [35.1808, -94.1123],
      [35.1806, -94.1079],
      [35.1767, -94.0792],
      [35.1749, -94.0761],
      [35.1736, -94.0717],
      [35.1744, -94.0557],
      [35.1763, -94.0467],
      [35.1744, -94.0317],
      [35.1716, -94.0255],
      [35.1702, -94.0243],
      [35.1678, -94.0189],
      [35.1675, -94.0169444444444],
      [35.25, -94.0169444444444],
      [35.0833333333333, -93.5669444444444],
      [34.85, -93.4169444444445],
      [34.6366666666667, -93.5216666666667],
      [34.6916666666667, -93.7647222222222],
      [34.6827777777778, -93.8383333333333],
      [34.7886111111111, -94.4477777777778],
      [34.9166666666667, -94.3855555555556],
      [35.0919444444444, -94.2991666666667],
      [35.2283333333333, -94.3136111111111],
    ],
    // Hog B, AR
    [
      [34.7886111111111, -94.4477777777778],
      [34.6827777777778, -93.8383333333333],
      [34.6916666666667, -93.7647222222222],
      [34.6366666666667, -93.5216666666667],
      [34.375, -93.6502777777778],
      [34.375, -94.0002777777778],
      [34.5, -94.3836111111111],
      [34.7, -94.5002777777778],
    ],
    // Shirley A, AR
    [
      [35.3166666666667, -92.6333333333333],
      [35.3166666666667, -93.2],
      [35.6375, -93.5833333333333],
      [36.0333333333333, -93.2166666666667],
      [36.0333333333333, -92.6333333333333],
    ],
    // Shirley B, AR
    [
      [35.3166666666667, -92.6333333333333],
      [36.0333333333333, -92.6333333333333],
      [36.0333333333333, -91.9169444444445],
      [35.9813888888889, -91.7666666666667],
      [35.3166666666667, -92.0333333333333],
    ],
    // Shirley C, AR
    [
      [35.3166666666667, -92.0333333333333],
      [35.9813888888889, -91.7666666666667],
      [35.8833333333333, -91.475],
      [35.6333333333333, -91.475],
      [35.3166666666667, -91.7502777777778],
    ],
  ]