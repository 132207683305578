export const florida =
  [
    // W-151 S Block
    [
      [30.3361111111111, -86.8],
      [30.3475, -86.6472222222222],
      [30.3294444444444, -86.3958333333333],
      [30.2502777777778, -86.1041666666667],
      [30.215, -86],
      [30.1445, -85.8943],
      [30.0333333333333, -85.8],
      [30.2333333333333, -86.8],
    ],
    // W-151 S-5
    [
      [30.3361111111111, -86.8],
      [30.3475, -86.6472222222222],
      [30.2028, -86.6472222222222],
      [30.2333333333333, -86.8],
    ],
    // W-151 A/B North Split
    [
      [30.2333333333333, -86.8],
      [30.0333333333333, -85.8],
      [29.7166666666667, -85.5],
      [30.0333333333333, -86.8],
    ],
    // W-151 A/B/C/D Split
    [
      [29.7, -86.8],
      [29.25, -85],
      [28.5833333333333, -85],
      [29.05, -86.8],
    ],
    // W-151 B/D/F Vertical split
    [
      [29.7166666666667, -85.5],
      [29.25, -85],
      [28.0397222222222, -85],
      [28.0286111111111, -85.2036111111111],
      [28.1183, -85.5],
    ],
    // W-151 A/C/E East/West Split
    [
      [30.3361111111111, -86.8],
      [30.3475, -86.6472222222222],
      [30.3294444444444, -86.3958333333333],
      [28.3862, -86.3958333333333],
      [28.5052777777778, -86.8],
    ],
    // Valparaiso, FL W-151A
    [
      [30.3361111111111, -86.8],
      [30.3475, -86.6472222222222],
      [30.3294444444444, -86.3958333333333],
      [30.2502777777778, -86.1041666666667],
      [30.215, -86],
      [29.1836111111111, -86],
      [29.3836111111111, -86.8],
    ],
    // W-151B
    [
      [30.215, -86],
      [29.8331, -85.4282],
      [29.8016666666667, -85.4719444444444],
      [29.6748333333333, -85.434],
      [29.6143333333333, -85.381],
      [29.616, -85.326],
      [29.6383333333333, -85.2986666666667],
      [29.6255, -85.2326666666667],
      [29.624, -85.218],
      [29.5921666666667, -85.1703333333333],
      [29.581, -85.1283333333333],
      [29.5698333333333, -85.1136666666667],
      [29.5443333333333, -85.0861666666667],
      [29.5411666666667, -85.0531666666667],
      [29.5461111111111, -85],
      [28.9169444444444, -85],
      [29.1836111111111, -86],
    ],
    // W-151C
    [
      [29.3836111111111, -86.8],
      [29.1836111111111, -86],
      [28.5113888888889, -86],
      [28.6836111111111, -86.8],
    ],
    // W-151D
    [
      [29.1836111111111, -86],
      [28.9169444444444, -85],
      [28.2502777777778, -85],
      [28.5113888888889, -86],
    ],
    // W-151E
    [
      [28.6836111111111, -86.8],
      [28.5113888888889, -86],
      [28.2694444444444, -86],
      [28.5052777777778, -86.8],
    ],
    // W-151F
    [
      [28.5113888888889, -86],
      [28.2502777777778, -85],
      [28.0397222222222, -85],
      [28.0286111111111, -85.2036111111111],
      [28.2694444444444, -86],
    ],
    // Pensacola, FL W-155A
    [
      [30.173, -88.025],
      [30.1891666666667, -87.7375],
      [30.1625, -87.7633333333333],
      [30.1127171093165, -87.7291780486546],
      [30.0675, -87.6883333333333],
      [30.0480555555556, -87.7047222222222],
      [30.0429291650042, -87.6993131818436],
      [29.9989136895001, -87.6444752191156],
      [29.9624744871037, -87.5827575781236],
      [29.9344169908272, -87.5155308279598],
      [29.9153607950283, -87.4442831378407],
      [29.9057264344818, -87.3705882282296],
      [29.9097222222222, -87.2563888888889],
      [30.3055555555556, -87.0002777777778],
      [30.3361111111111, -86.8],
      [29.4225, -86.8],
      [29.6002777777778, -88.025],
    ],
    // W-155B
    [
      [29.6002777777778, -88.025],
      [29.4225, -86.8],
      [28.6836111111111, -86.8],
      [28.8752777777778, -87.7333333333333],
      [29.0002777777778, -88.025],
    ],
    // W-155C
    [
      [29.0002777777778, -88.025],
      [28.8752777777778, -87.7333333333333],
      [28.6836111111111, -86.8],
      [28.5052777777778, -86.8],
      [28.8558333333333, -88.025],
    ],
    // W-155B Split
    [
      [29.4975, -87.3165014],
      [28.7897, -87.3165014],
      [28.8752777777778, -87.7333333333333],
      [29.0002777777778, -88.025],
      [29.6002777777778, -88.025],
    ],
    // W-155A Split
    [
      [29.9437, -87.5378],
      [29.9344169908272, -87.5155308279598],
      [29.9153607950283, -87.4442831378407],
      [29.9057264344818, -87.3705882282296],
      [29.9097222222222, -87.2563888888889],
      [29.4805, -87.1996],
      [29.5595, -87.7436],
    ],
  ]