export const texas =
  [
    // Brady Low/High MOA
    [
      [31.3538888888889, -98.5572222222222],
      [30.8869444444444, -98.8225],
      [31.1366666666667, -99.5655555555555],
      [31.3002777777778, -99.6169444444444],
      [31.3419444444444, -99.5002777777778],
    ],
    // Brady North MOA
    [
      [31.3833333333333, -99.75],
      [31.3861111111111, -99.3833333333333],
      [31.3888888888889, -98.8930555555556],
      [31.3916666666667, -98.55],
      [31.3538888888889, -98.5572222222222],
      [31.3419444444444, -99.5002777777778],
      [31.3002777777778, -99.6169444444444],
    ],
  ]