export const wisconsin =
  [
    // Minnow, WI
    [
      [44.0916666666667, -87.4958333333333],
      [44.0205555555556, -86.9438888888889],
      [43.6833333333333, -86.6333333333333],
      [43.2833333333333, -86.7333333333333],
      [43.2583333333333, -87.2333333333333],
      [43.3166666666667, -87.6833333333333],
    ],
    // Volk East, WI
    [
      [44.7002777777778, -90.3361111111111],
      [44.5833333333333, -90.3],
      [44.45, -89.9833333333333],
      [44.1666666666667, -89.9833333333333],
      [44.05, -89.9283333333333],
      [43.6666666666667, -89.7708333333333],
      [43.5916666666667, -88.775],
      [44.1594444444444, -88.7541666666667],
      [44.4833333333333, -89],
    ],
    // Volk Falls, WI
    [
      [44.8, -90.4333333333333],
      [44.1444444444444, -90.7388888888889],
      [44.0458333333333, -90.7416666666667],
      [44.2833333333333, -91.35],
      [44.55, -91.35],
      [44.8, -90.9833333333333],
    ],
    // Volk South, WI
    [
      [43.6666666666667, -89.7708333333333],
      [43.9916666666667, -90.5877777777778],
      [44.0005555555556, -90.5875],
      [44.0005555555556, -90.3666666666667],
      [44.05, -90.2833333333333],
      [44.05, -89.9283333333333],
    ],
    // Vok West, WI
    [
      [44.8, -90.4333333333333],
      [44.8, -90.3666666666667],
      [44.5833333333333, -90.3],
      [44.45, -89.9833333333333],
      [44.1666666666667, -89.9833333333333],
      [44.05, -89.9283333333333],
      [44.05, -90.2833333333333],
      [44.0005555555556, -90.3666666666667],
      [44.0005555555556, -90.6097222222222],
      [44.0027777777778, -90.6113888888889],
      [44.0077777777778, -90.6113888888889],
      [44.16, -90.6138888888889],
      [44.16, -90.6727777777778],
      [44.1444444444444, -90.6727777777778],
      [44.1444444444444, -90.6727777777778],
      [44.1444444444444, -90.7388888888889],
    ],
  ]