export const indiana =
  [
    // Hill Top, IN
    [
      [40.9833333333333, -86.7166666666667],
      [40.9583333333333, -86.4416666666667],
      [40.8833333333333, -86.15],
      [40.5916666666667, -86.1583333333333],
      [40.4166666666667, -86.2333333333333],
      [40.3833333333333, -86.4333333333333],
      [40.675, -86.8833333333333],
    ],
    // Red Hills, IN
    [
      [38.8333333333333, -88.4166666666667],
      [38.9666666666667, -87.4083333333333],
      [38.9166666666667, -87.3166666666667],
      [38.7666666666667, -87.2833333333333],
      [38.4333333333333, -87.5],
      [38.45, -88.4166666666667],
    ],
    // Twelve Mile East, IN
    [
      [41.05, -86.6666666666667],
      [41, -86.15],
      [40.8, -86.1525],
      [40.8, -86.7666666666667],
      [40.75, -86.85],
    ],
    // Twelve Mile West, IN
    [
      [41.05, -86.6666666666667],
      [41.0833333333333, -86.9666666666667],
      [41.0166666666667, -87.1],
      [40.75, -86.8666666666667],
      [40.75, -86.85],
    ],
  ]