export const arizona =
  [
    // Fuzzy
    [
      [31.5166666666667, -111.642222222222],
      [31.725, -111.592222222222],
      [31.725, -111.075555555556],
      [31.6486111111111, -111.0825],
      [31.3680555555556, -111.186666666667],
    ],
    // Jackal
    [
      [32.675, -110.5],
      [33.75, -110.5],
      [34, -109.566666666667],
      [32.9083333333333, -109.566666666667],
      [32.5, -109.733333333333],
    ],
    // Morenci
    [
      [33.25, -109.567222222222],
      [33.25, -108.408888888889],
      [32.4708333333333, -109.317222222222],
      [32.5, -109.733888888889],
      [32.9083333333333, -109.567222222222],
    ],
    // Outlaw
    [
      [33.1166666666667, -111.383333333333],
      [33.4916666666667, -111.383333333333],
      [33.75, -110.5],
      [32.675, -110.5],
      [32.7, -110.633333333333],
    ],
    // Outlaw/Jackal A
    [
      [33.4916666666667, -111.383333333333],
      [33.75, -110.5],
      [34, -109.566666666667],
      [33.8783333333333, -109.566666666667],
      [33.5666666666667, -110.5],
      [33.4916666666667, -110.761666666667],
      [33.4216666666667, -111.383333333333],
    ],
    // Outlaw/Jackal B/C Demarcation
    [
      [33.4916666666667, -111.383333333333],
      [33.75, -110.5],
      [34, -109.566666666667],
      [33.25, -109.566666666667],
      [33.2966666666667, -111.383333333333],
    ],
    // Outlaw D
    [
      [33.44229, -111.2],
      [33.0150, -111.2],
      [33.1166666666667, -111.383333333333],
      [33.4216666666667, -111.383333333333],
    ],
    // Reserve
    [
      [34, -109.567222222222],
      [34, -108.8],
      [33.9, -108.8],
      [33.4944444444444, -108.122222222222],
      [33.25, -108.408888888889],
      [33.25, -109.567222222222],
    ],
    // Reserve A
    [
      [34, -109.567222222222],
      [34, -109.185],
      [33.8766666666667, -109.567222222222],
    ],
    // Reserve C
    [
      [33.8766666666667, -109.567222222222],
      [33.955, -109.326666666667],
      [33.2683333333333, -108.381666666667],
      [33.25, -108.408888888889],
      [33.25, -109.567222222222],
    ],
    // Ruby
    [
      [31.5166666666667, -111.642222222222],
      [31.725, -111.592222222222],
      [31.8166666666667, -111.533888888889],
      [31.8166666666667, -111.067222222222],
      [31.6486111111111, -111.0825],
      [31.3680555555556, -111.186666666667],
    ],
    // Ruby A/B Demarcation
    [
      [31.44606666667, -111.4257],
      [31.81666666667, -111.3273],
    ],
    // Sells 1
    [
      [31.9666666666667, -113.0925],
      [32.1916666666667, -113.0925],
      [32.1916666666667, -112.946666666667],
      [32.4833333333333, -112.8925],
      [32.8402777777778, -112.8175],
      [32.8477777777778, -112.715555555556],
      [32.8166666666667, -112.650833333333],
      [32.6416666666667, -112.300833333333],
      [32.2527777777778, -111.600555555556],
      [31.9625, -111.600555555556],
      [31.8166666666667, -111.533888888889],
      [31.725, -111.592222222222],
      [31.5166666666667, -111.642222222222],
    ],
    // Sells N/S Demarcation
    [
      [32.4833333333333, -112.8925],
      [32.8402777777778, -112.8175],
      [32.8477777777778, -112.715555555556],
      [32.8166666666667, -112.650833333333],
      [32.6416666666667, -112.300833333333],
      [32.2527777777778, -111.600555555556],
      [31.9625, -111.600555555556],
      [32.2783, -112.9295],
      [32.4833333333333, -112.8925],
    ],
    // Sells E/W Demarcation
    [
      [32.475, -112],
      [32.2527777777778, -111.600555555556],
      [31.9625, -111.600555555556],
      [31.8166666666667, -111.533888888889],
      [31.725, -111.592222222222],
      [31.5166666666667, -111.642222222222],
      [31.6860, -112.1874],
    ],
    // Sells E
    [
      [32.2783, -112.9295],
      [32.2099, -112.6413],
      [31.7833333333333, -112.5],
      [31.9666666666667, -113.0925],
      [32.1916666666667, -113.0925],
      [32.1916666666667, -112.946666666667],
    ],
    // Tombstone A
    [
      [31.9458333333333, -109.726666666667],
      [31.8761111111111, -109.046388888889],
      [31.7833333333333, -109.046388888889],
      [31.6372222222222, -109.381388888889],
      [31.6645, -109.413833333333],
      [31.6855, -109.446],
      [31.7031666666667, -109.483166666667],
      [31.7136666666667, -109.520166666667],
      [31.7218333333333, -109.546333333333],
      [31.7222222222222, -109.576111111111],
      [31.7263888888889, -109.683888888889],
      [31.6777777777778, -109.806111111111],
      [31.7041666666667, -109.875555555556],
      [31.8013888888889, -109.913055555556],
    ],
    // Tombstone B
    [
      [31.5, -109.353333333333],
      [31.6472222222222, -109.000555555556],
      [31.7041666666667, -108.783888888889],
      [31.8347222222222, -108.517222222222],
      [31.8166666666667, -108.200555555556],
      [31.3333333333333, -108.200555555556],
      [31.3333333333333, -109.353333333333],
    ],
    // Tombstone C
    [
      [31.8875, -110.000555555556],
      [31.9166666666667, -109.967222222222],
      [31.95, -109.767222222222],
      [31.85, -108.800555555556],
      [31.8166666666667, -108.200555555556],
      [31.3333333333333, -108.200555555556],
      [31.3333333333333, -110.000555555556],
    ],

    // douglas : [31.4726736, -109.6020536],
    [
      [31.4726736, -109.6020536],
    ],
    // Tombstone West-1 West
    [
      [31.723225, -109.6020536],
      [31.9330596, -109.6020536],
    ],
    // Tombstone West-2 North demarcation
    [
      [31.6372222222222, -109.381388888889],
      [31.8991333333333, -109.271111111111],
    ],
    // Tombstone West-2 Arc
    [
      [31.3333, -108.9420],
      [31.3837714381062, -108.928226598797],
      [31.4708569546672, -108.919985011565],
      [31.4708569546672, -108.919985011565],
      [31.5580219829601, -108.926977136824],
      [31.6433171578918, -108.949088577474],
      [31.6589, -108.9561],
      [31.6472222222222, -109.000555555556],
      [31.5, -109.353333333333],
      [31.3333333333333, -109.353333333333],
    ],
    // Tombstone East Division Line
    [
      [31.7559866666666, -108.6781],
      [31.3333333333333, -108.567333333333],
    ],
    // Tombstone East 2 N/S Division
    [
      [31.5872, -108.6339],
      [31.6382, -108.2006],
    ],
  ]