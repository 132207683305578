export const indiana =
  [
    // Atterbury Reserve Forces Training Area, IN R-3401A
    [
      [39.3583333333333, -86.1],
      [39.3583333333333, -85.9916666666667],
      [39.2166666666667, -85.9916666666667],
      [39.2166666666667, -86.1],
    ],
    // Atterbury Reserve Forces Training Area, IN R-3401B
    [
      [39.3666666666667, -86.1111111111111],
      [39.3666666666667, -85.9916666666667],
      [39.3583333333333, -85.9916666666667],
      [39.3583333333333, -86.1],
      [39.2166666666667, -86.1],
      [39.2166666666667, -85.9916666666667],
      [39.2083333333333, -85.9916666666667],
      [39.2083333333333, -86.1638888888889],
      [39.3166666666667, -86.1888888888889],
    ],
    // Jefferson Proving Ground, IN R-3403A
    [
      [39.0491666666667, -85.4616666666667],
      [39.0333333333333, -85.3666666666667],
      [38.935, -85.3666666666667],
      [38.8430555555556, -85.3805555555555],
      [38.8333333333333, -85.4],
      [38.8333333333333, -85.4616666666667],
    ],
    // Jefferson Proving Ground, IN R-3403B
    [
      [39.0833333333333, -85.5],
      [39.0833333333333, -85.3666666666667],
      [39.0333333333333, -85.3666666666667],
      [39.0491666666667, -85.4616666666667],
      [38.9166666666667, -85.4616666666667],
      [38.9583333333333, -85.5],
    ],
  ]