export const aars =
[
  // R-4006
  [
    [38.37047165960217, -75.85697622115438],
    [38.367914682717945, -75.8355229541374],
    [38.36818384243387, -75.80291398827158],
    [38.375316210146785, -75.77047664854189],
    [38.3894442965398, -75.73666629972311],
    [38.40976422086483, -75.70784321961055],
    [38.432088145100884, -75.68965084918013],
    [38.462201057422625, -75.67626401056152],
    [38.48746469521452, -75.67351799238335],
    [38.52453782492996, -75.68381556055151],
    [38.54843718268543, -75.69754565144238],
    [38.57232859964271, -75.72431932867958],
    [38.59111402184603, -75.76104732181267],
    [38.5999683023164, -75.79880507176257],
    [38.59943171034705, -75.84960640805879],
    [38.5865522996341, -75.89560221254322],
    [38.57152340031986, -75.92477865568634],
    [38.54951112467033, -75.94674680111171],
    [38.527492108156494, -75.96322291018078],
    [38.499824525651256, -75.97146096471529],
    [38.47940279393121, -75.97317722607666],
    [38.45306096586124, -75.96734193744801],
    [38.42267528334637, -75.95017932383445],
    [38.397658004404335, -75.92477865568634],
    [38.38070589506078, -75.89319944663733],
    [38.37033643007721, -75.85870485204258]
  ],
  // R-4006
  [
    [38.026564323266285, -75.803989041705],
    [38.02180191213756, -76.00030328799222],
    [38.24736970282286, -75.99749358462292],
    [38.2484481265771, -75.66110635779653],
    [38.02717290543971, -75.80363981611421]
  ]
]