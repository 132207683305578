export const missouri =
  [
    // Fort Leonard Wood, MO R-4501A
    [
      [37.685, -92.155],
      [37.6375, -92.155],
      [37.6263888888889, -92.1772222222222],
      [37.6041666666667, -92.1772222222222],
      [37.6041666666667, -92.2561111111111],
      [37.6577777777778, -92.2561111111111],
      [37.6852777777778, -92.24],
    ],
    // Fort Leonard Wood, MO R-4501B
    [
      [37.7166666666667, -92.1155555555555],
      [37.7030555555556, -92.1041666666667],
      [37.6519444444444, -92.105],
      [37.6375, -92.155],
      [37.7172222222222, -92.155],
    ],
    // Fort Leonard Wood, MO R-4501C/D/E
    [
      [37.6833333333333, -92.2697222222222],
      [37.6905555555556, -92.1711111111111],
      [37.6711111111111, -92.1183333333333],
      [37.6388888888889, -92.1155555555555],
      [37.6019444444444, -92.1744444444444],
      [37.5894444444444, -92.2588888888889],
    ],
    // Fort Leonard Wood, MO R-4501/F
    [
      [37.6833333333333, -92.1513888888889],
      [37.6833333333333, -92.1813888888889],
      [37.7172222222222, -92.2030555555556],
      [37.7194444444444, -92.1461111111111],
    ],
    // Fort Leonard Wood, MO R-4501H
    [
      [37.7305555555556, -92.1225],
      [37.7333333333333, -92.1211111111111],
      [37.7458333333333, -92.0947222222222],
      [37.7472222222222, -92.0802777777778],
      [37.7708333333333, -92.0919444444444],
      [37.7958333333333, -92.1002777777778],
      [37.8, -92.1002777777778],
      [37.8, -92.0447222222222],
      // South along the Big Piney River and Reservation boundary
      [37.7968, -92.0338],
      [37.7887, -92.0346],
      [37.7874, -92.0325],
      [37.7791666666667, -92.0280555555556],
      [37.7083333333333, -92.0683333333333],
      [37.7041666666667, -92.1016666666667],
      [37.7138888888889, -92.1225]
    ],
  ]