export const westVirginia =
  [
    // Evers, WV
    [
      [38.6669444444444, -79.9663888888889],
      [38.6669444444444, -79.3302777777778],
      [38.4, -79.3302777777778],
      [38.4, -79.9663888888889],
    ],
    // Pickett 1, VA
    [
      [37.0938888888889, -77.9113888888889],
      [37.0938888888889, -77.8647222222222],
      [37.0738888888889, -77.8622222222222],
      // Along State HWY 40
      [37.0655555555556, -77.8511111111111],
      [37.0455555555556, -77.8436111111111],
      [37.0183333333333, -77.845],
      [37.1669444444444, -77.7163888888889],
      [37.2002777777778, -77.7663888888889],
      [37.1308333333333, -77.9113888888889],
    ],
    // Pickett 2, VA
    [
      [36.9652777777778, -77.8883333333333],
      [36.9002777777778, -77.9330555555556],
      [36.8669444444444, -78.2163888888889],
      [37.0502777777778, -78.0830555555555],
      [37.0308333333333, -77.9775],
      [36.9702777777778, -77.9613888888889],
    ],
    // Pickett 3, VA
    [
      [37.0308333333333, -77.9775],
      [37.0502777777778, -78.0830555555555],
      [37.1308333333333, -77.9113888888889],
      [37.0938888888889, -77.9113888888889],
      [37.0727777777778, -77.9325],
      [37.0308333333333, -77.9325],
    ],
  ]