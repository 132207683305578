export const nevada =
  [
    // Alamo A
    [
      [37.2833333333333, -115.300833333333],
      [37.2835, -115.184333333333],
      [37.2, -115.1175],
      [37, -115.1175],
      [37, -115.300833333333],
    ],
    // Alamo B
    [
      [37, -115.300833333333],
      [37, -115.1175],
      [36.85, -115.1175],
      [36.85, -115.300833333333],
    ],
    // Alamo C
    [
      [36.85, -115.300833333333],
      [36.85, -115.1175],
      [36.8, -115.117833333333],
      [36.6333333333333, -115.300833333333],
      [36.7166666666667, -115.300833333333],
    ],
    // Caliente A
    [
      [37.9706666666667, -115.1175],
      [37.9888333333333, -114.845166666667],
      [37.2833333333333, -114.845333333333],
      [37.2835, -115.1175],
    ],
    // Caliente B
    [
      [37.9888333333333, -114.845166666667],
      [38.0041666666667, -114.576],
      [37.465, -114.5785],
      [37.2833333333333, -114.845333333333],
    ],
    // Caliente C
    [
      [38.0041666666667, -114.576],
      [38.0166666666667, -114.2],
      [37.8956666666667, -113.701],
      [37.8236666666667, -113.700333333333],
      [37.4503333333333, -114.007833333333],
      [37.465, -114.5785],
    ],
    // Coyote A
    [
      [37.5506666666667, -115.800833333333],
      [37.5511666666667, -115.433333333333],
      [37.2835, -115.434166666667],
      [37.2833333333333, -115.584166666667],
      [37.4666666666667, -115.584166666667],
      [37.4668333333333, -115.800833333333],
    ],
    // Coyote B
    [
      [37.9458333333333, -115.433],
      [37.9706666666667, -115.1175],
      [37.2835, -115.1175],
      [37.2, -115.1175],
      [37.2835, -115.184333333333],
      [37.2833333333333, -115.300833333333],
      [37.2835, -115.434166666667],
      [37.5511666666667, -115.433333333333],
    ],
    // Coyote C
    [
      [37.909, -115.884],
      [37.9458333333333, -115.433],
      [37.5511666666667, -115.433333333333],
      [37.5506666666667, -115.800833333333],
      [37.5505, -115.884166666667],
      [37.6321666666667, -115.884166666667],
      [37.7, -115.884166666667],
    ],
    // Coyote D
    [
      [37.8833333333333, -116.184166666667],
      [37.909, -115.884],
      [37.7, -115.884166666667],
      [37.7, -116.184166666667],
    ],
    // CTA
    [
      [36.6061666666667, -115.796666666667],
      [36.6061666666667, -115.670166666667],
      [36.6061666666667, -115.617],
      [36.5833333333333, -115.6175],
      [36.5833333333333, -115.776333333333],
    ],
    // EC East
    [
      [37.8833333333333, -116.434166666667],
      [37.8833333333333, -116.184166666667],
      [37.7, -116.184166666667],
      [37.5333333333333, -116.283333333333],
      [37.5333333333333, -116.316833333333],
      [37.55, -116.316833333333],
      [37.55, -116.434166666667],
      [37.7018333333333, -116.434166666667],
      [37.702, -116.383333333333],
      [37.7518333333333, -116.383333333333],
      [37.7518333333333, -116.434166666667],
    ],
    // EC South
    [
      [37.2666666666667, -116.922],
      [37.2668333333333, -116.834166666667],
      [37.2666666666667, -116.5175],
      [37.1333333333333, -116.450833333333],
      [37, -116.450833333333],
      [36.9166666666667, -116.450833333333],
      [36.9166666666667, -116.559166666667],
      [36.85, -116.559166666667],
    ],
    // EC West
    [
      [37.8835, -116.6675],
      [37.8833333333333, -116.434166666667],
      [37.7518333333333, -116.434166666667],
      [37.752, -116.5005],
      [37.7368333333333, -116.500333333333],
      [37.7025, -116.4665],
      [37.7018333333333, -116.434166666667],
      [37.55, -116.434166666667],
      [37.55, -116.708333333333],
      [37.55, -116.716666666667],
      [37.6666666666667, -116.8175],
      [37.6666666666667, -116.6675],
    ],
    // Elgin
    [
      [37.2833333333333, -114.845333333333],
      [37.465, -114.5785],
      [37.4503333333333, -114.007833333333],
      [36.7166666666667, -114.600833333333],
      [36.7168333333333, -114.845333333333],
    ],
    // Pahute A
    [
      [37.3833333333333, -116.300833333333],
      [37.3833333333333, -116.284166666667],
      [37.333, -116.183833333333],
      [37.2666666666667, -116.184166666667],
      [37.2666666666667, -116.250833333333],
      [37.2668333333333, -116.300833333333],
    ],
    // Pahute B
    [
      [37.35, -116.5675],
      [37.35, -116.450833333333],
      [37.3833333333333, -116.3675],
      [37.3833333333333, -116.317166666667],
      [37.3833333333333, -116.300833333333],
      [37.2668333333333, -116.300833333333],
      [37.2666666666667, -116.5175],
    ],
    // Reveille North
    [
      [38.2333333333333, -116.3145],
      [38.2333333333333, -115],
      [38.0166666666667, -114.2],
      [38.0041666666667, -114.576],
      [37.9993333333333, -116.667],
    ],
    // Reveille South
    [
      [37.9993333333333, -116.667],
      [38.0041666666667, -114.576],
      [37.9888333333333, -114.845166666667],
      [37.9706666666667, -115.1175],
      [37.9458333333333, -115.433],
      [37.909, -115.884],
      [37.8833333333333, -116.184166666667],
      [37.8833333333333, -116.434166666667],
      [37.8835, -116.6675],
      [37.8835, -116.833333333333],
    ],
    // Sally Corridor
    [
      [37.2835, -115.1175],
      [37.2833333333333, -114.845333333333],
      [36.7168333333333, -114.845333333333],
      [36.7166666666667, -115.050833333333],
      [36.4333333333333, -115.300833333333],
      [36.5335, -115.300833333333],
      [36.6333333333333, -115.300833333333],
      [36.8, -115.117833333333],
    ],
    // Shelf
    [
      [37.715, -116.858833333333],
      [37.715, -116.6675],
      [37.6666666666667, -116.6675],
      [37.6666666666667, -116.8175],
    ],
    // TPECR
    [
      [37.3333333333333, -116.834166666667],
      [37.3333333333333, -116.825166666667],
      [37.3333333333333, -116.750333333333],
      [37.3333333333333, -116.668666666667],
      [37.3333333333333, -116.6175],
      [37.35, -116.5675],
      [37.2666666666667, -116.5175],
      [37.2668333333333, -116.834166666667],
    ],
    // TPECR (FAC A)
    [
      [37.3753333333333, -116.789666666667],
      [37.3751666666667, -116.750166666667],
      [37.3333333333333, -116.750333333333],
      [37.3333333333333, -116.825166666667],
    ],
    // TPECR (FAC B)
    [
      [37.3751666666667, -116.750166666667],
      [37.3753333333333, -116.708333333333],
      [37.3753333333333, -116.699333333333],
      [37.3333333333333, -116.668666666667],
      [37.3333333333333, -116.750333333333],
    ],
  ]