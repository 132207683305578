export const arizona =
  [
    // Jackal Low
    [
      [32.6713888888889, -110.484444444444],
      [33.0536111111111, -110.292777777778],
      [33, -110],
      [32.7813888888889, -109.783333333333],
      [32.5397222222222, -109.905277777778],
    ],
  ]