export const germany =
  [
    // ED-R161A LANTA
    [
      [53.3855559999999, 8.22833299999999],
      [52.7416669999999, 8.572222],
      [52.516667, 8.333333],
      [52.7, 7.75],
      [52.7, 7.258333],
      [52.718056, 7.063889],
      [52.808333, 7.075],
      [53, 7.3],
      [53.309444, 7.3],
    ],
    // ED-R161B LANTA
    [
      [52.683333, 8.513889],
      [52.1, 8.75],
      [52.080278, 8.34444399999999],
      [52.0458329999999, 8.29666699999999],
      [52.7, 7.75],
      [52.516667, 8.333333],
    ],
    // ED-R162 LANTA
    [
      [52.1, 8.75],
      [51.9, 8.866667],
      [51.5, 8.866667],
      [51.394444, 8.733333],
      [51.7305559999999, 8.375],
      [51.823611, 7.959722],
      [52.080278, 8.34444399999999],
    ],
    // ED-R163A LANTA
    [
      [49.916667, 9.71666699999999],
      [49.683333, 9.44999999999999],
      [49.283333, 9.44999999999999],
      [49.116667, 9.783333],
      [48.95, 10.1],
      [49.133333, 10.5],
      [50.083333, 10.5],
      [50.266667, 10.2],
      [50.25, 9.633333],
    ],
    // ED-R163B LANTA
    [
      [49.133333, 10.5],
      [49.2333329999999, 10.666667],
      [50, 10.666667],
      [50.083333, 10.5],
    ],
    // ED-R164 LANTA
    [
      [49.152222, 11.523889],
      [49.166667, 11.966667],
      [49.2, 12.016667],
      [49.25, 12.033333],
      [49.618056, 12.524722],
      [49.531667, 12.608333],
      [49.35, 12.786667],
      [48.936389, 13.515833],
      [48.880833, 13.688889],
      [48.3416669999999, 13.083333],
      [48.414167, 12.614444],
      [48.588611, 12.548056],
      [48.666667, 12.24],
      [48.6675, 11.794167],
    ],
    // ED-R165A LANTA
    [
      [50.433333, 6.376389],
      [50.433333, 6.383333],
      [50.540556, 6.434167],
      [50.593056, 7.055278],
      [50.549722, 7.446389],
      [50.556111, 7.676667],
      [50.454167, 7.804167],
      [50.333333, 7.808333],
      [50.219444, 7.678056],
      [50.346389, 7.589722],
      [50.286667, 7.315556],
      [50.119444, 7.373889],
      [50.113056, 6.233333],
      [50.260833, 6.233333],
      [50.260867, 6.23320599999999],
      [50.261906, 6.234872],
      [50.262531, 6.23607499999999],
      [50.262419, 6.238928],
      [50.261656, 6.237578],
      [50.261639, 6.238242],
      [50.261664, 6.239789],
      [50.261822, 6.241058],
      [50.261897, 6.241675],
      [50.261911, 6.242514],
      [50.261931, 6.243592],
      [50.262019, 6.244572],
      [50.262086, 6.245572],
      [50.262628, 6.245914],
      [50.262747, 6.246803],
      [50.262961, 6.247781],
      [50.263347, 6.249189],
      [50.263747, 6.250572],
      [50.264217, 6.251831],
      [50.26465, 6.252861],
      [50.264967, 6.253636],
      [50.265392, 6.25451099999999],
      [50.265664, 6.255039],
      [50.265869, 6.255492],
      [50.266444, 6.25555599999999],
      [50.267453, 6.257464],
      [50.267842, 6.259014],
      [50.265675, 6.260353],
      [50.265736, 6.261428],
      [50.264981, 6.261517],
      [50.265058, 6.261997],
      [50.265114, 6.262322],
      [50.26515, 6.262564],
      [50.265147, 6.262778],
      [50.2651, 6.263064],
      [50.2650829999999, 6.263306],
      [50.265161, 6.263572],
      [50.265236, 6.263717],
      [50.2653469999999, 6.26395599999999],
      [50.265397, 6.264167],
      [50.265403, 6.264372],
      [50.2653439999999, 6.26470599999999],
      [50.265278, 6.265067],
      [50.265208, 6.265356],
      [50.265103, 6.265667],
      [50.265008, 6.265847],
      [50.264892, 6.266019],
      [50.2648059999999, 6.26623099999999],
      [50.264747, 6.266442],
      [50.264961, 6.266864],
      [50.265269, 6.267333],
      [50.265511, 6.26775599999999],
      [50.265764, 6.268103],
      [50.266056, 6.2684],
      [50.266297, 6.268664],
      [50.266578, 6.268992],
      [50.2668579999999, 6.269303],
      [50.266867, 6.269708],
      [50.266867, 6.269958],
      [50.266853, 6.27027199999999],
      [50.266839, 6.27053299999999],
      [50.266872, 6.270714],
      [50.266933, 6.270931],
      [50.267019, 6.271142],
      [50.267131, 6.27136699999999],
      [50.2672529999999, 6.271522],
      [50.267397, 6.271669],
      [50.267472, 6.271786],
      [50.2675969999999, 6.272014],
      [50.267719, 6.272317],
      [50.26715, 6.273328],
      [50.267236, 6.273631],
      [50.267247, 6.27399399999999],
      [50.267225, 6.274322],
      [50.267158, 6.27474699999999],
      [50.2670359999999, 6.275158],
      [50.266906, 6.275672],
      [50.2667, 6.276119],
      [50.2665, 6.276442],
      [50.266244, 6.276831],
      [50.265967, 6.27712499999999],
      [50.266111, 6.277439],
      [50.266167, 6.277653],
      [50.266172, 6.277861],
      [50.266169, 6.278025],
      [50.266297, 6.278394],
      [50.266444, 6.278592],
      [50.266589, 6.278714],
      [50.266722, 6.278711],
      [50.266814, 6.2787],
      [50.2668689999999, 6.278658],
      [50.266919, 6.278619],
      [50.267058, 6.278653],
      [50.267131, 6.278656],
      [50.267208, 6.278667],
      [50.2672529999999, 6.278725],
      [50.267292, 6.27879699999999],
      [50.2673139999999, 6.278892],
      [50.2673579999999, 6.278892],
      [50.267433, 6.278903],
      [50.267497, 6.278897],
      [50.267556, 6.27895],
      [50.2675999999999, 6.279031],
      [50.267631, 6.279125],
      [50.2677809999999, 6.279394],
      [50.2680419999999, 6.279706],
      [50.268072, 6.27976399999999],
      [50.268111, 6.279708],
      [50.2682, 6.279747],
      [50.268264, 6.279822],
      [50.2683189999999, 6.279953],
      [50.2683689999999, 6.280089],
      [50.268422, 6.280211],
      [50.268464, 6.280422],
      [50.2685389999999, 6.280575],
      [50.268633, 6.280703],
      [50.268722, 6.280792],
      [50.268808, 6.280853],
      [50.269103, 6.28122199999999],
      [50.269286, 6.28135],
      [50.269428, 6.281428],
      [50.269644, 6.281522],
      [50.269925, 6.281703],
      [50.270197, 6.281919],
      [50.270617, 6.2822],
      [50.270719, 6.28235799999999],
      [50.270811, 6.282383],
      [50.271, 6.282269],
      [50.271128, 6.282211],
      [50.271192, 6.282236],
      [50.2713, 6.28230299999999],
      [50.271433, 6.282522],
      [50.27155, 6.282697],
      [50.271703, 6.282808],
      [50.271814, 6.282972],
      [50.271892, 6.283158],
      [50.271911, 6.283308],
      [50.271978, 6.283411],
      [50.2720859999999, 6.28350799999999],
      [50.272197, 6.283642],
      [50.272364, 6.283794],
      [50.2725169999999, 6.28395],
      [50.2725689999999, 6.284003],
      [50.2725419999999, 6.284094],
      [50.272544, 6.284181],
      [50.272603, 6.284289],
      [50.27275, 6.284456],
      [50.272889, 6.28459999999999],
      [50.272983, 6.284708],
      [50.273078, 6.284814],
      [50.2732389999999, 6.285039],
      [50.273281, 6.285075],
      [50.273336, 6.285019],
      [50.273406, 6.284944],
      [50.273475, 6.284883],
      [50.273517, 6.284808],
      [50.273589, 6.284853],
      [50.273658, 6.284914],
      [50.2737559999999, 6.285053],
      [50.273822, 6.28520599999999],
      [50.273856, 6.285356],
      [50.273922, 6.285533],
      [50.273969, 6.285628],
      [50.274, 6.285722],
      [50.274014, 6.285831],
      [50.273989, 6.28595],
      [50.2739919999999, 6.286028],
      [50.274058, 6.286144],
      [50.274108, 6.286175],
      [50.274175, 6.286192],
      [50.274236, 6.286214],
      [50.274275, 6.286261],
      [50.2743, 6.28638899999999],
      [50.274369, 6.286469],
      [50.274414, 6.286506],
      [50.274472, 6.28660299999999],
      [50.274444, 6.28678599999999],
      [50.274447, 6.287019],
      [50.274503, 6.287003],
      [50.27455, 6.287111],
      [50.274581, 6.287189],
      [50.274603, 6.28727499999999],
      [50.274664, 6.287342],
      [50.274692, 6.28742199999999],
      [50.274669, 6.287633],
      [50.274672, 6.287714],
      [50.274681, 6.28781099999999],
      [50.274731, 6.28791399999999],
      [50.2747529999999, 6.288069],
      [50.274789, 6.288194],
      [50.274767, 6.288342],
      [50.2748079999999, 6.288469],
      [50.274839, 6.288531],
      [50.274831, 6.288672],
      [50.274869, 6.288781],
      [50.274886, 6.288894],
      [50.274889, 6.28899399999999],
      [50.274933, 6.289125],
      [50.275031, 6.28925599999999],
      [50.27515, 6.289275],
      [50.2752999999999, 6.289339],
      [50.275436, 6.289492],
      [50.275483, 6.289558],
      [50.275547, 6.289581],
      [50.275592, 6.289569],
      [50.275692, 6.289614],
      [50.275733, 6.28965299999999],
      [50.275753, 6.289825],
      [50.275811, 6.289861],
      [50.275881, 6.289942],
      [50.275942, 6.290008],
      [50.275992, 6.290089],
      [50.27595, 6.290194],
      [50.275956, 6.290272],
      [50.276025, 6.290289],
      [50.276083, 6.29029199999999],
      [50.276131, 6.290344],
      [50.276172, 6.290431],
      [50.276203, 6.29055299999999],
      [50.276244, 6.290625],
      [50.276311, 6.290692],
      [50.276356, 6.290758],
      [50.27645, 6.290933],
      [50.276567, 6.29105],
      [50.276633, 6.291067],
      [50.276711, 6.29107799999999],
      [50.276786, 6.291194],
      [50.276828, 6.291289],
      [50.276894, 6.29129199999999],
      [50.276944, 6.291322],
      [50.2770029999999, 6.291403],
      [50.277069, 6.291461],
      [50.27715, 6.291364],
      [50.27725, 6.291375],
      [50.277303, 6.291464],
      [50.277361, 6.291539],
      [50.277444, 6.291469],
      [50.277494, 6.291422],
      [50.277567, 6.291403],
      [50.277614, 6.29145299999999],
      [50.277697, 6.291444],
      [50.277747, 6.291503],
      [50.277781, 6.291617],
      [50.277917, 6.291653],
      [50.277986, 6.291661],
      [50.278064, 6.291706],
      [50.278172, 6.291697],
      [50.278261, 6.291572],
      [50.278308, 6.291553],
      [50.278375, 6.291689],
      [50.278408, 6.291847],
      [50.278436, 6.29196399999999],
      [50.278486, 6.292022],
      [50.278611, 6.29191899999999],
      [50.278686, 6.292072],
      [50.278758, 6.292131],
      [50.278942, 6.291953],
      [50.279269, 6.291761],
      [50.279519, 6.29152199999999],
      [50.279836, 6.29130599999999],
      [50.280122, 6.290939],
      [50.280397, 6.29051699999999],
      [50.280686, 6.290192],
      [50.2810359999999, 6.28983599999999],
      [50.281425, 6.289503],
      [50.281664, 6.289392],
      [50.281972, 6.289253],
      [50.282258, 6.289122],
      [50.2822499999999, 6.288489],
      [50.282281, 6.28787799999999],
      [50.282344, 6.287333],
      [50.282472, 6.286783],
      [50.282592, 6.286475],
      [50.282944, 6.286394],
      [50.2832689999999, 6.286281],
      [50.2835439999999, 6.28605599999999],
      [50.283753, 6.285814],
      [50.283978, 6.28546699999999],
      [50.284164, 6.285253],
      [50.284311, 6.285153],
      [50.284803, 6.28505],
      [50.285381, 6.285122],
      [50.285753, 6.285281],
      [50.286144, 6.285358],
      [50.286761, 6.285694],
      [50.286969, 6.285803],
      [50.287825, 6.28605],
      [50.287897, 6.28646699999999],
      [50.288153, 6.286447],
      [50.288417, 6.287042],
      [50.288672, 6.287692],
      [50.288864, 6.288219],
      [50.2897469999999, 6.287514],
      [50.289503, 6.28627199999999],
      [50.289908, 6.286044],
      [50.290156, 6.28709399999999],
      [50.290381, 6.288022],
      [50.2904309999999, 6.288528],
      [50.290761, 6.288406],
      [50.291139, 6.288214],
      [50.291578, 6.287897],
      [50.291922, 6.28750299999999],
      [50.292242, 6.287033],
      [50.292417, 6.286675],
      [50.2934639999999, 6.28604699999999],
      [50.293908, 6.285908],
      [50.294072, 6.285858],
      [50.294489, 6.28664999999999],
      [50.2947439999999, 6.287386],
      [50.294878, 6.28809399999999],
      [50.2949609999999, 6.288867],
      [50.294983, 6.28949399999999],
      [50.294911, 6.28998299999999],
      [50.295631, 6.290231],
      [50.295594, 6.290992],
      [50.295633, 6.2915],
      [50.295675, 6.292019],
      [50.295917, 6.292014],
      [50.296436, 6.29233899999999],
      [50.2969169999999, 6.29271699999999],
      [50.297144, 6.292097],
      [50.297339, 6.291678],
      [50.2976999999999, 6.291081],
      [50.2979, 6.291786],
      [50.298042, 6.292353],
      [50.298217, 6.29309199999999],
      [50.298322, 6.293844],
      [50.298475, 6.294683],
      [50.298875, 6.294222],
      [50.298953, 6.29396899999999],
      [50.299856, 6.295692],
      [50.300344, 6.295211],
      [50.300806, 6.294847],
      [50.301211, 6.29471899999999],
      [50.301422, 6.295256],
      [50.301531, 6.295614],
      [50.301681, 6.295942],
      [50.301903, 6.296347],
      [50.302117, 6.296669],
      [50.302303, 6.298386],
      [50.302719, 6.297492],
      [50.303289, 6.297956],
      [50.303917, 6.298342],
      [50.304275, 6.298606],
      [50.305019, 6.29575299999999],
      [50.305308, 6.295875],
      [50.305481, 6.29598299999999],
      [50.305731, 6.29615],
      [50.306003, 6.29647199999999],
      [50.306219, 6.296075],
      [50.307203, 6.29740299999999],
      [50.307517, 6.295578],
      [50.30785, 6.29583299999999],
      [50.308144, 6.295972],
      [50.308358, 6.296031],
      [50.308725, 6.296081],
      [50.308983, 6.296092],
      [50.308853, 6.297772],
      [50.309011, 6.297853],
      [50.308997, 6.298619],
      [50.309506, 6.298328],
      [50.309511, 6.299139],
      [50.309725, 6.299169],
      [50.3104139999999, 6.300789],
      [50.309994, 6.302311],
      [50.310311, 6.303108],
      [50.310036, 6.303794],
      [50.3098969999999, 6.304075],
      [50.309836, 6.304192],
      [50.309794, 6.304375],
      [50.309769, 6.304631],
      [50.309711, 6.305533],
      [50.309675, 6.306378],
      [50.309594, 6.307008],
      [50.3101609999999, 6.307017],
      [50.310144, 6.307339],
      [50.310681, 6.307667],
      [50.310694, 6.30834199999999],
      [50.311058, 6.30835],
      [50.3111, 6.308664],
      [50.3113859999999, 6.308711],
      [50.311547, 6.3092],
      [50.3116029999999, 6.309531],
      [50.312197, 6.309214],
      [50.312481, 6.30777199999999],
      [50.312697, 6.307689],
      [50.3129079999999, 6.307575],
      [50.313169, 6.3075],
      [50.313281, 6.307417],
      [50.3134109999999, 6.307331],
      [50.313575, 6.30714699999999],
      [50.313756, 6.307003],
      [50.314403, 6.307272],
      [50.314844, 6.307403],
      [50.315319, 6.30763599999999],
      [50.315542, 6.307786],
      [50.315708, 6.307858],
      [50.315881, 6.30834199999999],
      [50.31625, 6.307472],
      [50.31655, 6.30681699999999],
      [50.316747, 6.306403],
      [50.317, 6.30598599999999],
      [50.317314, 6.305606],
      [50.3176579999999, 6.305483],
      [50.318039, 6.305419],
      [50.318442, 6.305544],
      [50.31895, 6.305764],
      [50.319331, 6.305969],
      [50.319742, 6.306267],
      [50.320086, 6.306478],
      [50.320292, 6.307669],
      [50.32035, 6.308653],
      [50.320831, 6.309642],
      [50.320958, 6.30998099999999],
      [50.3210889999999, 6.310414],
      [50.321264, 6.310861],
      [50.321392, 6.311239],
      [50.321519, 6.311697],
      [50.321558, 6.312192],
      [50.321656, 6.312736],
      [50.321747, 6.31323299999999],
      [50.3213579999999, 6.313864],
      [50.321083, 6.314372],
      [50.3208609999999, 6.314836],
      [50.320656, 6.315517],
      [50.320533, 6.315911],
      [50.321014, 6.316514],
      [50.321514, 6.316956],
      [50.321247, 6.318133],
      [50.320028, 6.317075],
      [50.318944, 6.31785],
      [50.319381, 6.318608],
      [50.319597, 6.319044],
      [50.319828, 6.319444],
      [50.320064, 6.319911],
      [50.320264, 6.3203],
      [50.320403, 6.320658],
      [50.320544, 6.321061],
      [50.320683, 6.321522],
      [50.320822, 6.32190599999999],
      [50.3208829999999, 6.32212199999999],
      [50.320997, 6.322392],
      [50.3210889999999, 6.322572],
      [50.321294, 6.32287199999999],
      [50.321536, 6.323267],
      [50.321708, 6.323531],
      [50.321961, 6.32385299999999],
      [50.322236, 6.324219],
      [50.322431, 6.32451399999999],
      [50.322594, 6.324833],
      [50.322783, 6.325203],
      [50.322975, 6.325569],
      [50.323169, 6.325917],
      [50.323372, 6.326125],
      [50.323656, 6.326364],
      [50.3240749999999, 6.326717],
      [50.3244, 6.327022],
      [50.324706, 6.327383],
      [50.324517, 6.32778899999999],
      [50.324044, 6.328703],
      [50.323819, 6.329069],
      [50.323761, 6.329153],
      [50.323661, 6.32919999999999],
      [50.323614, 6.329233],
      [50.323556, 6.32925799999999],
      [50.323489, 6.329356],
      [50.323117, 6.330025],
      [50.323078, 6.330208],
      [50.323061, 6.330381],
      [50.323028, 6.330528],
      [50.322967, 6.330625],
      [50.322761, 6.330914],
      [50.322964, 6.331564],
      [50.323156, 6.332247],
      [50.323433, 6.333036],
      [50.323189, 6.333567],
      [50.3227919999999, 6.333408],
      [50.320736, 6.334217],
      [50.320661, 6.333764],
      [50.320628, 6.333428],
      [50.3207, 6.333175],
      [50.320725, 6.332961],
      [50.320033, 6.332847],
      [50.319717, 6.332914],
      [50.319381, 6.33307199999999],
      [50.319042, 6.333364],
      [50.3188469999999, 6.333756],
      [50.318667, 6.334439],
      [50.3186189999999, 6.335031],
      [50.318731, 6.33559699999999],
      [50.318094, 6.335817],
      [50.317947, 6.335881],
      [50.317747, 6.335322],
      [50.317597, 6.33552799999999],
      [50.317347, 6.335653],
      [50.317039, 6.334956],
      [50.317064, 6.334708],
      [50.316389, 6.334614],
      [50.316433, 6.334942],
      [50.317047, 6.33616699999999],
      [50.316928, 6.336311],
      [50.3170969999999, 6.336853],
      [50.317286, 6.337539],
      [50.317386, 6.33812199999999],
      [50.317486, 6.338433],
      [50.317644, 6.338967],
      [50.317778, 6.33946699999999],
      [50.317842, 6.339861],
      [50.317889, 6.340114],
      [50.317953, 6.34028599999999],
      [50.318008, 6.340533],
      [50.318089, 6.340644],
      [50.318183, 6.34085599999999],
      [50.318261, 6.34095799999999],
      [50.318536, 6.3413],
      [50.318428, 6.342956],
      [50.318153, 6.34247199999999],
      [50.317911, 6.342011],
      [50.317181, 6.342461],
      [50.317708, 6.34325599999999],
      [50.317517, 6.343411],
      [50.317431, 6.34344999999999],
      [50.317392, 6.343542],
      [50.317347, 6.343639],
      [50.317317, 6.343794],
      [50.317256, 6.34377799999999],
      [50.317233, 6.343706],
      [50.317231, 6.343575],
      [50.317247, 6.343406],
      [50.317283, 6.343308],
      [50.317264, 6.343242],
      [50.317208, 6.343175],
      [50.317117, 6.343072],
      [50.317053, 6.342933],
      [50.316978, 6.342808],
      [50.316961, 6.342658],
      [50.316881, 6.342561],
      [50.316819, 6.342622],
      [50.316753, 6.342569],
      [50.316703, 6.342475],
      [50.316658, 6.342444],
      [50.3165809999999, 6.342461],
      [50.316469, 6.34254999999999],
      [50.316356, 6.342514],
      [50.316217, 6.34245],
      [50.316133, 6.342239],
      [50.316103, 6.341939],
      [50.316014, 6.341728],
      [50.315967, 6.341642],
      [50.31595, 6.34151699999999],
      [50.316058, 6.341353],
      [50.316219, 6.341325],
      [50.316425, 6.341292],
      [50.316211, 6.341031],
      [50.316094, 6.340925],
      [50.316083, 6.340761],
      [50.316028, 6.34058099999999],
      [50.315911, 6.34048099999999],
      [50.315811, 6.34051399999999],
      [50.315719, 6.340722],
      [50.315619, 6.341067],
      [50.315531, 6.341683],
      [50.315475, 6.342144],
      [50.315358, 6.34263099999999],
      [50.315281, 6.343092],
      [50.3152, 6.343836],
      [50.315078, 6.3445],
      [50.314944, 6.34482799999999],
      [50.314814, 6.345242],
      [50.314717, 6.345728],
      [50.314686, 6.346106],
      [50.314747, 6.34629399999999],
      [50.314897, 6.346578],
      [50.315, 6.34681899999999],
      [50.31505, 6.346936],
      [50.315094, 6.347111],
      [50.315, 6.347261],
      [50.314911, 6.347486],
      [50.314914, 6.347742],
      [50.314967, 6.347917],
      [50.315064, 6.34805],
      [50.315097, 6.3482],
      [50.315078, 6.348342],
      [50.314972, 6.348553],
      [50.3148859999999, 6.34869699999999],
      [50.314761, 6.348733],
      [50.314644, 6.34887799999999],
      [50.314456, 6.348997],
      [50.314278, 6.349011],
      [50.314172, 6.348989],
      [50.314056, 6.34895],
      [50.313853, 6.348956],
      [50.313544, 6.34906099999999],
      [50.313236, 6.349217],
      [50.31295, 6.34928899999999],
      [50.31265, 6.349453],
      [50.312442, 6.34965],
      [50.3123669999999, 6.349961],
      [50.312386, 6.35035799999999],
      [50.311314, 6.350872],
      [50.310975, 6.351139],
      [50.310747, 6.351436],
      [50.310586, 6.351828],
      [50.310422, 6.352369],
      [50.3101719999999, 6.353367],
      [50.310022, 6.354089],
      [50.309714, 6.354972],
      [50.309467, 6.35567199999999],
      [50.309114, 6.356347],
      [50.308828, 6.357017],
      [50.308544, 6.357461],
      [50.308097, 6.357989],
      [50.307747, 6.358364],
      [50.307489, 6.3586],
      [50.307272, 6.358725],
      [50.307158, 6.358847],
      [50.306994, 6.359225],
      [50.306803, 6.359722],
      [50.306536, 6.360286],
      [50.306386, 6.360864],
      [50.306347, 6.361269],
      [50.306339, 6.361397],
      [50.306347, 6.361497],
      [50.3063, 6.36152199999999],
      [50.306242, 6.361569],
      [50.306181, 6.361625],
      [50.306083, 6.361747],
      [50.305861, 6.36230799999999],
      [50.305483, 6.36308099999999],
      [50.305358, 6.363467],
      [50.305308, 6.363664],
      [50.305306, 6.363792],
      [50.305322, 6.363864],
      [50.305381, 6.363931],
      [50.305428, 6.363956],
      [50.305472, 6.363928],
      [50.305497, 6.36382199999999],
      [50.305528, 6.363711],
      [50.305539, 6.363619],
      [50.3056639999999, 6.363075],
      [50.305806, 6.36304699999999],
      [50.305861, 6.36305],
      [50.305956, 6.363025],
      [50.306039, 6.362986],
      [50.306058, 6.362917],
      [50.306089, 6.362839],
      [50.306156, 6.362664],
      [50.306319, 6.362431],
      [50.306586, 6.362158],
      [50.306806, 6.361906],
      [50.307011, 6.361481],
      [50.307236, 6.36091899999999],
      [50.307456, 6.360675],
      [50.307808, 6.360511],
      [50.308128, 6.36040799999999],
      [50.308503, 6.360283],
      [50.308769, 6.360139],
      [50.3088779999999, 6.359944],
      [50.308978, 6.35973599999999],
      [50.3091059999999, 6.359375],
      [50.309244, 6.359069],
      [50.309561, 6.35865],
      [50.309789, 6.358328],
      [50.309867, 6.35875],
      [50.309889, 6.358867],
      [50.3099219999999, 6.358994],
      [50.310011, 6.3591],
      [50.3101389999999, 6.359319],
      [50.3105809999999, 6.358733],
      [50.310842, 6.358419],
      [50.311103, 6.358011],
      [50.311197, 6.358044],
      [50.311297, 6.357872],
      [50.311369, 6.357961],
      [50.311319, 6.35827799999999],
      [50.3113, 6.3584],
      [50.311306, 6.358514],
      [50.311331, 6.358672],
      [50.310878, 6.359219],
      [50.311572, 6.359039],
      [50.311969, 6.358939],
      [50.312358, 6.358772],
      [50.312667, 6.358672],
      [50.312858, 6.358661],
      [50.313008, 6.358711],
      [50.313117, 6.35875],
      [50.313231, 6.358778],
      [50.313272, 6.35881399999999],
      [50.3135, 6.35901899999999],
      [50.3128639999999, 6.359344],
      [50.313208, 6.359739],
      [50.3133639999999, 6.360131],
      [50.313428, 6.360561],
      [50.313486, 6.360978],
      [50.313486, 6.36130599999999],
      [50.314781, 6.361497],
      [50.314717, 6.362286],
      [50.3145889999999, 6.362736],
      [50.314428, 6.36318299999999],
      [50.314242, 6.363808],
      [50.314083, 6.36445799999999],
      [50.313889, 6.365153],
      [50.313667, 6.365969],
      [50.313522, 6.366519],
      [50.313511, 6.366903],
      [50.313525, 6.367311],
      [50.313778, 6.367417],
      [50.313614, 6.3682],
      [50.313417, 6.368631],
      [50.3131249999999, 6.369011],
      [50.312764, 6.36959199999999],
      [50.312531, 6.370236],
      [50.312564, 6.370608],
      [50.312792, 6.371156],
      [50.312994, 6.371764],
      [50.313286, 6.372781],
      [50.313381, 6.373519],
      [50.313458, 6.374264],
      [50.313575, 6.374778],
      [50.313794, 6.37516699999999],
      [50.313989, 6.375367],
      [50.314222, 6.375517],
      [50.314561, 6.37580299999999],
      [50.315, 6.376236],
      [50.315194, 6.375906],
      [50.315306, 6.375731],
      [50.315494, 6.37549699999999],
      [50.315619, 6.375311],
      [50.315831, 6.374814],
      [50.3158389999999, 6.375208],
      [50.315875, 6.375411],
      [50.315892, 6.375547],
      [50.3159079999999, 6.375653],
      [50.315931, 6.375869],
      [50.31595, 6.375961],
      [50.315964, 6.376056],
      [50.316039, 6.376164],
      [50.316097, 6.376242],
      [50.316131, 6.376314],
      [50.3161719999999, 6.376358],
      [50.316239, 6.376461],
      [50.316314, 6.3765],
      [50.316361, 6.376531],
      [50.316481, 6.376681],
      [50.3175, 6.375981],
      [50.318283, 6.377717],
      [50.3185, 6.377519],
      [50.319033, 6.378756],
      [50.319203, 6.378908],
      [50.319086, 6.37975],
      [50.319094, 6.380258],
      [50.319169, 6.380839],
      [50.319233, 6.381297],
      [50.319883, 6.38104999999999],
      [50.320594, 6.380828],
      [50.321019, 6.380919],
      [50.3213529999999, 6.38119999999999],
      [50.3215969999999, 6.381569],
      [50.321906, 6.382125],
      [50.322142, 6.382431],
      [50.321678, 6.385778],
      [50.321414, 6.387497],
      [50.321175, 6.388856],
      [50.321064, 6.389578],
      [50.321139, 6.39037199999999],
      [50.321322, 6.391736],
      [50.32155, 6.393358],
      [50.321764, 6.395114],
      [50.322008, 6.397067],
      [50.322208, 6.398308],
      [50.322433, 6.399547],
      [50.322619, 6.400575],
      [50.3227919999999, 6.401908],
      [50.322908, 6.402586],
      [50.3230969999999, 6.403283],
      [50.323172, 6.403644],
      [50.323281, 6.40477799999999],
      [50.3233219999999, 6.405469],
      [50.325211, 6.405969],
      [50.327044, 6.406286],
      [50.328622, 6.406667],
      [50.3302889999999, 6.40705599999999],
      [50.332092, 6.40738599999999],
      [50.333397, 6.407719],
      [50.3347889999999, 6.407936],
      [50.3357609999999, 6.407936],
      [50.336442, 6.407406],
      [50.336931, 6.40620299999999],
      [50.337111, 6.404986],
      [50.337433, 6.403183],
      [50.337678, 6.401669],
      [50.337925, 6.400742],
      [50.338653, 6.400192],
      [50.339536, 6.400164],
      [50.341492, 6.400294],
      [50.343164, 6.40031899999999],
      [50.3442689999999, 6.399958],
      [50.345242, 6.398981],
      [50.346214, 6.397481],
      [50.347108, 6.395881],
      [50.348275, 6.393811],
      [50.349861, 6.39116699999999],
      [50.350781, 6.389467],
      [50.3519939999999, 6.3872],
      [50.3524499999999, 6.386353],
      [50.352811, 6.385617],
      [50.3542469999999, 6.383028],
      [50.354042, 6.38234699999999],
      [50.353725, 6.381719],
      [50.3534, 6.380953],
      [50.3539389999999, 6.379803],
      [50.353633, 6.379511],
      [50.353875, 6.379308],
      [50.3544389999999, 6.37885299999999],
      [50.354864, 6.378394],
      [50.3551809999999, 6.378017],
      [50.355644, 6.377447],
      [50.356036, 6.377053],
      [50.355214, 6.376336],
      [50.3559719999999, 6.375844],
      [50.356581, 6.37538299999999],
      [50.3571859999999, 6.374639],
      [50.3573809999999, 6.37449399999999],
      [50.357619, 6.374344],
      [50.357608, 6.37415],
      [50.357375, 6.373814],
      [50.357278, 6.373625],
      [50.358144, 6.373456],
      [50.35825, 6.372636],
      [50.35825, 6.372283],
      [50.358281, 6.372],
      [50.358431, 6.371661],
      [50.358142, 6.370614],
      [50.358858, 6.370003],
      [50.358489, 6.368989],
      [50.358072, 6.368181],
      [50.358806, 6.367147],
      [50.359175, 6.367733],
      [50.359317, 6.367536],
      [50.359722, 6.368253],
      [50.360144, 6.368989],
      [50.360406, 6.368733],
      [50.361028, 6.36970599999999],
      [50.361528, 6.36918099999999],
      [50.362261, 6.368289],
      [50.362844, 6.367358],
      [50.363303, 6.367281],
      [50.3636859999999, 6.367136],
      [50.363997, 6.366875],
      [50.364606, 6.366747],
      [50.364956, 6.366372],
      [50.365717, 6.365683],
      [50.3667, 6.364586],
      [50.367725, 6.363619],
      [50.367483, 6.364533],
      [50.368131, 6.363847],
      [50.368517, 6.363333],
      [50.369528, 6.362467],
      [50.370361, 6.361664],
      [50.371211, 6.360936],
      [50.371319, 6.360775],
      [50.371819, 6.359711],
      [50.3724, 6.35839399999999],
      [50.372964, 6.35717499999999],
      [50.373472, 6.355847],
      [50.373497, 6.355044],
      [50.373497, 6.354131],
      [50.373661, 6.3533],
      [50.373906, 6.352822],
      [50.374333, 6.352386],
      [50.374964, 6.35188299999999],
      [50.37585, 6.35121099999999],
      [50.376194, 6.350897],
      [50.376589, 6.350258],
      [50.377222, 6.349347],
      [50.377986, 6.348236],
      [50.378544, 6.347147],
      [50.378844, 6.34635],
      [50.379228, 6.344731],
      [50.3796329999999, 6.342981],
      [50.379858, 6.342239],
      [50.3803859999999, 6.342736],
      [50.381225, 6.343467],
      [50.382053, 6.344153],
      [50.383089, 6.34499699999999],
      [50.384039, 6.3458],
      [50.3848609999999, 6.346475],
      [50.385269, 6.346967],
      [50.385903, 6.34816099999999],
      [50.386686, 6.349514],
      [50.387533, 6.351167],
      [50.388664, 6.353281],
      [50.389444, 6.35489699999999],
      [50.390233, 6.356392],
      [50.390514, 6.356803],
      [50.391239, 6.357292],
      [50.392006, 6.357553],
      [50.393019, 6.35760799999999],
      [50.395044, 6.357744],
      [50.395475, 6.35776699999999],
      [50.395736, 6.358011],
      [50.396469, 6.358808],
      [50.397739, 6.360197],
      [50.398436, 6.361017],
      [50.399736, 6.363214],
      [50.400547, 6.364667],
      [50.400994, 6.36535599999999],
      [50.401831, 6.3659],
      [50.4037, 6.36691699999999],
      [50.405522, 6.367967],
      [50.407183, 6.368975],
      [50.408792, 6.369906],
      [50.409531, 6.370067],
      [50.410969, 6.369586],
      [50.4139279999999, 6.36852199999999],
      [50.415764, 6.367975],
      [50.417539, 6.367297],
      [50.419175, 6.366711],
      [50.419647, 6.366706],
      [50.420067, 6.36698599999999],
      [50.420806, 6.367733],
      [50.421858, 6.368717],
      [50.423061, 6.369811],
      [50.423842, 6.37048899999999],
      [50.425097, 6.371658],
      [50.425875, 6.372272],
      [50.426244, 6.37235],
      [50.426553, 6.372603],
      [50.4276499999999, 6.373464],
      [50.428206, 6.373922],
      [50.428614, 6.373964],
      [50.429806, 6.374069],
      [50.4311169999999, 6.37575],
      [50.432392, 6.376081],
      [50.434144, 6.376506],
    ],
    // ED-165B LANTA
    [
      [50.119444, 7.373889],
      [50.286667, 7.315556],
      [50.346389, 7.589722],
      [50.219444, 7.678056],
      [50.119444, 7.563889],
    ],
    // ED-R166 LANTA
    [
      [54.416667, 12],
      [54.416667, 13],
      [53.5, 13],
      [53.5, 12],
      [53.583333, 12],
      [53.583333, 11.583333],
    ],
  ]