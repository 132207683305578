export const southKorea =
  [
    // ACMI D
    [
      [37.0855555555556, 125.6],
      [37.0833333333333, 125.7],
      [36.5833333333333, 125.7],
      [36.5833333333333, 125.6],
    ],
    // CATA-3
    [
      [33.46666667, 126.65],
      [33.45, 126.8666667],
      [33.18333333, 127.0666667],
      [33.08333333, 126.95],
      [33.08333333, 126.5],
    ],
    // CATA-4A
    [
      [35.41666667, 126.5],
      [35.41666667, 126.6666667],
      [35.36666667, 126.6666667],
      [35.16666667, 126.4166667],
      [35.16666667, 126.25],
      [35.21666667, 126.25],
    ],
    // CATA-4B
    [
      [35.16666667, 126.4166667],
      [35.36666667, 126.6666667],
      [35.16666667, 126.6666667],
    ],
    // CATA-5
    [
      [35.5, 126],
      [35.5, 126.3333333],
      [35.3, 126.3333333],
      [35.3, 126],
    ],
    // CATA-6
    [
      [33.25, 126.3],
      [33.25, 126.5666667],
      [33.08333333, 126.5],
      [33.08333333, 126.3],
    ],
    // CATA-7L
    [
      [36.83333333, 129.8477778],
      [36.5, 129.8477778],
      [36.5, 129.4352778],
    ],
    // HTA-D1
    [
      [37.18333333, 126.6166667],
      [37.18333333, 126.7666667],
      [37.05, 126.7666667],
      [37.05, 126.6166667],
    ],
    // HTA-D2
    [
      [37.18333333, 126.4166667],
      [37.18333333, 126.6166667],
      [37.05, 126.6166667],
      [37.05, 126.4166667],
    ],
    // MOA-2L
    [
      [36.58333333, 127.1166667],
      [36.58333333, 127.3666667],
      [35.96666667, 127.3666667],
      [35.98333333, 127.0666667],
    ],
    // MOA-3A
    [
      [36.56666667, 127.5333333],
      [36.58333333, 127.7166667],
      [36.38333333, 127.95],
      [36.3, 127.85],
      [36.45, 127.6166667],
    ],
    // MOA-3L
    [
      [36.26666667, 127.3666667],
      [36.36666667, 127.7166667],
      [35.93333333, 128.25],
      [35.96666667, 127.3666667],
    ],
    // MOA-4
    [
      [36.75, 127.6666667],
      [36.85, 127.7666667],
      [36.58333333, 128.2333333],
      [36.45, 128.0833333],
    ],
    // MOA-15A
    [
      [35.76666667, 127.0333333],
      [35.73333333, 128.1],
      [35.31666667, 126.9833333],
    ],
    // MOA-19L
    [
      [35.66666667, 125.6019444],
      [35.66666667, 126.6666667],
      [35.25, 126.6666667],
      [35.25027778, 125.6027778],
    ],
    // MOA-25L
    [
      [34.75, 126.9333333],
      [34.75, 127.2833333],
      [34.25, 127.2833333],
      [34.25, 126.8833333],
    ],
    // MOA-26L
    [
      [35, 127.2833333],
      [35, 128.375],
      [34.825, 128.3583333],
      [34.13333333, 127.2833333],
    ],
    // MOA-27A
    [
      [35.23333333, 127.4],
      [35.62916667, 128.4305556],
      [35.25416667, 128.3972222],
    ],
    // MOA-34
    [
      [34.58333333, 125.4166667],
      [34.58333333, 125.8333333],
      [34.83333333, 125.8333333],
      [34.83333333, 125.4166667],
    ],
    // MOA-35
    [
      [34.33333333, 125.4166667],
      [34.33333333, 125.8333333],
      [34.58333333, 125.8333333],
      [34.58333333, 125.4166667],
    ],
    // MOA-36
    [
      [34.33333333, 125],
      [34.33333333, 125.4166667],
      [34.83333333, 125.4166667],
      [34.83333333, 125],
    ],
    // MOA-37
    [
      [37.41666667, 129.5],
      [37.41666667, 131],
      [36.5, 131],
      [36.25, 130.5833333],
      [36.75, 130.5833333],
      [37.25, 129.5],
    ],
    // MOA-38
    [
      [37.5, 131],
      [37.5, 132],
      [37, 132],
      [36.5, 131],
    ],
    // MOA-39
    [
      [33.45, 126.8666667],
      [33.75, 127.25],
      [33.55, 127.5333333],
      [33.18333333, 127.0666667],
    ],
    // MOA-40
    [
      [34.33333333, 124.5],
      [34.33333333, 126],
      [33.75, 126],
      [33.75, 124.5],
    ],
    // MOA-41
    [
      [33.75, 124.5],
      [33.75, 126],
      [33.16666667, 126],
      [33.16666667, 124.5],
    ]
  ]