export const alaska =
  [
    // Big Delta, AK R-2202A
    [
      [64.0594444444444, -146.182777777778],
      [63.9377777777778, -145.827222222222],
      [63.9052777777778, -145.841111111111],
      [63.8413888888889, -145.835555555556],
      [63.7163888888889, -145.9025],
      [63.7038888888889, -146.226111111111],
      [63.7330555555556, -146.502222222222],
      [63.7563888888889, -146.193888888889],
    ],
    // Big Delta, AK R-2202B
    [
      [64.2455555555556, -146.723055555556],
      [64.0594444444444, -146.182777777778],
      [63.7563888888889, -146.193888888889],
      [63.7330555555556, -146.502222222222],
      [63.8469444444444, -146.793888888889],
      // Along the east bank of the East Fork and Little Delta Rivers
      [63.8593, -146.7947],
      [63.8963, -146.8330],
      [63.9161, -146.8170],
      [63.9282, -146.8261],
      [63.9528, -146.8999],
      [63.9682, -146.9174],
      [63.9773, -146.9088],
      [64.0179, -146.8354],
      [64.1072, -146.8041],
    ],
    // Stuart Creek, AK R-2205
    [
      [64.7580555555556, -146.791388888889],
      // CCW along the arc of a 25 mile radius circle Centered at [64.8363888888889,-147.615277777778],
      [64.7697222222222, -146.780277777778],
      [64.7691666666667, -146.19],
      [64.5880555555556, -146.19],
      [64.5563888888889, -146.310833333333],
      [64.5566666666667, -146.419166666667],
    ],
    // Blair Lakes, AK R-2211
    [
      [64.4994444444444, -147.735833333333],
      [64.3327777777778, -147.319166666667],
      [64.2244444444444, -147.535555555556],
      [64.3744444444444, -147.969166666667],
    ],
  ]