export const newJersey =
  [
    // Joint Base McGuire-Dix-Lakehurst, NJ R-5001A
    [
      [40.0458333333333, -74.4497222222222],
      [40, -74.4386111111111],
      [39.9833333333333, -74.4186111111111],
      [39.9666666666667, -74.4163888888889],
      [39.9583333333333, -74.4211111111111],
      [39.9563888888889, -74.4302777777778],
      [39.9791666666667, -74.4663888888889],
      [39.9791666666667, -74.5233333333333],
      [39.9875, -74.5580555555556],
      [40.0313888888889, -74.5580555555556],
      [40.0458333333333, -74.5413888888889],
    ],
    // Joint Base McGuire-Dix-Lakehurst, NJ R-5001B
    [
      [40.0458333333333, -74.4497222222222],
      [40, -74.4386111111111],
      [39.9833333333333, -74.4186111111111],
      [39.9666666666667, -74.4163888888889],
      [39.9583333333333, -74.4211111111111],
      [39.9563888888889, -74.4302777777778],
      [39.9791666666667, -74.4663888888889],
      [39.9791666666667, -74.5233333333333],
      [40.0313888888889, -74.5580555555556],
      [40.0458333333333, -74.5413888888889],
    ],
    // Warren Grove, NJ R-5002A
    [
      [39.7236111111111, -74.2933333333333],
      [39.6402777777778, -74.4052777777778],
      [39.6416666666667, -74.4913888888889],
      [39.6555555555556, -74.4997222222222],
      [39.7472222222222, -74.4108333333333],
      [39.7472222222222, -74.3219444444444],
    ],
    // Warren Grove, NJ R-5002B
    [
      [39.6833333333333, -74.3475],
      [39.6694444444444, -74.3372222222222],
      [39.6472222222222, -74.3552777777778],
      [39.6, -74.4413888888889],
      [39.6, -74.4580555555556],
      [39.6166666666667, -74.4802777777778],
      [39.6416666666667, -74.4913888888889],
      [39.6402777777778, -74.4052777777778],
    ],
    // Warren Grove, NJ R-5002C
    [
      [39.6555555555556, -74.4997222222222],
      [39.675, -74.5108333333333],
      [39.7472222222222, -74.4580555555556],
      [39.7472222222222, -74.4108333333333],
    ],
    // Warren Grove, NJ R-5002D
    [
      [39.7472222222222, -74.4108333333333],
      [39.7555555555556, -74.3955555555556],
      [39.7638888888889, -74.3330555555555],
      [39.7472222222222, -74.3219444444444],
    ],
    // Warren Grove, NJ R-5002E
    [
      [39.7236111111111, -74.2933333333333],
      [39.6833333333333, -74.3475],
      [39.6694444444444, -74.3372222222222],
    ],
    // Warren Grove, NJ R-5002F
    [
      [39.7236111111111, -74.2933333333333],
      [39.6694444444444, -74.3372222222222],
      [39.6472222222222, -74.3552777777778],
      [39.6402777777778, -74.3680555555555],
      [39.6402777777778, -74.4052777777778],
      [39.6416666666667, -74.4913888888889],
      [39.6555555555556, -74.4997222222222],
      [39.7472222222222, -74.4108333333333],
      [39.7472222222222, -74.3219444444444],
    ],
    // Warren Grove, NJ R-5002G
    [
      [39.8172222222222, -74.0125],
      [39.6383333333333, -74.2094444444444],
      [39.6402777777778, -74.3680555555555],
      [39.6402777777778, -74.4052777777778],
      [39.6416666666667, -74.4913888888889],
      [39.6555555555556, -74.4997222222222],
      [39.7472222222222, -74.4108333333333],
      [39.8172222222222, -74.2716666666667],
    ],
  ]