export const germany =
  [
    // ED-R112A SENNE
    [
      [51.758333, 8.68333299999999],
      [51.883333, 8.68333299999999],
      [51.916667, 8.758333],
      [51.916667, 8.80833299999999],
      [51.9, 8.866667],
      [51.85, 8.866667],
      [51.832778, 8.851111],
      [51.832778, 8.744444],
      [51.766111, 8.744444],
      [51.766111, 8.790556],
      [51.758333, 8.783333],
    ],
    // ED-R112B SENNE
    [
      [51.832778, 8.851111],
      [51.832778, 8.744444],
      [51.766111, 8.744444],
      [51.766111, 8.790556],
    ],
    // ED-116 BAUMHOLDER
    [
      [49.666667, 7.116667],
      [49.7, 7.283333],
      [49.705556, 7.516667],
      [49.6, 7.516667],
      [49.516667, 7.333333],
      [49.583333, 7.2],
    ],
    // ED-132A HEUBERG
    [
      [48.208333, 8.94999999999999],
      [48.208333, 9.1],
      [48.116667, 9.1],
      [48.116667, 8.94999999999999],
    ],
    // ED-R132B HEUBERG
    [
      [48.208333, 8.9],
      [48.208333, 8.94999999999999],
      [48.116667, 8.94999999999999],
      [48.116667, 9.1],
      [48.083333, 9.1],
      [48.083333, 8.9],
    ],
    // ED-R134 WILDFLECKEN
    [
      [50.427778, 9.78888899999999],
      [50.422222, 9.94999999999999],
      [50.331944, 9.872222],
      [50.331944, 9.78888899999999],
    ],
    // ED-R135 HAB
    [
      [50.1166669999999, 9.80555599999999],
      [50.1166669999999, 9.93333299999999],
      [50.033333, 9.93333299999999],
      [50.033333, 9.80555599999999],
    ],
    // ED-136A GRAFENWOEHR
    [
      [49.75, 11.733333],
      [49.75, 11.833333],
      [49.716667, 11.916667],
      [49.683333, 11.966667],
      [49.65, 11.95],
      [49.616667, 11.916667],
      [49.616667, 11.642222],
      [49.690556, 11.6025],
      [49.733333, 11.666667],
    ],
    // ED-136B GRAFENWOEHR
    [
      [49.690556, 11.6025],
      [49.616667, 11.642222],
      [49.616667, 11.616667],
      [49.633333, 11.566667],
      [49.666667, 11.566667],
    ],
    // ED-R136C GRAFENWOEHR
    [
      [49.690556, 11.6025],
      [49.616667, 11.642222],
      [49.616667, 11.616667],
      [49.633333, 11.566667],
      [49.666667, 11.566667],
    ],
    // ED-R137A HOHENFELS
    [
      [49.366667, 11.716667],
      [49.316667, 11.833333],
      [49.283333, 11.95],
      [49.25, 12.033333],
      [49.2, 12.016667],
      [49.166667, 11.966667],
      [49.2, 11.75],
      [49.2, 11.688889],
      [49.25, 11.64],
      [49.283333, 11.633333],
      [49.333333, 11.666667],
    ],
    // ED-R137B HOHENFELS
    [
      [49.25, 11.64],
      [49.2, 11.688889],
      [49.2, 11.65],
    ],
    // ED-R31 BERGEN
    [
      [52.9166669999999, 9.925],
      [52.816667, 9.94999999999999],
      [52.75, 9.96666699999999],
      [52.713889, 9.96944399999999],
      [52.7152779999999, 9.848611],
      [52.709722, 9.739722],
      [52.7324999999999, 9.693056],
      [52.766667, 9.666667],
      [52.85, 9.69999999999999],
      [52.9166669999999, 9.8],
      [52.95, 9.883333],
    ],
    // ED-R32A MUNSTER
    [
      [53.038889, 9.991667],
      [52.977778, 10.175],
      [52.95, 10.166667],
      [52.9, 10.125],
      [52.816667, 9.94999999999999],
      [52.9166669999999, 9.925],
      [52.95, 9.883333],
      [53, 9.916667],
    ],
    // ED-R32B MUNSTER
    [
      [53.091667, 10.1],
      [53.05, 10.266667],
      [53, 10.266667],
      [52.977778, 10.175],
      [53.038889, 9.991667],
    ],
    // ED-R37A NORDHORN
    [
      [52.51660874, 7.2],
      [52.51629125, 7.18807307999999],
      [52.51534122, 7.17623745],
      [52.51376591, 7.16458367],
      [52.51157738, 7.15320092],
      [52.50879237, 7.14217622999999],
      [52.5054322, 7.13159386999999],
      [52.50152256, 7.12153468999999],
      [52.49709336, 7.11207544],
      [52.49217844, 7.10328826],
      [52.48681538, 7.09524006],
      [52.48104514, 7.08799205],
      [52.4749118, 7.08159925],
      [52.46846216, 7.07611009],
      [52.46174545, 7.07156607],
      [52.4548129, 7.06800138],
      [52.44771734, 7.06544275],
      [52.44051286, 7.06390917],
      [52.43325432, 7.06341182],
      [52.42599698, 7.06395399],
      [52.4187960399999, 7.06553102],
      [52.41170629, 7.06813043],
      [52.4047815999999, 7.07173197],
      [52.39807459, 7.07630781],
      [52.39163619, 7.08182277],
      [52.38551527, 7.08823457999999],
      [52.37975828, 7.09549423999999],
      [52.37440888, 7.10354636],
      [52.36950763, 7.11232961999999],
      [52.36509167, 7.12177722],
      [52.36119446, 7.13181739],
      [52.35784552, 7.14237394],
      [52.3550702099999, 7.15336682],
      [52.35288955, 7.16471272],
      [52.35132004, 7.17632572],
      [52.35037355, 7.18811789999999],
      [52.35005726, 7.2],
      [52.35037355, 7.2118821],
      [52.35132004, 7.22367427999999],
      [52.35288955, 7.23528728],
      [52.3550702099999, 7.24663318],
      [52.35784552, 7.25762606],
      [52.36119446, 7.26818261],
      [52.36509167, 7.27822278],
      [52.36950763, 7.28767038],
      [52.37440888, 7.29645364],
      [52.37975828, 7.30450576],
      [52.38551527, 7.31176542],
      [52.39163619, 7.31817723],
      [52.39807459, 7.32369218999999],
      [52.4047815999999, 7.32826803],
      [52.41170629, 7.33186957],
      [52.4187960399999, 7.33446898],
      [52.42599698, 7.33604601],
      [52.43325432, 7.33658818],
      [52.44051286, 7.33609083],
      [52.44771734, 7.33455725],
      [52.4548129, 7.33199862],
      [52.46174545, 7.32843393],
      [52.46846216, 7.32388991],
      [52.4749118, 7.31840074999999],
      [52.48104514, 7.31200795],
      [52.48681538, 7.30475994],
      [52.49217844, 7.29671174],
      [52.49709336, 7.28792456],
      [52.50152256, 7.27846531],
      [52.5054322, 7.26840613],
      [52.50879237, 7.25782377],
      [52.51157738, 7.24679908],
      [52.51376591, 7.23541633],
      [52.51534122, 7.22376255],
      [52.51629125, 7.21192692],
    ],
    // ED-R37B NORDHORN
    [
      [52.422983, 7.021994],
      [52.429133, 7.0108],
      [52.445283, 7.00510599999999],
      [52.454711, 7.000028],
      [52.462131, 6.99457799999999],
      [52.465489, 6.993908],
      [52.467356, 6.991828],
      [52.469769, 6.98761099999999],
      [52.465683, 6.977531],
      [52.4631189999999, 6.976194],
      [52.463039, 6.975997],
      [52.462903, 6.97608299999999],
      [52.459717, 6.974447],
      [52.454472, 6.969978],
      [52.449928, 6.966931],
      [52.444622, 6.96194999999999],
      [52.443992, 6.962183],
      [52.443631, 6.961783],
      [52.443992, 6.961058],
      [52.436625, 6.94730299999999],
      [52.435428, 6.941661],
      [52.442244, 6.90608299999999],
      [52.446547, 6.889064],
      [52.451361, 6.861819],
      [52.449917, 6.852544],
      [52.459708, 6.8543],
      [52.459192, 6.844736],
      [52.459964, 6.831792],
      [52.4595809999999, 6.804469],
      [52.4596, 6.774764],
      [52.464114, 6.752736],
      [52.477019, 6.723775],
      [52.477183, 6.720469],
      [52.478125, 6.717742],
      [52.486281, 6.697553],
      [52.515983, 6.703986],
      [52.521011, 6.705342],
      [52.553319, 6.680883],
      [52.548436, 6.716103],
      [52.5486999999999, 6.716239],
      [52.5492279999999, 6.71602799999999],
      [52.549806, 6.716269],
      [52.550331, 6.716239],
      [52.550892, 6.718181],
      [52.551672, 6.71999999999999],
      [52.552514, 6.720219],
      [52.552678, 6.720697],
      [52.553106, 6.721017],
      [52.561781, 6.725536],
      [52.5626279999999, 6.72558599999999],
      [52.563258, 6.725975],
      [52.56175, 6.734378],
      [52.562153, 6.738381],
      [52.562067, 6.740031],
      [52.5592439999999, 6.750606],
      [52.559044, 6.751997],
      [52.559178, 6.75377499999999],
      [52.561564, 6.763942],
      [52.5616419999999, 6.766697],
      [52.563536, 6.766661],
      [52.588625, 6.718797],
      [52.593342, 6.72007799999999],
      [52.5949, 6.721622],
      [52.596358, 6.720814],
      [52.598308, 6.72095],
      [52.603161, 6.723725],
      [52.604133, 6.723922],
      [52.604594, 6.723775],
      [52.605661, 6.724158],
      [52.607603, 6.72450599999999],
      [52.608542, 6.72442499999999],
      [52.609083, 6.724756],
      [52.610414, 6.725078],
      [52.6149889999999, 6.727336],
      [52.617847, 6.725286],
      [52.618489, 6.725003],
      [52.619144, 6.724386],
      [52.620375, 6.72289999999999],
      [52.620356, 6.72212799999999],
      [52.621553, 6.720414],
      [52.6219689999999, 6.718611],
      [52.623033, 6.718425],
      [52.6234109999999, 6.71814199999999],
      [52.6259029999999, 6.714903],
      [52.626836, 6.714244],
      [52.626844, 6.712003],
      [52.627317, 6.710169],
      [52.627494, 6.710033],
      [52.627864, 6.710044],
      [52.628897, 6.712803],
      [52.630314, 6.715133],
      [52.631344, 6.718283],
      [52.6314469999999, 6.720814],
      [52.633222, 6.726403],
      [52.637528, 6.730697],
      [52.641206, 6.736961],
      [52.6453859999999, 6.742044],
      [52.646889, 6.750492],
      [52.647278, 6.750711],
      [52.648025, 6.752253],
      [52.648136, 6.753083],
      [52.647933, 6.75365],
      [52.647633, 6.75346899999999],
      [52.6470419999999, 6.751914],
      [52.646361, 6.75247799999999],
      [52.645833, 6.753464],
      [52.646344, 6.754544],
      [52.6467919999999, 6.754883],
      [52.647058, 6.75554999999999],
      [52.647261, 6.755506],
      [52.6488279999999, 6.758833],
      [52.646864, 6.767881],
      [52.6500529999999, 6.772092],
      [52.650244, 6.774281],
      [52.651661, 6.777364],
      [52.6525249999999, 6.783881],
      [52.652364, 6.787397],
      [52.652806, 6.789783],
      [52.652556, 6.790856],
      [52.65095, 6.793272],
      [52.650642, 6.794317],
      [52.651892, 6.802375],
      [52.651875, 6.803219],
      [52.651178, 6.805856],
      [52.649447, 6.807225],
      [52.649103, 6.808019],
      [52.648619, 6.816192],
      [52.647692, 6.818586],
      [52.647733, 6.822228],
      [52.649036, 6.827519],
      [52.649883, 6.829547],
      [52.650708, 6.830297],
      [52.651497, 6.831678],
      [52.651956, 6.837672],
      [52.6518029999999, 6.838886],
      [52.648614, 6.851122],
      [52.6495749999999, 6.853825],
      [52.649794, 6.86449699999999],
      [52.65185, 6.86948299999999],
      [52.652983, 6.87142799999999],
      [52.653261, 6.873197],
      [52.652964, 6.874133],
      [52.651469, 6.876931],
      [52.650547, 6.87997499999999],
      [52.651547, 6.891692],
      [52.651339, 6.897453],
      [52.650981, 6.898669],
      [52.648581, 6.903006],
      [52.647939, 6.907931],
      [52.646711, 6.914006],
      [52.646411, 6.914625],
      [52.646003, 6.914956],
      [52.641828, 6.915639],
      [52.639986, 6.918769],
      [52.639844, 6.91936399999999],
      [52.640164, 6.928817],
      [52.638317, 6.934636],
      [52.6378419999999, 6.938736],
      [52.637936, 6.939797],
      [52.639319, 6.94524399999999],
      [52.638978, 6.953486],
      [52.6392, 6.954258],
      [52.641706, 6.959122],
      [52.641756, 6.968139],
      [52.6426029999999, 6.970214],
      [52.646061, 6.974828],
      [52.646264, 6.975892],
      [52.645067, 6.981769],
      [52.643669, 6.984183],
      [52.6413689999999, 6.992258],
      [52.641297, 6.99375799999999],
      [52.642153, 6.999411],
      [52.6421469999999, 7.000539],
      [52.641847, 7.00211099999999],
      [52.640914, 7.004453],
      [52.639203, 7.00574199999999],
      [52.638581, 7.006483],
      [52.636733, 7.012642],
      [52.635728, 7.021342],
      [52.634636, 7.028769],
      [52.634825, 7.035658],
      [52.634378, 7.036725],
      [52.633003, 7.038317],
      [52.632708, 7.039578],
      [52.632728, 7.041792],
      [52.643417, 7.055722],
      [52.644361, 7.05563299999999],
      [52.644433, 7.054772],
      [52.644617, 7.05475799999999],
      [52.666389, 7.05749999999999],
      [52.433333, 7.060833],
      [52.433333, 7.063889],
      [52.49217844, 7.29671174],
      [52.48681538, 7.30475994],
      [52.48104514, 7.31200795],
      [52.4749118, 7.31840074999999],
      [52.46846216, 7.32388991],
      [52.46174545, 7.32843393],
      [52.4548129, 7.33199862],
      [52.44771734, 7.33455725],
      [52.44051286, 7.33609083],
      [52.43325432, 7.33658818],
      [52.42599698, 7.33604601],
      [52.4187960399999, 7.33446898],
      [52.41170629, 7.33186957],
      [52.4047815999999, 7.32826803],
      [52.39807459, 7.32369218999999],
      [52.39163619, 7.31817723],
      [52.38551527, 7.31176542],
      [52.37975828, 7.30450576],
      [52.37440888, 7.29645364],
      [52.36950763, 7.28767038],
      [52.36509167, 7.27822278],
      [52.36119446, 7.26818261],
      [52.35784552, 7.25762606],
      [52.3550702099999, 7.24663318],
      [52.35288955, 7.23528728],
      [52.35132004, 7.22367427999999],
      [52.35037355, 7.2118821],
      [52.35005726, 7.2],
      [52.35037355, 7.18811789999999],
      [52.35132004, 7.17632572],
      [52.35288955, 7.16471272],
      [52.3550702099999, 7.15336682],
      [52.35784552, 7.14237394],
      [52.36119446, 7.13181739],
      [52.36509167, 7.12177722],
      [52.36950763, 7.11232961999999],
      [52.37440888, 7.10354636],
      [52.37975828, 7.09549423999999],
      [52.38551527, 7.08823457999999],
      [52.39163619, 7.08182277],
      [52.39807459, 7.07630781],
      [52.4047815999999, 7.07173197],
      [52.41170629, 7.06813043],
      [52.4187960399999, 7.06553102],
      [52.42599698, 7.06395399],
      [52.43325432, 7.06341182],
      [52.44051286, 7.06390917],
      [52.44771734, 7.06544275],
      [52.4548129, 7.06800138],
      [52.46174545, 7.07156607],
      [52.46846216, 7.07611009],
      [52.4749118, 7.08159925],
      [52.48104514, 7.08799205],
      [52.48681538, 7.09524006],
      [52.49217844, 7.10328826],
      [52.49709336, 7.11207544],
      [52.50152256, 7.12153468999999],
      [52.5054322, 7.13159386999999],
      [52.50879237, 7.14217622999999],
      [52.51157738, 7.15320092],
      [52.51376591, 7.16458367],
      [52.51534122, 7.17623745],
      [52.51629125, 7.18807307999999],
      [52.51660874, 7.2],
      [52.51629125, 7.21192692],
      [52.51598685, 7.21667722],
      [52.683333, 7.25277799999999],
      [52.718056, 7.063611],
    ],
    // ED-R76A OBERLAUSITZ
    [
      [51.411111, 14.958889],
      [51.411111, 14.765833],
      [51.426667, 14.752778],
      [51.435, 14.733889],
      [51.47, 14.695556],
      [51.500018, 14.834721],
      [51.499575, 14.836905],
      [51.496004, 14.84074],
      [51.4950389999999, 14.844441],
      [51.494203, 14.845363],
      [51.490417, 14.851311],
      [51.4898029999999, 14.852754],
      [51.490762, 14.859238],
      [51.491241, 14.860943],
      [51.4910529999999, 14.864669],
      [51.4907179999999, 14.865687],
      [51.48983, 14.866971],
      [51.486419, 14.869124],
      [51.485244, 14.871127],
      [51.484997, 14.872732],
      [51.485112, 14.874274],
      [51.485674, 14.876565],
      [51.486504, 14.879015],
      [51.48744, 14.880877],
      [51.487823, 14.883019],
      [51.488111, 14.885079],
      [51.4878629999999, 14.889687],
      [51.486291, 14.890946],
      [51.4836739999999, 14.894886],
      [51.482778, 14.896905],
      [51.482333, 14.899231],
      [51.482435, 14.901725],
      [51.482996, 14.904016],
      [51.484317, 14.907436],
      [51.484216, 14.914615],
      [51.48284, 14.921157],
      [51.481604, 14.924481],
      [51.480255, 14.925893],
      [51.478609, 14.926126],
      [51.474784, 14.925031],
      [51.47409, 14.925447],
      [51.473388, 14.92659],
      [51.473612, 14.933117],
      [51.4732649999999, 14.941162],
      [51.472932, 14.945182],
      [51.472005, 14.949396],
      [51.471018, 14.95097],
      [51.4639329999999, 14.957],
      [51.46329, 14.957269],
      [51.461063, 14.956456],
      [51.4601039999999, 14.956416],
      [51.458061, 14.958465],
      [51.454839, 14.962881],
      [51.452947, 14.964131],
      [51.449495, 14.965687],
      [51.446483, 14.968134],
      [51.443206, 14.973422],
      [51.442279, 14.974338],
      [51.438252, 14.974264],
      [51.4368929999999, 14.973697],
      [51.433139, 14.970848],
      [51.43265, 14.969951],
      [51.432802, 14.968713],
      [51.433836, 14.966994],
      [51.434554, 14.964751],
      [51.433542, 14.962152],
      [51.432919, 14.961032],
      [51.431974, 14.960048],
      [51.430967, 14.960083],
      [51.429904, 14.960918],
      [51.4275449999999, 14.965585],
      [51.426848, 14.96651],
      [51.425696, 14.966983],
      [51.424389, 14.965838],
      [51.423203, 14.965427],
      [51.422046, 14.966331],
      [51.419013, 14.967606],
      [51.418006, 14.96764],
      [51.41614, 14.96691],
      [51.412663, 14.964],
      [51.412141, 14.962299],
      [51.411762, 14.959872],
      [51.411365, 14.95905],
    ],
    // ED-R76B OBERLAUSITZ
    [
      [51.47, 14.695556],
      [51.435, 14.733889],
      [51.426667, 14.752778],
      [51.411111, 14.765833],
      [51.411111, 14.746667],
      [51.417778, 14.737222],
      [51.417778, 14.654167],
      [51.431111, 14.654167],
      [51.461111, 14.654167],
    ],
    // ED-R76C OBERLAUSITZ
    [
      [51.486667, 14.453333],
      [51.461111, 14.654167],
      [51.431111, 14.654167],
      [51.438889, 14.607222],
      [51.436111, 14.545],
      [51.4205559999999, 14.509444],
      [51.45, 14.507778],
    ],
    // ED-R76D OBERLAUSITZ
    [
      [51.486667, 14.453333],
      [51.45, 14.507778],
      [51.4205559999999, 14.509444],
      [51.466667, 14.4],
      [51.483333, 14.416667],
    ],
    // ED-R97A SCHWARZENBORN
    [
      [50.894444, 9.411111],
      [50.916667, 9.475],
      [50.906944, 9.497222],
      [50.884722, 9.497222],
      [50.869444, 9.4375],
      [50.869444, 9.402778],
    ],
    // ED-R97B SCHWARZENBORN
    [
      [51.057778, 9.582222],
      [50.944722, 9.75388899999999],
      [50.866667, 9.625],
      [50.867222, 9.385278],
      [50.936389, 9.4],
    ]
  ]