export const ohio =
  [
    // Brush Creek, OH
    [
      [38.8977777777778, -83.59],
      [39.1583333333333, -83.375],
      [39.2166666666667, -83.375],
      [39.4333333333333, -83.5166666666667],
      [39.5, -83.4166666666667],
      [39.5, -83.2166666666667],
      [39.45, -83.2],
      [39.3833333333333, -83.1416666666667],
      [39.3, -83.0333333333333],
      [38.8083333333333, -83.0333333333333],
    ],
    // Buckeye, OH
    [
      [38.9186111111111, -83.9116666666667],
      [39.1791666666667, -83.8833333333333],
      [39.4166666666667, -83.4666666666667],
      [39.4525, -83.2833333333333],
      [39.4055555555556, -82.825],
      [39.3, -82.7375],
      [38.9791666666667, -82.8333333333333],
      [38.7833333333333, -82.9791666666667],
      [38.7722222222222, -83.2388888888889],
      [38.8708333333333, -83.5666666666667],
      [38.9011111111111, -83.8297222222222],
    ],
  ]