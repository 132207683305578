export const idaho =
  [
    // Jarbidge North, ID
    [
      [42.8833333333333, -115.404166666667],
      [42.8833333333333, -115.383333333333],
      [42.6638888888889, -115.033333333333],
      [42, -115.033333333333],
      [42, -116],
      [42.75, -116],
      [42.75, -115.705555555556],
      [42.6, -115.705555555556],
      [42.6, -115.404166666667],
    ],
    // Owyhee North, ID
    [
      [42.75, -116],
      [42, -116],
      [42, -117],
      [42.75, -117],
    ],
  ]