export const texas =
  [
    // R-6312 Cotulla, TX (First of two circles)
    [
      [28.2474674368132, -98.6309192211579],
      [28.2599215554601, -98.6319622861381],
      [28.2720988473331, -98.6350905803713],
      [28.2837271548084, -98.6402348815778],
      [28.2945465050411, -98.6472808115596],
      [28.3043149364415, -98.6560713432324],
      [28.3128139281484, -98.6664102754054],
      [28.3198533090401, -98.6780666006761],
      [28.3252755341615, -98.6907796702491],
      [28.3289592305035, -98.7042650399434],
      [28.330821930554, -98.718220864721],
      [28.330821930554, -98.7323346952789],
      [28.3289592305035, -98.7462905200565],
      [28.3252755341615, -98.7597758897508],
      [28.3198533090401, -98.7724889593238],
      [28.3128139281484, -98.7841452845945],
      [28.3043149364415, -98.7944842167675],
      [28.2945465050411, -98.8032747484404],
      [28.2837271548084, -98.8103206784221],
      [28.2720988473331, -98.8154649796286],
      [28.2599215554601, -98.8185932738619],
      [28.2474674368132, -98.819636338842],
      [28.235014742156, -98.8185715958063],
      [28.2228415957253, -98.815423549699],
      [28.2112197868063, -98.8102631778348],
      [28.2004087108216, -98.8032062863594],
      [28.1906495941601, -98.7944108763386],
      [28.1821601300288, -98.7840735824342],
      [28.1751296429821, -98.7724252664906],
      [28.169714887716, -98.7597258656461],
      [28.1660365735074, -98.7462586095572],
      [28.1641766896394, -98.7323237337853],
      [28.1641766896394, -98.7182318262146],
      [28.1660365735074, -98.7042969504427],
      [28.169714887716, -98.6908296943539],
      [28.1751296429821, -98.6781302935094],
      [28.1821601300288, -98.6664819775657],
      [28.1906495941601, -98.6561446836613],
      [28.2004087108216, -98.6473492736405],
      [28.2112197868063, -98.6402923821651],
      [28.2228415957253, -98.6351320103009],
      [28.235014742156, -98.6319839641936],
      [28.2474674368132, -98.6309192211579],
    ],
    // R-6312 Cotulla, TX (Second of two circles)
    [
      [28.0983009689301, -98.6202166758701],
      [28.1107553545792, -98.621258362449],
      [28.1229328988784, -98.6243823818961],
      [28.1345614394225, -98.6295196024128],
      [28.1453809995083, -98.6365557998865],
      [28.1551496145787, -98.645334161846],
      [28.1636487616087, -98.6556587576973],
      [28.1706882679926, -98.6672989006882],
      [28.1761105878269, -98.6799943055295],
      [28.179794347546, -98.6934609261041],
      [28.1816570793455, -98.7073973407942],
      [28.1816570793455, -98.7214915392057],
      [28.179794347546, -98.7354279538958],
      [28.1761105878269, -98.7488945744704],
      [28.1706882679926, -98.7615899793118],
      [28.1636487616087, -98.7732301223026],
      [28.1551496145787, -98.783554718154],
      [28.1453809995083, -98.7923330801134],
      [28.1345614394225, -98.7993692775872],
      [28.1229328988784, -98.8045064981038],
      [28.1107553545792, -98.8076305175509],
      [28.0983009689301, -98.8086722041299],
      [28.0858479983691, -98.8076090043804],
      [28.0736745735983, -98.8044653832936],
      [28.0620524909872, -98.799312214354],
      [28.0512411534259, -98.7922651387608],
      [28.0414817948666, -98.7834819355587],
      [28.0329921158538, -98.7731589655153],
      [28.0259614477181, -98.761526770932],
      [28.0205465510439, -98.7488449308536],
      [28.0168681398136, -98.7353962861108],
      [28.0150082065922, -98.7214806610863],
      [28.0150082065922, -98.7074082189136],
      [28.0168681398136, -98.6934925938891],
      [28.0205465510439, -98.6800439491463],
      [28.0259614477181, -98.6673621090679],
      [28.0329921158538, -98.6557299144846],
      [28.0414817948666, -98.6454069444412],
      [28.0512411534259, -98.6366237412391],
      [28.0620524909872, -98.6295766656459],
      [28.0736745735983, -98.6244234967063],
      [28.0858479983691, -98.6212798756195],
      [28.0983009689301, -98.6202166758701],

    ],
  ]