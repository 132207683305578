export const minnesota =
  [
    // Lake Superior, MN R-4305
    [
      [47.75, -90],
      [47.75, -89.4666666666667],
      [46.9166666666667, -89.4669444444445],
      [46.9166666666667, -90.0836111111111],
      [47.5833333333333, -90.0833333333333],
    ],
  ]