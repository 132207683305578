export const germany =
  [
    // ED-R201A TRA
    [
      [53.666667, 6.49999999999999],
      [53.722222, 6.883333],
      [53.827778, 7.633333],
      [53.316667, 7.025],
      [53.336389, 6.99361099999999],
      [53.398889, 6.949444],
      [53.474444, 6.86361099999999],
      [53.495833, 6.816389],
      [53.504167, 6.741667],
      [53.522778, 6.672222],
      [53.5605559999999, 6.606667],
    ],
    // ED-R201B TRA
    [
      [53.827778, 7.633333],
      [53.848333, 7.830556],
      [53.404167, 8.46666699999999],
      [53.3, 7.19166699999999],
      [53.316667, 7.025],
    ],
    // ED-R201C TRA
    [
      [53.848333, 7.830556],
      [54.206944, 8.51361099999999],
      [53.885278, 9.248611],
      [53.580556, 8.938889],
      [53.404167, 8.46666699999999],
    ],
    // ED-R201D TRA
    [
      [54.206944, 8.51361099999999],
      [54.498611, 9.07611099999999],
      [54.441389, 9.680833],
      [54.2666669999999, 9.641667],
      [54.077778, 9.445833],
      [53.885278, 9.248611],
    ],
    // ER-R201E TRA
    [
      [54.206944, 8.51361099999999],
      [54.4949999999999, 8.309722],
      [54.65, 8.19999999999999],
      [54.733333, 8.18333299999999],
      [54.804444, 8.195278],
      [54.933333, 8.21666699999999],
      [55.061389, 8.328611],
      [55.066667, 8.333333],
      [55.0691669999999, 8.391944],
      [55.066667, 8.416667],
      [55.0499999999999, 8.483333],
      [55.016667, 8.483333],
      [54.916667, 8.566667],
      [54.91131, 8.637492],
      [54.908689, 8.659806],
      [54.911282, 8.668504],
      [54.91128, 8.671341],
      [54.910569, 8.680121],
      [54.908806, 8.687072],
      [54.9052979999999, 8.696001],
      [54.904691, 8.69679099999999],
      [54.903702, 8.69965],
      [54.902852, 8.700853],
      [54.900911, 8.707636],
      [54.898868, 8.70978599999999],
      [54.897258, 8.71660499999999],
      [54.896491, 8.716177],
      [54.895624, 8.718353],
      [54.892735, 8.727842],
      [54.893041, 8.73510699999999],
      [54.893234, 8.738162],
      [54.895632, 8.749539],
      [54.896694, 8.750232],
      [54.89687, 8.754892],
      [54.89451, 8.759566],
      [54.892646, 8.77698799999999],
      [54.895451, 8.776408],
      [54.895714, 8.778059],
      [54.896902, 8.780673],
      [54.897209, 8.781295],
      [54.8977649999999, 8.781256],
      [54.898488, 8.786913],
      [54.899734, 8.79041299999999],
      [54.901443, 8.793137],
      [54.902193, 8.795951],
      [54.902559, 8.799064],
      [54.90466, 8.798996],
      [54.90456, 8.799396],
      [54.905921, 8.811347],
      [54.904575, 8.811868],
      [54.904735, 8.81218299999999],
      [54.904037, 8.814322],
      [54.903998, 8.818795],
      [54.905523, 8.822047],
      [54.9057209999999, 8.823078],
      [54.906854, 8.82415999999999],
      [54.9063329999999, 8.829496],
      [54.905765, 8.829231],
      [54.905666, 8.829701],
      [54.906218, 8.831556],
      [54.90603, 8.832628],
      [54.906589, 8.832831],
      [54.906642, 8.83328099999999],
      [54.905527, 8.836919],
      [54.901308, 8.841866],
      [54.899407, 8.84462899999999],
      [54.8974439999999, 8.848438],
      [54.897286, 8.848831],
      [54.8968309999999, 8.86000499999999],
      [54.897319, 8.860818],
      [54.89758, 8.86237599999999],
      [54.898407, 8.86302399999999],
      [54.899039, 8.864938],
      [54.899909, 8.865826],
      [54.899407, 8.872889],
      [54.899647, 8.873576],
      [54.901131, 8.875476],
      [54.90206, 8.87745999999999],
      [54.902205, 8.87906899999999],
      [54.9020699999999, 8.880023],
      [54.902083, 8.883092],
      [54.902553, 8.88748799999999],
      [54.903914, 8.88905699999999],
      [54.904902, 8.892217],
      [54.903974, 8.894906],
      [54.904437, 8.902123],
      [54.903835, 8.904133],
      [54.903805, 8.910584],
      [54.903149, 8.912034],
      [54.90315, 8.91320299999999],
      [54.9023499999999, 8.913052],
      [54.902011, 8.913349],
      [54.902524, 8.91612499999999],
      [54.903011, 8.91693],
      [54.902689, 8.918037],
      [54.902545, 8.921156],
      [54.903327, 8.922889],
      [54.90341, 8.923619],
      [54.902394, 8.940393],
      [54.902549, 8.941254],
      [54.902541, 8.947844],
      [54.901591, 8.952838],
      [54.901028, 8.957541],
      [54.899987, 8.96187499999999],
      [54.899429, 8.963407],
      [54.898336, 8.965327],
      [54.896756, 8.973022],
      [54.895761, 8.977611],
      [54.89216, 8.984202],
      [54.886463, 9.01465299999999],
      [54.880327, 9.029699],
      [54.878293, 9.02904599999999],
      [54.87576, 9.036508],
      [54.871936, 9.048341],
      [54.872924, 9.05738],
      [54.872652, 9.058795],
      [54.871167, 9.063249],
      [54.87138, 9.06825],
      [54.871163, 9.07173399999999],
      [54.871853, 9.075836],
      [54.871681, 9.078681],
      [54.870694, 9.085646],
      [54.870196, 9.091144],
      [54.8703679999999, 9.09209],
      [54.871983, 9.09562],
      [54.871916, 9.099404],
      [54.873988, 9.110499],
      [54.8736919999999, 9.113144],
      [54.872932, 9.11804899999999],
      [54.872593, 9.12351999999999],
      [54.873721, 9.136091],
      [54.873031, 9.142893],
      [54.870726, 9.15513],
      [54.868905, 9.16007699999999],
      [54.8660849999999, 9.17280399999999],
      [54.863279, 9.185801],
      [54.861145, 9.187578],
      [54.860304, 9.191547],
      [54.859215, 9.194595],
      [54.859034, 9.195898],
      [54.859061, 9.20501],
      [54.853605, 9.223483],
      [54.849977, 9.24022899999999],
      [54.843842, 9.244875],
      [54.839069, 9.238747],
      [54.8352459999999, 9.235223],
      [54.83116, 9.235803],
      [54.826843, 9.23834699999999],
      [54.817179, 9.24599199999999],
      [54.809653, 9.250068],
      [54.810585, 9.258003],
      [54.810886, 9.26578499999999],
      [54.812121, 9.270427],
      [54.811783, 9.273285],
      [54.809799, 9.281216],
      [54.809641, 9.284141],
      [54.804298, 9.286315],
      [54.801688, 9.294876],
      [54.80316, 9.295559],
      [54.80617, 9.291988],
      [54.808925, 9.293433],
      [54.805625, 9.329431],
      [54.804221, 9.330417],
      [54.8002419999999, 9.34367499999999],
      [54.802078, 9.345325],
      [54.803613, 9.341998],
      [54.80409, 9.342154],
      [54.807125, 9.343163],
      [54.806685, 9.346434],
      [54.8143319999999, 9.354717],
      [54.815179, 9.356267],
      [54.816226, 9.360829],
      [54.815986, 9.36225499999999],
      [54.815172, 9.362719],
      [54.815301, 9.36338499999999],
      [54.816554, 9.36274399999999],
      [54.817296, 9.36387599999999],
      [54.8183979999999, 9.36694499999999],
      [54.818947, 9.36715399999999],
      [54.819374, 9.37083299999999],
      [54.821126, 9.37383099999999],
      [54.8215429999999, 9.372938],
      [54.822138, 9.372517],
      [54.82312, 9.37398],
      [54.8249249999999, 9.374116],
      [54.82582, 9.375463],
      [54.827295, 9.375029],
      [54.828595, 9.37557],
      [54.829169, 9.376261],
      [54.830627, 9.375197],
      [54.831259, 9.37423899999999],
      [54.832218, 9.374839],
      [54.833322, 9.374737],
      [54.8336499999999, 9.376669],
      [54.833096, 9.378893],
      [54.834179, 9.38016],
      [54.835156, 9.38068299999999],
      [54.83543, 9.381557],
      [54.837357, 9.380891],
      [54.839139, 9.382809],
      [54.838359, 9.387643],
      [54.838346, 9.391361],
      [54.838711, 9.395384],
      [54.83928, 9.397133],
      [54.839145, 9.397828],
      [54.840465, 9.402639],
      [54.838863, 9.403649],
      [54.8424, 9.407418],
      [54.842637, 9.40827799999999],
      [54.8421879999999, 9.411139],
      [54.838041, 9.418049],
      [54.836476, 9.41841299999999],
      [54.8358559999999, 9.416345],
      [54.834347, 9.41606999999999],
      [54.833321, 9.416528],
      [54.8323039999999, 9.417895],
      [54.832461, 9.418943],
      [54.83196, 9.42035699999999],
      [54.827119, 9.428658],
      [54.827095, 9.435228],
      [54.827975, 9.437417],
      [54.832827, 9.449405],
      [54.833363, 9.460176],
      [54.831633, 9.46302099999999],
      [54.837039, 9.48267399999999],
      [54.8413749999999, 9.4982],
      [54.850833, 9.516111],
      [54.6, 9.71666699999999],
      [54.441389, 9.680833],
      [54.498611, 9.07611099999999],
    ],
    // ER-R201F TRA
    [
      [54.6, 9.71666699999999],
      [54.616667, 9.916667],
      [54.516667, 10],
      [54.4999999999999, 10.016667],
      [54.48, 10.033333],
      [54.48, 10.083333],
      [54.491667, 10.283333],
      [54.4999999999999, 10.416667],
      [54.333333, 10.416667],
      [54.272778, 10.610278],
      [54.191667, 10.416667],
      [54.033333, 9.9],
      [54.077778, 9.445833],
      [54.2666669999999, 9.641667],
      [54.441389, 9.680833],
    ],
    // ER-R202A TRA
    [
      [53.16835, 7.890333],
      [52.7175, 7.903833],
      [52.687, 7.059667],
      [52.729189, 7.064919],
      [52.810317, 7.071553],
      [52.821483, 7.079983],
      [52.821603, 7.079531],
      [52.822217, 7.080531],
      [52.822325, 7.080072],
      [52.82245, 7.080744],
      [52.8382, 7.092742],
      [52.849889, 7.087258],
      [52.859236, 7.098661],
      [52.859739, 7.098706],
      [52.86, 7.099592],
      [52.8639439999999, 7.104386],
      [52.941586, 7.181517],
      [53.108667, 7.202167],
      [53.168333, 7.890333],
    ],
    // ER-R202B TRA
    [
      [53.168333, 7.890333],
      [53.2055, 8.3195],
      [52.7375, 8.570833],
      [52.7175, 7.903833],
    ],
    // ER-R202C TRA
    [
      [52.7375, 8.570833],
      [52.391667, 8.191667],
      [52.2833329999999, 8],
      [52.264667, 7.622167],
      [52.706167, 7.565],
    ],
    // ER-R202D TRA
    [
      [52.706167, 7.565],
      [52.264667, 7.622167],
      [52.237167, 7.063],
      [52.237167, 7.061286],
      [52.256144, 7.04239699999999],
      [52.271706, 7.032075],
      [52.2736439999999, 7.028569],
      [52.277956, 7.027519],
      [52.278933, 7.027983],
      [52.279364, 7.028836],
      [52.280183, 7.029614],
      [52.281522, 7.029544],
      [52.2873189999999, 7.02622799999999],
      [52.2919809999999, 7.026428],
      [52.302453, 7.033803],
      [52.307628, 7.0395],
      [52.310158, 7.040539],
      [52.311114, 7.04132799999999],
      [52.312428, 7.038833],
      [52.313478, 7.04354699999999],
      [52.3139139999999, 7.04329699999999],
      [52.314908, 7.046856],
      [52.3153, 7.047458],
      [52.316614, 7.046469],
      [52.316392, 7.045733],
      [52.3184939999999, 7.04754199999999],
      [52.319442, 7.04761099999999],
      [52.319953, 7.048064],
      [52.321294, 7.047242],
      [52.322831, 7.046958],
      [52.323747, 7.047183],
      [52.324933, 7.04803099999999],
      [52.328697, 7.05213899999999],
      [52.332164, 7.05545],
      [52.33295, 7.054817],
      [52.334958, 7.05615],
      [52.337964, 7.056247],
      [52.351878, 7.072256],
      [52.356528, 7.072058],
      [52.366808, 7.072769],
      [52.368011, 7.072264],
      [52.371689, 7.072289],
      [52.372772, 7.072047],
      [52.373606, 7.072161],
      [52.390367, 7.064003],
      [52.396181, 7.058531],
      [52.666333, 7.05749999999999],
      [52.687, 7.059667],
    ],
    // ER-R202E TRA
    [
      [53.3, 7.19166699999999],
      [53.378889, 8.13833299999999],
      [53.193333, 8.179444],
      [53.168333, 7.890278],
      [53.108611, 7.202222],
      [53.1134139999999, 7.202794],
      [53.121769, 7.1829],
      [53.124222, 7.18589399999999],
      [53.124928, 7.18407499999999],
      [53.124897, 7.183611],
      [53.126672, 7.181831],
      [53.127872, 7.180933],
      [53.130028, 7.18028599999999],
      [53.138158, 7.178939],
      [53.138539, 7.178967],
      [53.138033, 7.180256],
      [53.140406, 7.183217],
      [53.142281, 7.18389399999999],
      [53.144397, 7.185558],
      [53.148014, 7.18962799999999],
      [53.155444, 7.189225],
      [53.157381, 7.189317],
      [53.159564, 7.19115299999999],
      [53.159886, 7.19121099999999],
      [53.160928, 7.190736],
      [53.161697, 7.190831],
      [53.176567, 7.203656],
      [53.176544, 7.208136],
      [53.1769689999999, 7.212564],
      [53.180281, 7.2275],
      [53.188481, 7.208044],
      [53.192511, 7.21145599999999],
      [53.1938359999999, 7.21345799999999],
      [53.194836, 7.21564399999999],
      [53.1955529999999, 7.216553],
      [53.197822, 7.21762499999999],
      [53.201025, 7.217633],
      [53.213333, 7.216944],
    ],
    // ER-R203A TRA
    [
      [52.122222, 8.311111],
      [52.138333, 8.944444],
      [51.583333, 8.944444],
      [51.5, 8.883333],
      [51.505556, 8.125],
      [51.6, 8.016667],
      [51.816667, 7.866667],
    ],
    // ER-R203B TRA
    [
      [51.816667, 7.866667],
      [51.961111, 8.83388899999999],
      [51.966389, 8.944444],
      [51.583333, 8.944444],
      [51.55, 8.92],
      [51.558611, 8.144722],
    ],
    // ER-R205A TRA
    [
      [49.4945, 7.478667],
      [49.649667, 6.437167],
      [49.650801, 6.43890999999999],
      [49.6542399999999, 6.440203],
      [49.657332, 6.440524],
      [49.659111, 6.439142],
      [49.6600499999999, 6.43728299999999],
      [49.660441, 6.435687],
      [49.660554, 6.43374399999999],
      [49.660326, 6.432077],
      [49.660198, 6.428635],
      [49.660487, 6.427406],
      [49.661144, 6.426428],
      [49.661928, 6.425876],
      [49.662645, 6.425794],
      [49.665282, 6.427189],
      [49.669723, 6.431712],
      [49.670267, 6.432829],
      [49.67097, 6.43554199999999],
      [49.6753069999999, 6.443272],
      [49.67782, 6.446758],
      [49.680858, 6.449594],
      [49.684925, 6.454108],
      [49.690119, 6.457976],
      [49.691248, 6.459681],
      [49.692294, 6.462737],
      [49.692869, 6.467273],
      [49.6937349999999, 6.470898],
      [49.6949079999999, 6.47355],
      [49.696534, 6.479186],
      [49.6986, 6.482653],
      [49.701645, 6.485895],
      [49.70494, 6.490367],
      [49.707395, 6.492911],
      [49.711749, 6.499617],
      [49.712862, 6.502962],
      [49.713024, 6.50413],
      [49.713044, 6.505495],
      [49.712536, 6.50724],
      [49.713423, 6.50668199999999],
      [49.7161409999999, 6.506529],
      [49.71714, 6.505233],
      [49.720386, 6.499662],
      [49.723215, 6.496009],
      [49.724678, 6.495178],
      [49.725556, 6.495474],
      [49.726239, 6.496443],
      [49.726814, 6.49931599999999],
      [49.7267199999999, 6.501905],
      [49.726046, 6.504968],
      [49.721291, 6.510485],
      [49.721069, 6.511334],
      [49.721038, 6.512349],
      [49.72151, 6.514792],
      [49.722836, 6.516915],
      [49.7239039999999, 6.51725299999999],
      [49.724699, 6.516639],
      [49.725239, 6.51575199999999],
      [49.725787, 6.51413599999999],
      [49.727372, 6.511276],
      [49.728192, 6.50855],
      [49.72937, 6.50579],
      [49.732265, 6.502104],
      [49.734804, 6.501554],
      [49.740511, 6.502311],
      [49.743231, 6.50180999999999],
      [49.744419, 6.501951],
      [49.745814, 6.50266],
      [49.748147, 6.500847],
      [49.751665, 6.50053699999999],
      [49.7532, 6.501277],
      [49.754845, 6.503378],
      [49.756016, 6.50658999999999],
      [49.757149, 6.511319],
      [49.757795, 6.513133],
      [49.758952, 6.51531899999999],
      [49.760096, 6.516957],
      [49.761689, 6.518263],
      [49.762376, 6.51848299999999],
      [49.764984, 6.517866],
      [49.767538, 6.51807299999999],
      [49.768586, 6.517648],
      [49.771, 6.51507899999999],
      [49.772276, 6.513144],
      [49.773549, 6.510082],
      [49.773932, 6.509825],
      [49.775459, 6.51001],
      [49.7767559999999, 6.510775],
      [49.781425, 6.51635],
      [49.782907, 6.51696999999999],
      [49.783439, 6.516562],
      [49.784836, 6.514325],
      [49.785909, 6.51034],
      [49.78714, 6.50751],
      [49.787944, 6.506463],
      [49.789551, 6.505774],
      [49.791113, 6.50678399999999],
      [49.792152, 6.508521],
      [49.793925, 6.51443],
      [49.796501, 6.52044],
      [49.797473, 6.521765],
      [49.801775, 6.525966],
      [49.803504, 6.529065],
      [49.80524, 6.530932],
      [49.806335, 6.530837],
      [49.8078039999999, 6.530013],
      [49.809127, 6.529727],
      [49.811088, 6.527079],
      [49.813134, 6.522081],
      [49.813188, 6.52078299999999],
      [49.812899, 6.519866],
      [49.811324, 6.518432],
      [49.808459, 6.518192],
      [49.805219, 6.516283],
      [49.802412, 6.513418],
      [49.801957, 6.512534],
      [49.801933, 6.51118],
      [49.802933, 6.510056],
      [49.808288, 6.507156],
      [49.8094109999999, 6.505939],
      [49.9345, 6.608833],
      [49.942833, 6.89883299999999],
      [49.836333, 7.602833],
    ],
    // ER-R205B TRA
    [
      [49.4945, 7.478667],
      [49.836333, 7.602833],
      [49.710833, 8.39416699999999],
      [49.669667, 8.448833],
      [49.346667, 8.40799999999999],
    ],
    // ER-R205C TRA
    [
      [49.4945, 7.478667],
      [49.346667, 8.40799999999999],
      [49.076333, 8.374167],
      [49.0044999999999, 8.348333],
      [48.967833, 8.291667],
      [48.971111, 8.223889],
      [48.971557, 8.226813],
      [48.973928, 8.226761],
      [48.975832, 8.223312],
      [48.97496, 8.21435999999999],
      [48.970125, 8.20778999999999],
      [48.969643, 8.203835],
      [48.966899, 8.199768],
      [48.969229, 8.195537],
      [48.972364, 8.197582],
      [48.977114, 8.19455],
      [48.976591, 8.187633],
      [48.97852, 8.184645],
      [48.977538, 8.180456],
      [48.978282, 8.180319],
      [48.979073, 8.171625],
      [48.976397, 8.170123],
      [48.977693, 8.166981],
      [48.97755, 8.162152],
      [48.978615, 8.160807],
      [48.977182, 8.153831],
      [48.977319, 8.148609],
      [48.978556, 8.14465599999999],
      [48.97848, 8.139587],
      [48.979565, 8.136533],
      [48.9811549999999, 8.135673],
      [48.981175, 8.13288599999999],
      [48.983158, 8.131081],
      [48.982519, 8.128095],
      [48.986423, 8.123504],
      [48.984711, 8.11985],
      [48.986909, 8.115106],
      [48.987023, 8.108729],
      [48.989055, 8.106574],
      [48.989921, 8.100728],
      [48.988847, 8.097643],
      [48.989702, 8.096667],
      [48.989351, 8.09143],
      [48.990482, 8.08802599999999],
      [48.991771, 8.087618],
      [48.996318, 8.071626],
      [48.997611, 8.068991],
      [48.99904, 8.069165],
      [49.000971, 8.064162],
      [49.002936, 8.06523],
      [49.00548, 8.064],
      [49.006788, 8.058828],
      [49.014292, 8.049609],
      [49.013665, 8.0449],
      [49.0184629999999, 8.031485],
      [49.018113, 8.022817],
      [49.02138, 8.018527],
      [49.020104, 8.016339],
      [49.020762, 8.013667],
      [49.024042, 8.012615],
      [49.025396, 8.010679],
      [49.025032, 8.007253],
      [49.026341, 8.002274],
      [49.028559, 8.000397],
      [49.028629, 7.989671],
      [49.025845, 7.98238599999999],
      [49.027229, 7.978678],
      [49.026553, 7.97518],
      [49.029879, 7.97468299999999],
      [49.030733, 7.972959],
      [49.031796, 7.974069],
      [49.034135, 7.972199],
      [49.035964, 7.97219],
      [49.036369, 7.966855],
      [49.04159, 7.966762],
      [49.042985, 7.965117],
      [49.04308, 7.956895],
      [49.044965, 7.95745],
      [49.047228, 7.949052],
      [49.048616, 7.949738],
      [49.049749, 7.945662],
      [49.051612, 7.946498],
      [49.055485, 7.945463],
      [49.054399, 7.940948],
      [49.056355, 7.94028999999999],
      [49.0580879999999, 7.931748],
      [49.054266, 7.931195],
      [49.049823, 7.926533],
      [49.043723, 7.923153],
      [49.039996, 7.913525],
      [49.041948, 7.91385],
      [49.043728, 7.91075],
      [49.045156, 7.910667],
      [49.044522, 7.9026],
      [49.049027, 7.888739],
      [49.046485, 7.88436599999999],
      [49.046036, 7.881309],
      [49.043761, 7.880378],
      [49.040547, 7.87447],
      [49.033012, 7.8674],
      [49.0349569999999, 7.86080699999999],
      [49.03553, 7.854649],
      [49.037748, 7.852225],
      [49.042284, 7.85157],
      [49.044048, 7.849836],
      [49.042606, 7.84571],
      [49.043169, 7.843701],
      [49.04474, 7.844068],
      [49.047272, 7.83629499999999],
      [49.047298, 7.83053699999999],
      [49.052188, 7.825931],
      [49.054962, 7.81569],
      [49.057095, 7.812644],
      [49.061286, 7.810455],
      [49.06571, 7.794423],
      [49.058404, 7.788967],
      [49.058299, 7.779889],
      [49.056711, 7.776495],
      [49.050473, 7.769851],
      [49.0476959999999, 7.768777],
      [49.045513, 7.761077],
      [49.045506, 7.756736],
      [49.047896, 7.75157699999999],
      [49.04637, 7.74704],
      [49.0473469999999, 7.74075],
      [49.044802, 7.737418],
      [49.043973, 7.732246],
      [49.055554, 7.731464],
      [49.054942, 7.727082],
      [49.051177, 7.722788],
      [49.0516099999999, 7.72022399999999],
      [49.053929, 7.717576],
      [49.052486, 7.708248],
      [49.056159, 7.705664],
      [49.056975, 7.70080999999999],
      [49.055848, 7.698046],
      [49.057804, 7.695948],
      [49.054145, 7.69247899999999],
      [49.051807, 7.697194],
      [49.049625, 7.697313],
      [49.049197, 7.685653],
      [49.048278, 7.683858],
      [49.048927, 7.680702],
      [49.044993, 7.674697],
      [49.05489, 7.631208],
      [49.058589, 7.632653],
      [49.06382, 7.632599],
      [49.065724, 7.630616],
      [49.072645, 7.63094299999999],
      [49.073583, 7.629391],
      [49.074154, 7.620215],
      [49.083233, 7.60099],
      [49.079769, 7.569335],
      [49.080247, 7.563706],
      [49.085839, 7.54983499999999],
      [49.093683, 7.53563199999999],
      [49.096437, 7.53779],
      [49.097105, 7.528445],
      [49.102057, 7.529557],
      [49.103644, 7.528311],
      [49.103947, 7.525194],
      [49.108048, 7.521838],
      [49.116465, 7.519052],
      [49.121048, 7.515347],
      [49.1224199999999, 7.511776],
      [49.1224, 7.505489],
      [49.1345379999999, 7.497701],
      [49.135828, 7.495489],
      [49.1365649999999, 7.48953],
      [49.142292, 7.489879],
      [49.141409, 7.49538],
      [49.142456, 7.496758],
      [49.143622, 7.49522999999999],
      [49.146313, 7.497727],
      [49.150616, 7.505938],
      [49.154719, 7.506436],
      [49.155179, 7.495952],
      [49.164337, 7.492571],
      [49.169507, 7.493965],
      [49.169632, 7.48222],
      [49.167339, 7.467415],
      [49.164989, 7.465518],
      [49.162803, 7.459759],
      [49.163069, 7.45765299999999],
      [49.166904, 7.453904],
      [49.1684979999999, 7.448738],
      [49.168318, 7.44619],
      [49.164221, 7.439143],
      [49.1656519999999, 7.432573],
      [49.168087, 7.43307999999999],
      [49.170401, 7.440429],
      [49.172186, 7.441793],
      [49.175822, 7.439376],
      [49.176937, 7.4361],
      [49.180051, 7.436001],
      [49.1822059999999, 7.443663],
      [49.184189, 7.445614],
      [49.175054, 7.38112799999999],
      [49.171862, 7.365189],
      [49.166942, 7.364755],
      [49.1642149999999, 7.36578999999999],
      [49.161655, 7.368955],
      [49.160697, 7.366684],
      [49.156402, 7.362465],
      [49.152917, 7.361308],
      [49.145237, 7.362987],
      [49.144734, 7.361507],
      [49.143194, 7.362723],
      [49.1432819999999, 7.345325],
      [49.144167, 7.353],
    ],
    // ED-R205D TRA
    [
      [49.4945, 7.478667],
      [49.144167, 7.353],
      [49.1432819999999, 7.345325],
      [49.145229, 7.341557],
      [49.144791, 7.329889],
      [49.141288, 7.321149],
      [49.137113, 7.313805],
      [49.129467, 7.310173],
      [49.127072, 7.305608],
      [49.123189, 7.304176],
      [49.1172749999999, 7.298408],
      [49.1149479999999, 7.293163],
      [49.116908, 7.282332],
      [49.123438, 7.28383],
      [49.1243759999999, 7.281591],
      [49.122161, 7.27981999999999],
      [49.124272, 7.27166999999999],
      [49.122657, 7.265618],
      [49.127371, 7.248829],
      [49.130087, 7.245048],
      [49.128693, 7.235646],
      [49.126169, 7.228763],
      [49.1236, 7.205462],
      [49.122623, 7.203806],
      [49.120304, 7.203675],
      [49.117304, 7.201303],
      [49.115173, 7.198133],
      [49.121625, 7.196336],
      [49.125835, 7.190525],
      [49.126387, 7.191561],
      [49.128021, 7.190211],
      [49.127805, 7.187175],
      [49.131245, 7.185424],
      [49.131432, 7.18393899999999],
      [49.129961, 7.183567],
      [49.130273, 7.17744999999999],
      [49.128584, 7.178242],
      [49.1292219999999, 7.174491],
      [49.127378, 7.167797],
      [49.128618, 7.165113],
      [49.122185, 7.159548],
      [49.120465, 7.159466],
      [49.121463, 7.155947],
      [49.125184, 7.150292],
      [49.125905, 7.143178],
      [49.127948, 7.14048599999999],
      [49.128486, 7.135884],
      [49.1308069999999, 7.134017],
      [49.131693, 7.131586],
      [49.134682, 7.13153],
      [49.142112, 7.126388],
      [49.138522, 7.104235],
      [49.1405819999999, 7.103054],
      [49.146241, 7.104426],
      [49.150819, 7.112761],
      [49.152426, 7.113406],
      [49.152761, 7.107243],
      [49.155162, 7.106734],
      [49.156091, 7.10131599999999],
      [49.1526359999999, 7.09334899999999],
      [49.152065, 7.087028],
      [49.1534519999999, 7.084735],
      [49.1521829999999, 7.082318],
      [49.144201, 7.084163],
      [49.141993, 7.08177599999999],
      [49.132841, 7.090094],
      [49.130739, 7.090023],
      [49.124409, 7.072013],
      [49.120598, 7.068685],
      [49.116797, 7.06848],
      [49.114307, 7.06663999999999],
      [49.111915, 7.056998],
      [49.112952, 7.052043],
      [49.115623, 7.047487],
      [49.120379, 7.044206],
      [49.136735, 7.046782],
      [49.146706, 7.041435],
      [49.156728, 7.03214],
      [49.164227, 7.032914],
      [49.1668409999999, 7.029274],
      [49.170588, 7.02764799999999],
      [49.1757199999999, 7.02850099999999],
      [49.182613, 7.033833],
      [49.187959, 7.033308],
      [49.191418, 7.035021],
      [49.191268, 7.027922],
      [49.189191, 7.023228],
      [49.19321, 7.021351],
      [49.191226, 7.01562199999999],
      [49.188658, 7.01345],
      [49.187939, 7.010799],
      [49.193981, 7.008903],
      [49.194464, 6.999486],
      [49.197729, 6.997146],
      [49.200775, 6.992569],
      [49.20944, 6.975177],
      [49.209518, 6.972598],
      [49.203097, 6.959636],
      [49.207743, 6.95113],
      [49.216796, 6.939854],
      [49.222452, 6.938193],
      [49.222109, 6.928534],
      [49.2233549999999, 6.925144],
      [49.222691, 6.918732],
      [49.219991, 6.918838],
      [49.215061, 6.91152299999999],
      [49.21369, 6.900587],
      [49.20914, 6.893401],
      [49.214866, 6.87309099999999],
      [49.218804, 6.870498],
      [49.222288, 6.862928],
      [49.222774, 6.858838],
      [49.2135, 6.838093],
      [49.211274, 6.836222],
      [49.208846, 6.842376],
      [49.200093, 6.852167],
      [49.193278, 6.85053199999999],
      [49.186785, 6.858488],
      [49.183526, 6.85952],
      [49.181844, 6.86173],
      [49.174644, 6.85906899999999],
      [49.175032, 6.855614],
      [49.1730469999999, 6.844331],
      [49.168333, 6.845405],
      [49.1639869999999, 6.844554],
      [49.157209, 6.847705],
      [49.151072, 6.834041],
      [49.154034, 6.824417],
      [49.157736, 6.81689399999999],
      [49.158668, 6.808034],
      [49.162012, 6.802954],
      [49.163199, 6.794254],
      [49.166373, 6.789836],
      [49.168187, 6.780664],
      [49.167532, 6.773557],
      [49.164779, 6.761193],
      [49.166732, 6.749853],
      [49.166167, 6.746167],
      [49.200333, 6.723],
      [49.205937, 6.731462],
      [49.218944, 6.723201],
      [49.221183, 6.719648],
      [49.216417, 6.706361],
      [49.2195, 6.710333],
      [49.6355, 6.4275],
      [49.649667, 6.437167],
    ],
    // ED-R207C TRA
    [
      [48.527778, 9.55],
      [48.698611, 9.686389],
      [48.864444, 10.079722],
      [48.713333, 10.457778],
      [48.661944, 10.543889],
      [48.363889, 11],
      [48.030556, 11],
      [47.910833, 10.309722],
    ],
    // ED-R207S TRA
    [
      [47.910833, 10.309722],
      [48.030556, 11],
      [48.363889, 11],
      [48.238056, 11.190278],
      [47.9263889999999, 11.233889],
      [47.835833, 10.400278],
    ],
    // ED-R207W TRA
    [
      [48.527778, 9.55],
      [47.910833, 10.309722],
      [47.89, 9.55],
    ],
    // ED-R208A TRA
    [
      [51.261111, 12.521111],
      [51.248333, 12.762778],
      [51.068611, 13.219167],
      [50.991667, 13.195556],
      [50.691667, 12.959167],
      [50.438611, 12.197778],
      [50.523611, 11.934167],
      [50.665278, 11.863889],
    ],
    // ED-R208B TRA
    [
      [51.261111, 12.521111],
      [51.457222, 12.826944],
      [51.508333, 13.1],
      [51.508611, 13.335],
      [51.068611, 13.219167],
      [51.248333, 12.762778],
    ],
    // ED-R210A TRA
    [
      [49.670731, 11.960367],
      [49.298056, 12.401667],
      [48.9675, 12.168056],
      [49.157325, 11.596617],
      [49.299603, 11.526456],
      [49.633333, 11.566667],
      [49.616667, 11.616667],
      [49.616667, 11.916667],
      [49.65, 11.95],
    ],
    // ED-R210B TRA
    [
      [49.157325, 11.596617],
      [48.9675, 12.168056],
      [48.800556, 12.0375],
      [48.860117, 11.741772],
    ],
    // ED-R 401 MVPA North East],
    [
      [54.4566666667, 12.6058333333],
      [54.3594444444, 13.0663888889],
      [54.2780555556, 13.1947222222],
      [53.9066666667, 13.7522222222],
      [53.6472222221999, 13.7452777778],
      [53.4083333333, 13.6063888889],
      [53.1127777778, 13.45],
      [53.0633333332999, 13.1805555556],
      [53.0875, 12.5561111111],
      [53.1038888889, 11.9433333333],
      [53.2347222222, 11.7611111111],
      [53.4147222222, 11.8522222222],
      [53.7127777777999, 12.0044444444],
      [53.9530555556, 12.1288888889],
      [54.1919444443999, 12.2541666667],
    ],
    // ED-R 401 MORE 10],
    [
      [54.4566666667, 12.6058333333],
      [54.6922222222, 12.9522222222],
      [54.5763888889, 13.5572222222],
      [54.5061111111, 13.8805555556],
      [53.6472222221999, 13.7452777778],
      [53.9066666667, 13.7522222222],
      [54.2780555556, 13.1947222222],
      [54.3594444444, 13.0663888889],
    ],
    // ED-R 401 MORE 11],
    [
      [53.2347222222, 11.7611111111],
      [53.1038888889, 11.9433333333],
      [53.0875, 12.5561111111],
      [53.0633333332999, 13.1805555556],
      [53.1127777778, 13.45],
      [52.5925, 12.8919444444],
      [52.7752777778, 11.755],
    ],
    // ED-R107C TRA
    [
      [47.910833, 10.309722],
      [48.030556, 11],
      [48.363889, 11],
      [48.661944, 10.543889],
      [48.625, 9.70194399999999],
      [48.527778, 9.55],
    ],
    // ED-R107W TRA
    [
      [48.527778, 9.55],
      [47.910833, 10.309722],
      [47.89, 9.55],
    ],
  ]