export const utah =
  [
    // Hill AFB R-6404A
    [
      [41.1916666666667, -112.759166666667],
      [41.2666666666667, -113.834166666667],
      [41.1416666666667, -114.0425],
      [40.925, -114.0425],
      [40.9166666666667, -114.000833333333],
      [40.9166666666667, -112.8425],
      [41.0166666666667, -112.650833333333],
      [41.1166666666667, -112.650833333333],
    ],
    // R-6404B/D
    [
      [40.9166666666667, -112.8425],
      [40.9166666666667, -114.000833333333],
      [40.8166666666667, -113.6675],
      [40.8666666666667, -112.950833333333],
    ],
    // R-6404C
    [
      [41.2666666666667, -113.834166666667],
      [41.1916666666667, -114.250833333333],
      [40.9916666666667, -114.250833333333],
      [40.925, -114.0425],
      [41.1416666666667, -114.0425],
    ],
    // UTTR South 3
    [
      [40.2716666666667, -114.45],
      [40.65, -113.716666666667],
      [40.65, -113.5],
      [40.25, -113.5],
      [40.25, -114.45],
    ],
    // UTTR South 4
    [
      [40.65, -113.5],
      [40.65, -113],
      [40.4166666666667, -112.833333333333],
      [40.4166666666667, -113.116666666667],
      [40.3333333333333, -113.116666666667],
      [40.3333333333333, -113.333333333333],
      [40.25, -113.35],
      [40.25, -113.5],
    ],
    // UTTR South 5
    [
      [40.25, -114.45],
      [40.25, -113.5],
      [39.8333333333333, -113.5],
      [39.8333333333333, -114.45],
    ],
    // UTTR South 6
    [
      [40.25, -113.5],
      [40.25, -113.35],
      [40, -113.416666666667],
      [40, -113.5],
    ],
    // UTTR South 7
    [
      [39.8333333333333, -114.45],
      [39.8333333333333, -113.5],
      [39.3833333333333, -113.5],
      [39.3833333333333, -114.45],
    ],
    // UTTR South 8
    [
      [40, -113.5],
      [40, -113.416666666667],
      [39.9166666666667, -113.45],
      [39.8666666666667, -113.45],
      [39.85, -113.333333333333],
      [39.8166666666667, -113.133333333333],
      [39.7333333333333, -113.133333333333],
      [39.7666666666667, -112.933333333333],
      [39.3833333333333, -113.316666666667],
      [39.3833333333333, -113.5],
    ],
    // UTTR Dugway Alley
    [
      [40.3333333333333, -113.333333333333],
      [40.3333333333333, -113.116666666667],
      [40.4166666666667, -113.116666666667],
      [40.4166666666667, -112.833333333333],
      [40.2666666666667, -112.716666666667],
      [40, -112.716666666667],
      [39.7666666666667, -112.933333333333],
      [39.7333333333333, -113.133333333333],
      [39.8166666666667, -113.133333333333],
      [39.85, -113.333333333333],
      [39.8666666666667, -113.45],
      [39.9166666666667, -113.45],
      [40, -113.416666666667],
      [40.25, -113.35],
    ],
  ]