export const newYork =
  [
    // Adirondack A, NY
    [
      [44.5, -75.3333333333333],
      [44.6, -75.05],
      [44.5, -75.05],
    ],
    // Adirondack B, NY
    [
      [44.3166666666667, -75.6180555555555],
      [44.4416666666667, -75.5],
      [44.5, -75.3333333333333],
      [44.5, -75.05],
      [44.4583333333333, -75.05],
      [44.3388888888889, -75.175],
      [44.2525, -75.5116666666667],
      [44.2686111111111, -75.5447222222222],
    ],
    // Adirondack C, NY
    [
      [44.2525, -75.5116666666667],
      [44.3388888888889, -75.175],
      [44.4583333333333, -75.05],
      [44.1, -75.05],
      [44.1, -75.4802777777778],
      [44.1194444444444, -75.4469444444445],
      [44.19, -75.3830555555555],
    ],
    // Adirondack D, NY
    [
      [44.1972222222222, -75.7313888888889],
      [44.3166666666667, -75.6180555555555],
      [44.2686111111111, -75.5447222222222],
      [44.1805555555556, -75.6497222222222],
      [44.1594444444444, -75.6666666666667],
    ],
    // Carthage East, NY
    [
      [44.0180555555556, -75.6205555555556],
      [44.1, -75.4802777777778],
      [44.1, -75.05],
      [43.8833333333333, -75.05],
      [43.8833333333333, -75.5833333333333],
    ],
    // Carthage West, NY
    [
      [43.7333333333333, -75.8666666666667],
      [44.1972222222222, -75.7313888888889],
      [44.1594444444444, -75.6666666666667],
      [44.1152777777778, -75.7025],
      [44.0555555555556, -75.6802777777778],
      [44.0180555555556, -75.6205555555556],
      [43.8833333333333, -75.5833333333333],
    ],
    // Cranberry, NY
    [
      [44.5916666666667, -75.05],
      [44.6, -75],
      [44.6, -74.5833333333333],
      [44.25, -74.5833333333333],
      [43.8833333333333, -75.05],
    ],
    // Lowville, NY
    [
      [43.7333333333333, -75.8666666666667],
      [43.8833333333333, -75.5833333333333],
      [43.8833333333333, -75.05],
      [43.5, -75.05],
      [43.5, -75.8666666666667],
    ],
    // Tupper Central, NY
    [
      [44.6, -74.5833333333333],
      [44.6, -74.35],
      [44.2333333333333, -74.35],
      [44.1, -74.2],
      [43.8833333333333, -74.2],
      [43.8833333333333, -75.05],
      [44.25, -74.5833333333333],
    ],
    // Tupper East, NY
    [
      [44.6, -74.35],
      [44.6, -74.2],
      [44.1, -74.2],
      [44.2333333333333, -74.35],
    ],
    // Tupper South, NY
    [
      [43.8833333333333, -75.05],
      [43.8833333333333, -74.2],
      [43.6666666666667, -74.2],
      [43.5, -74.35],
      [43.5, -75.05],
    ],
  ]