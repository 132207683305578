export const llzs =
[
  // LLZ-1
  [
    [37.15, 126.25],
    [36.33333333, 126.4166667],
    [36.33333333, 125.8166667],
    [35.45, 125.8166667],
    [35.45, 126.4333333],
    [35.95, 127.2666667],
    [36.6, 126.8666667],
    [36.75, 126.9666667],
    [36.82166667, 126.9333333],
    [36.93833333, 126.85],
    [37.05, 126.8333333],
    [37.02166667, 126.7333333],
    [37.15, 126.8166667],
  ],
  // LLZ-2
  [
    [35.45, 125.8166667],
    [34, 125.8166667],
    [34, 127.3333333],
    [34.5, 128.2333333],
    [35.95, 127.2666667],
    [35.45, 126.4333333],
  ],
  // LLZ-3
  [
    [35.95, 127.2666667],
    [34.5, 128.2333333],
    [35.26666667, 129.6333333],
    [35.88333333, 128.6666667],
    [36.4, 128.2833333],
  ],
  // LLZ-4
  [
    [36.4, 128.2833333],
    [35.88333333, 128.6666667],
    [35.26666667, 129.6333333],
    [36, 129.8333333],
    [37, 129.75],
    [37.16666667, 129.6666667],
    [37.33333333, 129.3333333],
    [37.5, 129.25],
    [37.4, 129.2166667],
    [37.03333333, 129.05],
    [37.03366667, 128.7735],
    [36.91783333, 128.7735],
  ],
  // LLZ-5
  [
    [37.31666667, 127.6],
    [36.4, 128.2833333],
    [36.91783333, 128.7735],
    [37.03366667, 128.7735],
    [37.03333333, 129.05],
    [37.4, 129.2166667],
    [37.5, 129.25],
    [37.75, 129.1333333],
    [37.75, 128.95],
  ],
  // LLZ-6
  [
    [37.71666667, 127.5166667],
    [37.81666667, 127.8333333],
    [37.98333333, 127.8333333],
    [38.06666667, 128.5166667],
    [38.16666667, 128.6166667],
    [38, 129],
    [37.75, 129.1333333],
    [37.75, 128.95],
    [37.31666667, 127.6],
  ],
]