export const koreaNfl =
[
  [37.6909708, 126.8116237],
  [37.8001474, 126.8238166],
  [38.1461252, 127.2468922],
  [38.1794087, 127.5186218],
  [38.0263626, 127.6390582],
  [38.0010501, 127.7582143],
  [38.0538691, 128.4103845],
  [38.1502457, 128.5337266],
  [38.2712053, 128.562506]
]