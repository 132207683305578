export const california =
  [
    // Abel Bravo, CA
    [
      [32.9416666666667, -115.450833333333],
      [32.95, -115.181388888889],
      [32.85, -115.0925],
      [32.85, -115.434166666667],
    ],
    // Abel East, CA
    [
      [33.2583333333333, -114.925833333333],
      [33.05, -114.750833333333],
      [32.85, -114.750833333333],
      [32.85, -115.0925],
      [32.95, -115.181388888889],
      [33.1458333333333, -114.945277777778],
    ],
    // Abel North, CA
    [
      [33.5444444444444, -115.564722222222],
      [33.5166666666667, -115.0675],
      [33.2583333333333, -114.925833333333],
      [33.1458333333333, -114.945277777778],
      [33.0166666666667, -115.100833333333],
      [33.2333333333333, -115.375833333333],
      [33.3583333333333, -115.549444444444],
      [33.3944444444444, -115.556388888889],
      [33.475, -115.703611111111],
    ],
    // Abel South, CA
    [
      [33.3583333333333, -115.549444444444],
      [33.2333333333333, -115.375833333333],
      [33.0166666666667, -115.100833333333],
      [32.95, -115.181388888889],
      [32.9416666666667, -115.450833333333],
    ],
    // Bakersfield, CA
    [
      [35.6666666666667, -118.850833333333],
      [35.6666666666667, -118.584166666667],
      [35.25, -118.584166666667],
      [34.9333333333333, -118.350833333333],
      [35.2333333333333, -118.700833333333],
    ],
    // Barstow, CA
    [
      [35.1166666666667, -116.5675],
      [35.0222222222222, -116.684166666667],
      [34.9390, -117.150165],
      // Thence along the eastern border of R-2515 and the southern boundary of R-2502E
      [35.1083333333333, -116.978611111111],
      [35.1472222222222, -116.811944444444],
      [35.1166666666667, -116.796666666667],
    ],
    // Bishop, CA
    [
      [37.2, -118.434166666667],
      [37.2, -118.000833333333],
      [37.15, -118.000833333333],
      [37.0333333333333, -118.334166666667],
    ],
    // Buckhorn, CA
    [
      [34.8277777777778, -118.096666666667],
      // Thence along southern boundary of R-2515
      [34.8, -118.0175],
      [34.8, -117.584166666667],
      [34.8083333333333, -117.534166666667],
      [34.8388888888889, -117.534166666667],
      [34.8547222222222, -117.434166666667],
      [34.825, -117.434166666667],
      [34.775, -117.584166666667],
      [34.7666666666667, -118.000833333333],
      [34.8, -118.096666666667],
    ],
    // Foothill 1, CA
    [
      [37.0805555555556, -119.2675],
      [37.0805555555556, -118.584166666667],
      [36.5972222222222, -118.584166666667],
      [36.7138888888889, -119.250833333333],
    ],
    // Foothill 2, CA
    [
      [36.7138888888889, -119.250833333333],
      [36.5972222222222, -118.584166666667],
      [36.1333333333333, -118.584166666667],
      [36.1333333333333, -119.039722222222],
      [36.55, -119.234166666667],
    ],
    // Isabella, CA
    [
      [36.1333333333333, -118.584166666667],
      [36.1333333333333, -117.884166666667],
      // Thence south and east along the boundary of R-2505
      [35.9, -117.884166666667],
      [35.625, -117.7925],
      [35.625, -117.5925],
      [35.6541666666667, -117.490555555556],
      [35.35, -117.6425],
      [35.3222222222222, -117.6425],
      // Thence along the western boundary of R-2515
      [35.0166666666667, -118.096666666667],
      [34.8277777777778, -118.096666666667],
      [34.8, -118.096666666667],
      [34.85, -118.234166666667],
      [34.9333333333333, -118.350833333333],
      [35.25, -118.584166666667],
    ],
    // Kane East, CA
    [
      [33.4597222222222, -115.728333333333],
      [33.3833333333333, -115.850833333333],
      [33.1097222222222, -115.854166666667],
      [32.9930555555556, -115.725833333333],
      [32.9255555555556, -115.671666666667],
      [32.9416666666667, -115.450833333333],
      [33.32504, -115.54155],
      [33.3530555555556, -115.5775],
      [33.3827777777778, -115.638611111111],
      [33.4572222222222, -115.725],
    ],
    // Kane South, CA
    [
      [32.9255555555556, -115.671666666667],
      [32.9416666666667, -115.450833333333],
      [32.85, -115.434166666667],
      [32.8347222222222, -115.756388888889],
      // Thence CW along the arc of a 4.3NM radius circle centered at [32.8291666666667, -115.671666666667],
      [32.8398408115441, -115.755778080327],
      [32.8503059830637, -115.752958642614],
      [32.8602993939954, -115.748321998133],
      [32.8695976163521, -115.741971222377],
      [32.8779926986836, -115.734047839605],
      [32.885296833095, -115.724728692028],
      [32.8913465750937, -115.714222003275],
      [32.8960065198128, -115.702762722798],
      [32.8991723502432, -115.690607255568],
      [32.9007731872741, -115.678027696717],
      [32.9011111111111, -115.671666666667],
    ],
    // Kane West, CA
    [
      [33.3833333333333, -115.850833333333],
      [33.1097222222222, -115.854166666667],
      [32.9930555555556, -115.725833333333],
      [32.9263888888889, -115.671666666667],
      [32.9011111111111, -115.671666666667],
      // Thence CCW along the arc of a 4.3NM radius circle centered at [32.8291666666667,-115.671666666667],
      [32.9007731872741, -115.678027696717],
      [32.8991723502432, -115.690607255568],
      [32.8960065198128, -115.702762722798],
      [32.8913465750937, -115.714222003275],
      [32.885296833095, -115.724728692028],
      [32.8779926986836, -115.734047839605],
      [32.8695976163521, -115.741971222377],
      [32.8602993939954, -115.748321998133],
      [32.8503059830637, -115.752958642614],
      [32.8398408115441, -115.755778080327],
      [32.8347222222222, -115.756388888889],
      [32.8347222222222, -115.9175],
      [32.8333333333333, -116.0175],
      [32.95, -116.1675],
      [33.3, -116.1675],
      [33.475, -115.859166666667],
      [33.475, -115.748611111111],
      [33.4597222222222, -115.728333333333],
    ],
    // Owens, CA
    [
      [37.2, -118.584166666667],
      [37.2, -118.434166666667],
      [37.0333333333333, -118.334166666667],
      [37.15, -118.000833333333],
      [36.7666666666667, -118.000833333333],
      [36.2333333333333, -117.600833333333],
      // Thence along the northern and western boundaries of R-2505
      [36.2333333333333, -117.884166666667],
      [36.1333333333333, -117.884166666667],
      [36.1333333333333, -118.584166666667],
    ],
    // Panamint, CA
    [
      [36.5, -117.800833333333],
      [36.5, -116.9175],
      [35.575, -116.3925],
      // Thence along the northern boundary of R-2502N, the eastern, northern, and western boundaries of R-2524, and the northwestern boundary of R-2515
      [35.575, -116.495277777778],
      [35.6291666666667, -116.495277777778],
      [35.6291666666667, -116.923055555556],
      [35.7961111111111, -116.923055555556],
      [35.7961111111111, -117.281944444444],
      [35.6, -117.281944444444],
      [35.6, -117.434166666667],
      [35.4611111111111, -117.434166666667],
      [35.3222222222222, -117.6425],
      [35.35, -117.6425],
      [35.6541666666667, -117.490555555556],
      // Thence along the eastern and northern boundaries of R-2505
      [35.675, -117.4175],
      [36.2333333333333, -117.4175],
      [36.2333333333333, -117.600833333333],
    ],
    // Porterville, CA
    [
      [36.1333333333333, -119.000833333333],
      [36.1333333333333, -118.584166666667],
      [35.6666666666667, -118.584166666667],
      [35.6666666666667, -118.850833333333],
    ],
    // Saline, CA
    [
      [37.2, -118.000833333333],
      [37.2, -117.334166666667],
      [36.5, -116.9175],
      [36.5, -117.800833333333],
      [36.7666666666667, -118.000833333333],
    ],
    // Shoshone, CA
    [
      [36.5, -116.9175],
      [36.5, -116.784166666667],
      [36.1, -116.300833333333],
      [35.65, -115.884166666667],
      [35.3125, -116.313333333333],
      [35.4763888888889, -116.313333333333],
      [35.575, -116.3925],
    ],
    // Silver North, CA
    [
      [35.65, -115.884166666667],
      [35.4083333333333, -115.884166666667],
      [35.1138888888889, -116.333333333333],
      [35.075, -116.483333333333],
      [35.1166666666667, -116.5675],
      [35.3125, -116.313333333333],
    ],
    // Silver South, CA
    [
      [35.1166666666667, -116.5675],
      [35.075, -116.483333333333],
      [35.0222222222222, -116.684166666667],
    ],
  ]