export const florida =
  [
    // Avon Park R-2901A
    [
      [27.7461111111111, -81.4219444444444],
      [27.7461111111111, -81.1941666666667],
      [27.5836111111111, -81.1497222222222],
      [27.5419444444444, -81.1230555555556],
      [27.4836111111111, -81.2247222222222],
      [27.5436111111111, -81.2794444444444],
      [27.5425, -81.3608333333333],
      [27.7002777777778, -81.4219444444444],
    ],
    // R-2901B
    [
      [28.0002777777778, -81.3497222222222],
      [28.0002777777778, -81.2330555555556],
      [27.7461111111111, -81.2330555555556],
      [27.7461111111111, -81.1941666666667],
      [27.5836111111111, -81.1497222222222],
      [27.5419444444444, -81.1230555555556],
      [27.4919444444444, -81.0908333333333],
      [27.3502777777778, -80.9997222222222],
      [27.2794444444444, -81.0997222222222],
      [27.4127777777778, -81.1830555555556],
      [27.5127777777778, -81.2969444444444],
      [27.5425, -81.3608333333333],
      [27.7002777777778, -81.4219444444444],
      [27.9169444444444, -81.4219444444444],
    ],
    // R-2901C
    [
      [27.7461111111111, -81.4219444444444],
      [27.7669444444444, -81.4219444444444],
      [27.766407, -81.381201],
      [27.787176, -81.328372],
      [27.806208, -81.315881],
      [27.805975, -81.314489],
      [27.8086111111111, -81.2330555555556],
      [27.7461111111111, -81.2330555555556],
    ],
    // R-2901D
    [
      [27.7669444444444, -81.4219444444444],
      [27.8336111111111, -81.4219444444444],
      [27.8336111111111, -81.2330555555556],
      [27.8086111111111, -81.2330555555556],
      [27.805975, -81.314489],
      [27.806208, -81.315881],
      [27.787176, -81.328372],
      [27.766407, -81.381201],
    ],
    // R-2901F
    [
      [27.5425, -81.3608333333333],
      [27.5436111111111, -81.2794444444444],
      [27.4836111111111, -81.2247222222222],
      [27.4127777777778, -81.1830555555556],
      [27.5127777777778, -81.2969444444444],
    ],
    // R-2901G
    [
      [27.4836111111111, -81.2247222222222],
      [27.5419444444444, -81.1230555555556],
      [27.4919444444444, -81.0908333333333],
      [27.4127777777778, -81.1830555555556],
    ],
    // R-2901H
    [
      [27.4127777777778, -81.1830555555556],
      [27.4919444444444, -81.0908333333333],
      [27.3502777777778, -80.9997222222222],
    ],
    // Rodman R-2906
    [
      [29.4835768501266, -81.6709072285713],
      [29.4960287213764, -81.6719621347375],
      [29.5082038883781, -81.6751271400159],
      [29.5198302337775, -81.6803322447661],
      [29.5306478172359, -81.6874617473617],
      [29.5404147021836, -81.6963567776715],
      [29.5489123860183, -81.7068188108712],
      [29.5559507101575, -81.7186140862752],
      [29.5613721376717, -81.7314788339486],
      [29.5650553002752, -81.7451251919611],
      [29.56691773294, -81.7592476798891],
      [29.56691773294, -81.7735300801109],
      [29.5650553002752, -81.7876525680389],
      [29.5613721376717, -81.8012989260514],
      [29.5559507101575, -81.8141636737248],
      [29.5489123860183, -81.8259589491288],
      [29.5404147021837, -81.8364209823285],
      [29.5306478172359, -81.8453160126382],
      [29.5198302337775, -81.8524455152339],
      [29.5082038883781, -81.8576506199841],
      [29.4960287213764, -81.8608156252625],
      [29.4835768501266, -81.8618705314287],
      [29.4711264776239, -81.8607925419855],
      [29.4589556736943, -81.8576065044708],
      [29.4473361680161, -81.8523842873249],
      [29.4365272931921, -81.845243112685],
      [29.4267702119942, -81.8363428877998],
      [29.41828255592, -81.8258825990637],
      [29.4112535925409, -81.8140958521692],
      [29.4058400270396, -81.8012456592633],
      [29.4021625291245, -81.7876185890236],
      [29.4003030604883, -81.7735184080666],
      [29.4003030604883, -81.7592593519334],
      [29.4021625291245, -81.7451591709764],
      [29.4058400270396, -81.7315321007367],
      [29.4112535925409, -81.7186819078308],
      [29.41828255592, -81.7068951609363],
      [29.4267702119942, -81.6964348722002],
      [29.4365272931921, -81.6875346473151],
      [29.4473361680161, -81.6803934726751],
      [29.4589556736943, -81.6751712555292],
      [29.4711264776239, -81.6719852180145],
    ],
    // Lake George R-2907A
    [
      [29.3808333333333, -81.5219444444444],
      [29.2086111111111, -81.4997222222222],
      [29.2086111111111, -81.6413888888889],
      [29.2516666666667, -81.6663888888889],
      [29.2516666666667, -81.8636111111111],
      [29.335, -81.8636111111111],
      [29.335, -81.6663888888889],
      [29.3836111111111, -81.6663888888889],
      [29.3836111111111, -81.6483333333333],
      [29.3853029700548, -81.6466967430373],
      [29.3923414357362, -81.6349203644632],
      [29.3977629700854, -81.6220762444736],
      [29.4014462041816, -81.6084517802182],
      [29.4033086726624, -81.5943519577504],
      [29.4033086726624, -81.5800924822496],
      [29.4014462041816, -81.5659926597818],
      [29.3977629700854, -81.5523681955264],
      [29.3923414357362, -81.5395240755368],
      [29.3853029700548, -81.5277476969628],
    ],
    // R-2907B/C
    [
      [29.4280555555556, -81.6963888888889],
      [29.3836111111111, -81.6663888888889],
      [29.335, -81.6663888888889],
      [29.335, -81.8636111111111],
      [29.4916666666667, -81.8613888888889],
      [29.4835768501266, -81.8618705314287],
      [29.4711264776239, -81.8607925419855],
      [29.4589556736943, -81.8576065044708],
      [29.4473361680161, -81.8523842873249],
      [29.4365272931921, -81.845243112685],
      [29.4267702119942, -81.8363428877998],
      [29.41828255592, -81.8258825990637],
      [29.4112535925409, -81.8140958521692],
      [29.4058400270396, -81.8012456592633],
      [29.4021625291245, -81.7876185890236],
      [29.4003030604883, -81.7735184080666],
      [29.4003030604883, -81.7592593519334],
      [29.4021625291245, -81.7451591709764],
      [29.4058400270396, -81.7315321007367],
      [29.4112535925409, -81.7186819078308],
      [29.41828255592, -81.7068951609363],
    ],
    // Pinecastle R-2910A
    [
      [29.1327777777778, -81.8080555555556],
      [29.1669444444444, -81.8427777777778],
      [29.2336111111111, -81.7636111111111],
      [29.1975, -81.7163888888889],
      [29.1980334543611, -81.7078845098878],
      [29.196170941122, -81.6938128604306],
      [29.1924876176829, -81.6802156099438],
      [29.1870659498239, -81.6673971299717],
      [29.1800273072586, -81.6556442399371],
      [29.1715292293401, -81.6452197577253],
      [29.1705555555556, -81.6441666666667],
      [29, -81.5],
      [29.0002777777778, -81.7080555555556],
      [29.0541666666667, -81.7805555555556],
      [29.0676361515746, -81.7935718437652],
      [29.0784456894542, -81.8006873089202],
      [29.0900658889553, -81.8058906613103],
      [29.1022373985547, -81.8090651106741],
      [29.1146884706194, -81.8101390603273],
      [29.1271410189324, -81.8090877671549],
    ],
    // R-2910B
    [
      [29, -81.5],
      [28.9655555555556, -81.4733333333333],
      [28.9222222222222, -81.6033333333333],
      [29.0002777777778, -81.7080555555556],
    ],
    // R-2910C
    [
      [28.9655555555556, -81.4733333333333],
      [28.8941666666667, -81.5655555555555],
      [28.9222222222222, -81.6033333333333],
    ],
    // R-2910D/E
    [
      [29.2086111111111, -81.4997222222222],
      [29, -81.5],
      [29.1705555555556, -81.6441666666667],
      [29.1715292293401, -81.6452197577253],
      [29.1800273072586, -81.6556442399371],
      [29.1870659498239, -81.6673971299717],
      [29.1924876176829, -81.6802156099438],
      [29.196170941122, -81.6938128604306],
      [29.1980334543611, -81.7078845098878],
      [29.1975, -81.7163888888889],
      [29.2336111111111, -81.7636111111111],
      [29.1669444444444, -81.8427777777778],
      [29.2652777777778, -81.9444444444444],
      [29.335, -81.8636111111111],
      [29.2516666666667, -81.8636111111111],
      [29.2516666666667, -81.6663888888889],
      [29.2086111111111, -81.6413888888889],
    ],
    // Eglin AFB R-2914A
    [
      [30.7227777777778, -86.4166666666667],
      [30.7294444444444, -86.175],
      [30.6836111111111, -86.0861111111111],
      [30.4002777777778, -85.9333333333333],
      [30.3211111111111, -85.9333333333333],
      [30.3669444444444, -86.1333333333333],
      [30.3891666666667, -86.1361111111111],
      [30.5127777777778, -86.4166666666667],
    ],
    // R-2914B
    [
      [30.3669444444444, -86.1333333333333],
      [30.3211111111111, -85.9333333333333],
      [30.1836111111111, -85.9333333333333],
      [30.2502777777778, -86.1041666666667],
    ],
    // Eglin AFB R-2915A
    [
      [30.5613888888889, -86.9166666666667],
      [30.6477777777778, -86.9166666666667],
      [30.6498, -86.9082],
      [30.6533, -86.8929],
      [30.6540, -86.8907],
      [30.6552, -86.8875],
      [30.6569, -86.8841],
      [30.6592, -86.8806],
      [30.6731, -86.8600],
      [30.6749, -86.8564],
      [30.6944, -86.8049],
      [30.7127777777778, -86.7625],
      [30.7141666666667, -86.6338888888889],
      [30.4838888888889, -86.6338888888889],
      [30.4419444444444, -86.8583333333333],
      [30.4419444444444, -86.8722222222222],
      [30.4447, -86.8732],
      [30.4466, -86.8981],
      [30.4474, -86.9008],
      [30.4490, -86.9041],
      [30.4514, -86.9068],
      [30.4539, -86.9086],
      [30.4573, -86.9096],
      [30.4833, -86.9106],
      [30.5129, -86.9038],
      [30.5506, -86.9020],
      [30.5520, -86.9025],
      [30.5535, -86.9035],
      [30.5551, -86.9059],
      [30.5581, -86.9154],
    ],
    // R-2915B
    [
      [30.4419444444444, -86.8583333333333],
      [30.4838888888889, -86.6338888888889],
      [30.3961111111111, -86.6375],
      [30.3797222222222, -86.8583333333333],
      [30.4058333333333, -86.8],
    ],
    // R-2915C
    [
      [30.3797222222222, -86.8583333333333],
      [30.3961111111111, -86.6375],
      [30.3475, -86.6472222222222],
      [30.3252777777778, -86.8583333333333],
    ],
    // DeFuniak Springs R-2917
    [
      [30.5724985684627, -86.195393784677],
      [30.5749884150178, -86.1956089314776],
      [30.5774227030867, -86.1962505735868],
      [30.5797470486384, -86.1973044073501],
      [30.58190952013, -86.1987469159314],
      [30.5838617994153, -86.2005458924993],
      [30.5855602621686, -86.2026611582122],
      [30.5869669535913, -86.205045459094],
      [30.5880504374997, -86.2076455218243],
      [30.5887864997153, -86.2104032448407],
      [30.5891586899427, -86.2132569980492],
      [30.5891586899427, -86.2161430019507],
      [30.5887864997153, -86.2189967551592],
      [30.5880504374996, -86.2217544781756],
      [30.5869669535913, -86.2243545409059],
      [30.5855602621686, -86.2267388417877],
      [30.5838617994153, -86.2288541075006],
      [30.58190952013, -86.2306530840685],
      [30.5797470486384, -86.2320955926498],
      [30.5774227030867, -86.2331494264131],
      [30.5749884150178, -86.2337910685223],
      [30.5724985684627, -86.2340062153229],
      [30.5700087845535, -86.2337900932328],
      [30.5675746788561, -86.2331475624928],
      [30.5652506191969, -86.2320930057163],
      [30.5630885117162, -86.2306500039821],
      [30.561136642216, -86.2288508079404],
      [30.5594385986109, -86.2267356159341],
      [30.5580322984563, -86.2243516753903],
      [30.5569491431701, -86.2217522276115],
      [30.5562133177311, -86.2189953195193],
      [30.5558412513963, -86.2161425087978],
      [30.5558412513963, -86.2132574912021],
      [30.5562133177311, -86.2104046804806],
      [30.5569491431701, -86.2076477723884],
      [30.5580322984563, -86.2050483246096],
      [30.5594385986109, -86.2026643840658],
      [30.561136642216, -86.2005491920595],
      [30.5630885117162, -86.1987499960178],
      [30.5652506191969, -86.1973069942836],
      [30.5675746788561, -86.1962524375071],
      [30.5700087845535, -86.1956099067671],
    ],
    // Valparaiso, FL R-2918
    [
      [30.7216666666667, -86.4602777777778],
      [30.7227777777778, -86.4166666666667],
      [30.5502777777778, -86.4166666666667],
      [30.5502777777778, -86.425],
      [30.6169444444444, -86.425],
      [30.6169444444444, -86.4602777777778],
    ],
    // Valparaiso, FL R-2919A
    [
      [30.5127777777778, -86.4166666666667],
      [30.3891666666667, -86.1361111111111],
      [30.3669444444444, -86.1333333333333],
      [30.4169444444444, -86.3738888888889],
      [30.4169444444444, -86.4166666666667],
    ],
    // R-2919B
    [
      [30.4169444444444, -86.3738888888889],
      [30.3669444444444, -86.1333333333333],
      [30.2502777777778, -86.1041666666667],
      [30.3294444444444, -86.3958333333333],
    ],
  ]