export const newJersey =
  [
    // Atlantic City, NJ W-107A
    [
      [38.9666666666667, -74.3330555555555],
      [39.4152777777778, -73.7630555555556],
      [39.7333333333333, -73.4636111111111],
      [39.7333333333333, -72.8836111111111],
      [39.0966666666667, -72.27],
      [38.2833333333333, -72.8338888888889],
      [38.55, -73.4708333333333],
      [38.5666666666667, -73.5161111111111],
    ],
    // Atlantic City, NJ W-107B
    [
      [39.575, -73.9333333333333],
      // Thence 12NM from and parallel to the shoreline
      [40, -73.8],
      [40, -73.6161111111111],
      [39.8666666666667, -73.4827777777778],
      [39.7333333333333, -73.6827777777778],
    ],
    // Atlantic City, NJ W-107C
    [
      [39.0583333333333, -74.4666666666667],
      // Thence 12NM from and parallel to the shoreline
      [39.575, -73.9333333333333],
      [39.7333333333333, -73.6827777777778],
      [39.7333333333333, -73.4636111111111],
      [39.4152777777778, -73.7630555555556],
      [38.9666666666667, -74.3330555555555],
    ],
  ]